import React from "react";
import MiTecnicoInfo from "./MiTecnicoInfo";
const MiTecnico = (props) => {
  return (
    <>
      <h4 style={{backgroundColor : '#d4d4d4',padding : 10}}>Mi técnico</h4>
      {props.id_productor > 0 && (
          <>
            <MiTecnicoInfo id_productor={props.id_productor} />
          </>
      )}
    </>
  );
};
export default MiTecnico;
