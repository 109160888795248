const MenuTecnico = [
  {
    path: "/",
    icon: "fa fa-th",
    title: "Inicio",
  },
  {
    path: "/tecnicos/productores",
    icon: "fa fa-user",
    title: "Mis productores",
    children: [
      { path: "/tecnicos/productores/info", title: "Información" },
      { path: "/tecnicos/vacas/eventos", title: "Vacas Eventos" },
    ],
  },
  {
    path: "/tecnicos/planeacion",
    icon: "fa fa-calendar",
    title: "Mi planeación",
  },
  {
    path: "/tecnicos/reporte_at",
    icon: "fas fa-chart-area",
    title: "Reporte avanzado",
  },
  {
    path: "/tecnicos/reporte_app",
    icon: "fas fa-hand-holding-heart",
    title: "Reporte adopción App",
  },
  {
    path: "/tecnicos/reporte",
    icon: "fas fa-chart-bar",
    title: "Reporte estado reproductivo",
  },
];
export default MenuTecnico;
