import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import ListadoTecnicosAccesos from './componentes/ListadoTecnicosAccesos';
import 'bootstrap/dist/css/bootstrap.min.css';

const TecnicosAccesos = () => {
		return (
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item"><Link to="/">Accesos de Técnicos</Link></li>
					<li className="breadcrumb-item active">Listado</li>
				</ol>
				<h1 className="page-header">Accesos de Técnicos</h1>
				<Panel>
					<PanelHeader></PanelHeader>
					<PanelBody>
					<div className="row">
						<div className="col-md-12" style={{borderRight : '2px solid #dadada'}}>
							<ListadoTecnicosAccesos />
						</div>

					</div>
					</PanelBody>
				</Panel>
			</div>
		)
};

export default TecnicosAccesos;