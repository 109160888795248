const descripcionFiltros = () =>{
    return tituloFiltros() + totalFiltros();
};

const tituloFiltros = () =>{
    switch (localStorage.getItem('filtro_tipo')) {
        case '1':
            return 'centro de colecta';
        case '2':
            return 'ruta';
        case '3':
            return 'productor';
        case '4':
            return 'tipo de documento';
        case '5':
            return 'fecha';
        case '6':
            return 'nivel';
        default:
        return '';
    }
}

const totalFiltros = () =>{
    let valores = localStorage.getItem('filtro_valor') + '';
    let array = (valores.length > 0) ? valores.split(',') : []; 
    return ' (' + array.length + ')';
}

export default descripcionFiltros