
import React,{useState,useEffect} from 'react';
import Api from '../../../api/global.service';
import TablaVacaInfo from './../eventos/componentes/TablaVacaInfo';
import TablaVacaEventos from './../eventos/componentes/TablaVacaEventos';
import TablaVacaEventosNotificaciones from './../eventos/componentes/TablaVacaEventosNotificaciones';
import BlockUi from 'react-block-ui';

var dataArray = [];
const InfoVacas = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	useEffect(() => {
		const obtenerInfoVaca = ()  => {
			setBlocking(true);
			Api.getAll('vacas/detalle',{id_vaca : props.vaca}).then(response => {
				dataArray = response.data.data; 
				setDatosFiltrados(dataArray);
				setBlocking(false);
			}).catch(e => {
				setBlocking(false);
			});
		  }
		obtenerInfoVaca();
	}, [props.vaca]);
		return (
			<BlockUi tag="div" blocking={blocking}>
				<div className="row">
					<div className="col-md-12" style={{borderRight : '2px solid #dadada'}}>
						{datosFiltrados.length > 0 && (
							<>
							<TablaVacaInfo completo={false} datos={datosFiltrados} />
							<TablaVacaEventos completo={false} vaca={props.vaca} />
							<TablaVacaEventosNotificaciones completo={false} vaca={props.vaca} />
							</>
						)}
					</div>
				</div>
			</BlockUi>
		)
};

export default InfoVacas;