import React, {useEffect, useState} from "react";
import BlockUi from "react-block-ui";
// import Swal from "sweetalert2";
import Api from "../../../../../api/global.service.js";
import ApiTecnicosUrls from "../../../../../constants/ApiTecnicos";
import ExportarTablaVacas from "./ExportarTablaVacas";
import NoData from "../../../../../components/content/no-data";
import {format} from 'date-fns'

const TablaVacas = (props) => {
	const [vacas, setVacas] = useState([]);
	const [blocking, setBlocking] = useState(false);

	useEffect(() => {
		obtenerReporteVacas();
	}, [props.id_productor]);

	const obtenerReporteVacas = () => {
		setBlocking(true);
		Api.getAll(ApiTecnicosUrls.reportes.edorep.vacas, {id_productor: props.id_productor})
			.then((response) => {
				setVacas(response.data.data);
				setBlocking(false);
			})
			.catch((e) => {
				setVacas([]);
				setBlocking(false);
			});
	};

	const exportarRegistros = () => {
		ExportarTablaVacas(vacas);
	}

	return (
		<BlockUi tag="div" blocking={blocking}>
			<div className="col-md-12" style={{borderRight: "2px solid #dadada"}}>
				{props.id_productor > 0 && (
					<div className="overflow-auto">
						<button className="btn btn-primary margin_right" onClick={() => exportarRegistros()} ><i className="fa fa-download" /> </button>
						<table className="table table-bordered table-hover">
							<thead>
								<tr>
									<th>No. Vaca</th>
									<th>ID SINIIGA</th>
									<th>ESTADO FISIOL.</th>
									{/* <th>SEXO CRÍA U. P.</th> */}
									<th>FECHA DE PARTO</th>
									<th>NÚMERO DE PARTOS</th>
									{/* <th>FECHA 1er SERVICIO</th>
                <th>FECHA ULT SERVICIO</th>
                <th>No SERV</th>
                <th>TIPO SERV</th>
                <th>TORO</th>*/}
									<th>DX</th>
									{/* <th>D Ins</th>
                <th>DPS</th> */}
									<th>D A</th>
									<th>DEL</th>
									{/* <th>FECHA A SECAR</th> */}
									<th>FECHA DE SECADO</th>
									{/* <th>DIAS EN SECAS</th>
                <th>FECHA A PARIR</th>
                <th>OBS</th> */}
									<th>FECHA ÚLTIMA INSEMINACIÓN</th>
								</tr>
							</thead>
							<tbody>
								{vacas.length > 0 && vacas.map((vaca) => (
									<tr key={vaca.id_vaca}>
										<td>{vaca.no_vaca}</td>
										<td>{vaca.id_siniiga}</td>
										<td>{vaca.estado_fisiologico}</td>
										{/* <td>{vaca.sexo_cria_ultimo_parto}</td> */}
										<td>{vaca.fecha_parto ? format(new Date(vaca.fecha_parto + "T00:00:00.000"), "dd/MM/yyyy") : ""}</td>
										<td>{vaca.no_partos}</td>
										{/* <td>{vaca.fecha_primer_servicio}</td>
					<td>{vaca.fecha_ultimo_servicio}</td>
					<td>{vaca.no_servicios}</td>
					<td>{vaca.tipo_servicio}</td>
					<td>{vaca.toro}</td>*/}
										<td>{vaca.dx}</td>
										{/* <td>{vaca.d_ins}</td>
					<td>{vaca.dps}</td> */}
										<td>{vaca.d_a}</td>
										<td>{vaca.del}</td>
										{/* <td>{vaca.fecha_secar}</td> */}
										<td>{vaca.fecha_secado ? format(new Date(vaca.fecha_secado + "T00:00:00.000"), "dd/MM/yyyy") : ""}</td>
										{/* <td>{vaca.dias_secas}</td>
					<td>{vaca.fecha_a_parir}</td>
					<td>{vaca.obs}</td> */}
										<td>{vaca.fecha_ultima_inseminacion ? format(new Date(vaca.fecha_ultima_inseminacion + "T00:00:00.000"), "dd/MM/yyyy") : ""}</td>
									</tr>
								))}
							</tbody>
						</table>

						{vacas.length == 0 && <NoData />}
					</div>
				)}
			</div>
		</BlockUi>
	);
};

export default TablaVacas;
