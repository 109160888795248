import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import AsistenteUsuariosTabla from './AsistenteUsuariosTabla.jsx';

const AsistenteUsuariosListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Información de usuarios</Link></li>
        <li className="breadcrumb-item active">Listado de usuarios</li>
      </ol>
      <h1 className="page-header">Información de usuarios <small></small></h1>
      <Panel>
        <PanelHeader>Usuarios registrados</PanelHeader>
        <PanelBody>
            <AsistenteUsuariosTabla/>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default AsistenteUsuariosListar;
