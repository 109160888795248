import React from "react";
import MiTecnico from './mitecnico/MiTecnico';
import MisAsistencias from './misasistencias/MisAsistencias';

const MisBeneficios = (props) => {
  return (
    <>
      <h3>Mis beneficios margarita</h3>
      {props.id_productor > 0 && (
          <>
            <MiTecnico id_productor={props.id_productor} />
            <MisAsistencias id_productor={props.id_productor} />
          </>
      )}
    </>
  );
};
export default MisBeneficios;