import xlsx from "json-as-xlsx";
import moment from 'moment';

const ListadoVacasExportar = (content) => {
    let columns = [
        { label: 'ID Vaca', value: 'id_vaca' },
        { label: 'Proveedor', value: 'nombre_proveedor' },
        { label: 'ID UGRJ', value: 'id_ugrj' },
        { label: 'ID Margarita', value: 'id_margarita' },
        { label: 'Rancho', value: 'rancho' },
        { label: 'Nombre', value: 'nombre' },
        { label: 'Fecha de Ingreso', value: 'fecha_ingreso' },
        { label: 'Fecha de Nacimiento', value: 'fecha_nacimiento' },
        { label: 'Raza', value: 'nombre_raza' },
        { label: 'Tipo', value: 'nombre_tipo_vaca' },
        { label: 'Estado Reproductivo', value: 'nombre_estado_reproductivo' },
        { label: 'Última inseminacion', value: 'fecha_ultima_inseminacion' },
        { label: 'Último celo', value: 'fecha_ultimo_celo' },
        { label: 'Último diagnostico preñez', value: 'fecha_ultimo_diagnostico_prenez' },
        { label: 'Último monto', value: 'fecha_ultimo_monto' },
        { label: 'Último parto', value: 'fecha_ultimo_parto' },
        { label: 'Último secar', value: 'fecha_ultimo_secar' },
        { label: 'Fecha de registro', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'Listado_Vacas',
        fileName: 'Listado_Vacas_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default ListadoVacasExportar;
