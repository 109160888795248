import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import ArchivosEnviarForm from './ArchivosEnviarForm';
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import  '../../../nuup/styles/Tablas.css'
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import ArchivosExportarProductor from './ArchivosExportarProductor';
var dataArray = [];
const ArchivosEnviarProductorTabla = (props) => {
	const [show, setShow] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [filtro] = useState({
		usuario : 0,
		categoria : 0,
		ruta : 0
	});
	const [state, setState] = useState({
	id_user : 0,
	archivos: [],
	currentUsuario: null,
	currentIndex: -1
	});

	const columns = [
		{
			name: 'ID',
			selector: 'id_avisos_users',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'PRODUCTOR',
			maxWidth: '350px',
			sortable: true,
			right: false,
			cell: row => <div>{row.nombre_proveedor}</div>
		},

		{
			name: 'TÍTULO',
			selector: 'titulo',
			maxWidth: '350px',
			sortable: true,
			right: false
		},
		{
			name: 'NOTICIA',
			selector: 'aviso',
			maxWidth: '550px',
			sortable: true,
			right: false
		},

		{
			name: 'VISTO',
			maxWidth: '50px',
			sortable: true,
			right: false,
			cell: row => <div>{(row.visto === 1) ? 'SI' : 'NO'}</div>
		},
		{
			name: 'FECHA VISTO',
			selector: 'visto_fecha',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'FECHA ENVÍO',
			selector: 'creado',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			maxWidth: '150px',
			cell: (row) => <><DeleteButton onClick={() => deleteNoticiaProductor(row.id_avisos_users)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];

	useEffect(() => {
		obtenerNoticiasProductor();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtro]);

	  const obtenerNoticiasProductor = ()  => {
		Swal.showLoading();	
		Api.getAll('noticias/archivos/productores',filtro)
		  .then(response => {
			Swal.close();
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
		  })
		  .catch(e => {
			Swal.close();
			console.log(e);
		  });
	  }

	  // eslint-disable-next-line no-unused-vars
	  const [selectedRows, setSelectedRows] = React.useState([]);
	     // eslint-disable-next-line no-unused-vars 
	  const [toggleCleared, setToggleCleared] = React.useState(false);
  

	  const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
		props.onChangeSeleccionados(state.selectedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const deleteNoticiaProductor = (id_avisos_users) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete('avisos/noticias/archivos/productores',id_avisos_users)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Usuario eliminado.',
						  'success'
						);
					}
				obtenerNoticiasProductor();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el usuario.',
					'error'
				  );
				  obtenerNoticiasProductor();
				});

			}
		  })

	};


	
	const hideFormEdit = () => {
		setState({...state,id_user : 0});
		setShow(false);
	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}


	return (
      <div>
		  {/*
			<div className="row">
			  <div className="col-md-8 offset-md-2">
				<div className="row">
					<div className="col-md-3">
						<FiltroUsuarios onChange={(event) => filtrarTabla('usuario',event.target.value)} showLoading={false}  />
					</div>
					<div className="col-md-6">
						<FiltroRutas onChange={(event) => filtrarTabla('ruta',event.target.value)} showLoading={false}  />
					</div>

					<div className="col-md-3">
						<FiltroNotificacionesCategorias onChange={(event) => filtrarTabla('categoria',event.target.value)} showLoading={false}  />
					</div>
				</div>
			  </div>
		  </div>
		  */}

		  <HerramientasTablas 
				txtBusqueda="Buscar archivo" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/noticias/archivos/registrar" 
				columnasFiltrar={["id_aviso","productor","categoria","titulo","aviso","creado"]}
				exportar={ArchivosExportarProductor}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>


			<DataTable
				pagination
				columns={columns}
				data={datosFiltrados}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				onSelectedRowsChange={handleRowSelected}
			/>

			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Enviar notificación
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ArchivosEnviarForm />
              </Modal.Body>
            </Modal>


		</div>
    );
  };
export default ArchivosEnviarProductorTabla;
