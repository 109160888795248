import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../../../api/global.service';
import 'bootstrap/dist/css/bootstrap.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../../nuup/componentes/Forms/FormSelectApi.jsx';
import { Link } from 'react-router-dom';
import {
  Panel,
  PanelHeader,
  PanelBody,
} from '../../../components/panel/panel.jsx';
import FormInputFile from '../../../nuup/componentes/Forms/FormInputFile.jsx';
import procesarCola from '../../../nuup/helpers/procesarCola';
import ArchivosUsuariosTabla from './ArchivosUsuariosTabla';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';

const ArchivosEnviarForm = () => {
  const [file, setFile] = useState();
  const [centro] = useState(0);
  const [ruta] = useState(0);
  const [blocking, setBlocking] = useState(false);
  const [filterRole, setFilterRole] = useState('Productor');

  const schema = yup.object().shape({
    id_avisos_tipos_categoria: yup
      .number('Seleccione un tipo de archivo')
      .min(1, 'Seleccione un tipo de archivo')
      .required('Seleccione un tipo de archivo'),
    titulo: yup.string('Introduzca título').required('Introduzca título'),
    aviso: yup
      .string('Introduzca información')
      .required('Introduzca información'),
    descripcion: yup
      .string('Introduzca descripción')
      .required('Introduzca descripción'),
    file: yup
      .mixed()
      .required('Requerido')
      .test('fileFormat', 'Solo se permiten archivos .pdf o .jpg', (value) => {
        return (
          value[0] && ['application/pdf', 'image/jpeg'].includes(value[0].type)
        );
      }),
  });

  const [seleccionados, setSeleccionados] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      id_avisos_tipos_categoria: 0,
      titulo: '',
      aviso: '',
      descripcion: '',
    },
  });

  const onSubmit = (data) => {
    enviarNotificaciones(data);
  };

  const enviarNotificaciones = (values) => {
    const formData = new FormData();
    var _values = Object.keys(values).map((key) => [key, values[key]]);
    _values.forEach(function (item) {
      if (item[0] !== 'file') {
        formData.append(item[0], item[1]);
      }
    });

    formData.append('file', file);
    formData.append('ids', seleccionados);
    if (seleccionados.length > 0) {
      setBlocking(true);
      Api.createMultiPart('noticias/archivos', formData)
        .then((response) => {
          setBlocking(false);
          procesarCola();
          let _result = response.data;
          if (_result.success === 1) {
            Swal.close();
            Swal.fire('Enviado!', 'Notificaciones enviadas.', 'success');
          }
        })
        .catch((e) => {
          setBlocking(false);
          Swal.fire('Error!', 'Error al enviar notificaciones.', 'error');
        });
    } else {
      setBlocking(false);
      Swal.fire(
        'Error',
        'Seleccione al menos un registro para enviar',
        'question'
      );
    }
  };

  const handleSeleccionados = (_arr) => {
    console.log('handleSeleccionados');
    console.log(_arr);
    let _ids = [];
    for (let a = 0; a < _arr.length; a++) {
      _ids.push(_arr[a].id);
    }
    setSeleccionados(_ids);
  };

  const onChange = (e) => {
    console.log('onChange');
    setFile(e.target.files[0]);
  };

  return (
    <>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/administracion/notificaciones/archivos">
              Registrar archivo
            </Link>
          </li>
          <li className="breadcrumb-item active"></li>
        </ol>
        <h1 className="page-header">
          Noticias y comunicados con archivos <small></small>
        </h1>

        <div className="row">
          <div className="col-md-12">
            <Panel>
              <PanelHeader>Datos del archivo</PanelHeader>
              <PanelBody>
                <BlockUi
                  tag="div"
                  message=""
                  blocking={blocking}
                  renderChildren={true}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-12">
                        <FormSelectApi
                          id="id_avisos_tipos_categoria"
                          name="id_avisos_tipos_categoria"
                          label="Tipo de archivo"
                          url="notificaciones/categoriasarchivos"
                          keyArray="id_avisos_tipos_categoria"
                          labelArray="aviso_categoria"
                          showLoading={true}
                          error={errors.id_avisos_tipos_categoria}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <FormInput
                          id="titulo"
                          type="text"
                          name="titulo"
                          label="Título"
                          error={errors.titulo}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <FormInput
                          id="descripcion"
                          name="descripcion"
                          label="Descripción (Se mostrará en la notificación Push)"
                          error={errors.descripcion}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <FormInput
                          id="aviso"
                          name="aviso"
                          label="Aviso (Se mostrará en el listado de notificaciones)"
                          rows={5}
                          error={errors.aviso}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <FormInputFile
                          id="file"
                          name="file"
                          label="Seleccione archivo a enviar"
                          keyArray="file"
                          labelArray="tipo_documento"
                          error={errors.file}
                          register={register}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    {/* Filters for users list */}
					          <hr />
					          <h5>Filtrar por:</h5>
                    <div className="row">
                      <div className="col-md-2">
                        <select
						              className='form-control'
                          value={filterRole}
                          onChange={(e) => setFilterRole(e.target.value)}
                        >
                          <option value={'Productor'}>Productor</option>
                          <option value={'Administrador'}>Administrador</option>
                          <option value={'Equipo'}>Equipo</option>
                        </select>
                      </div>
                    </div>

                    {/* Users list */}
                    <ArchivosUsuariosTabla
                      id_centro_colecta={centro}
                      id_ruta={ruta}
                      role={filterRole}
                      onChangeSeleccionados={handleSeleccionados}
                    />

                    <button className="btn btn-primary btn-block" type="submit">
                      Enviar notificación
                    </button>
                  </form>
                </BlockUi>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArchivosEnviarForm;
