import React, { useState } from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
const MenuMeses = (props) => {
  const [mes, setMes] = useState('Seleeccione un mes');
  return (
    <>
    <ButtonGroup aria-label="Basic example">
      <Button onClick={() => {props.getData(1,2022);setMes('Enero')}} variant="secondary">Enero</Button>
      <Button onClick={() => {props.getData(2,2022);setMes('Febrero')}} variant="secondary">Febrero</Button>
      <Button onClick={() => {props.getData(3,2022);setMes('Marzo')}} variant="secondary">Marzo</Button>
      <Button onClick={() => {props.getData(4,2022);setMes('Abril')}} variant="secondary">Abril</Button>
      <Button onClick={() => {props.getData(5,2022);setMes('Mayo')}} variant="secondary">Mayo</Button>
      <Button onClick={() => {props.getData(6,2022);setMes('Junio')}} variant="secondary">Junio</Button>
      <Button onClick={() => {props.getData(7,2022);setMes('Julio')}} variant="secondary">Julio</Button>
      <Button onClick={() => {props.getData(8,2022);setMes('Agosto')}} variant="secondary">Agosto</Button>
      <Button onClick={() => {props.getData(9,2022);setMes('Septiembre')}} variant="secondary">Septiembre</Button>
      <Button onClick={() => {props.getData(10,2022);setMes('Octubre')}} variant="secondary">Octubre</Button>
      <Button onClick={() => {props.getData(11,2022);setMes('Noviembre')}} variant="secondary">Noviembre</Button>
      <Button onClick={() => {props.getData(12,2022);setMes('Diciembre')}} variant="secondary">Diciembre</Button>
    </ButtonGroup>
    <div>
      <h3 style={{paddingTop : 10}}>{mes}</h3>
    </div>
    </>
  );
  };
export default MenuMeses;
