import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import UsuariosLoginExportar from './UsuariosLoginExportar';
import getUrlFiltros from '../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
var dataArray = [];
const UsuariosLoginTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const columns = [
		{
			name: 'ID',
			selector: 'id_user',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'ACCESO',
			selector: 'username',
			sortable: true,
			right: false
		},
		{
			name: 'CORREO ELECTRÓNICO',
			selector: 'email',
			sortable: true,
			right: false
		},
		{
			name: 'PROVEEDOR / CONTACTO',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.nombre_proveedor}</div>{row.firstname + ' ' + row.lastname1 + ' ' + row.lastname2}</div>
		},
		{
			name: 'ID UGRJ',
			selector: 'id_ugrj',
			sortable: true,
			right: false
		},
		{
			name: 'ID Margarita',
			selector: 'id_margarita',
			sortable: true,
			right: false
		},

		{
			name: 'TOKEN (Push)',
			selector: 'token',
			sortable: true,
			right: false
		},

		{
			name: 'ÚLTIMO LOGIN',
			selector: 'ultimo_login',
			sortable: true,
			right: false
		},

	];



	useEffect(() => {
		obtenerLogins(false);
	}, []);

	  const obtenerLogins = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,'usuarios/logins')).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);

		  });
	  }




	  const filtrarUsuarios = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerLogins(true);
		}else{
			obtenerLogins(false);
		}
	};


	  const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" blocking={blocking}>
			<HerramientasTablas 
				txtBusqueda="Buscar login" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["id_user","id_ugrj","id_margarita","username","email","nombre_proveedor","ultimo_login"]}
				exportar={UsuariosLoginExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				filtrarRegistros={true}
				funcionFiltrar={filtrarUsuarios}
				filtros={['centro','ruta','productor']}
			/>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default UsuariosLoginTabla;
