const MenuAdmin = [
  { 
  	path: '/', icon: 'fa fa-th', title: 'Inicio'
  },
  { 
    path: '/administracion/usuarios', icon: 'fa fa-user', title: 'Usuarios',
    children: [
      { path: '/administracion/usuarios', title: 'Listado de usuarios' },
      { path: '/administracion/usuarios/registrar', title: 'Registrar usuario' },
      { path: '/administracion/usuarios/logins', title: 'Listado de logins' },
      { path: '/administracion/usuarios/info', title: 'Información general' },
    ]
  },
  { 
    path: '/administracion/tecnicos', icon : 'fa fa-th', title: 'Técnicos',
    children: [
      { path: '/administracion/tecnicos/accesos', title: 'Accesos' },
      { path: '/administracion/tecnicos/informacion', title: 'Información' },
      { path: '/administracion/tecnicos/personalizados', title: 'Personalizados' }
    ]
  },
  { 
  	path: '/administracion/notificaciones/solopush', icon: 'fa fa-bell', title: 'Notificaciones Push'
  },
  { 
    path: '/administracion/notificaciones/whatsapp', icon : 'fab fa-whatsapp', title: 'Notificaciones Whatsapp',
    children: [
      { path: '/administracion/notificaciones/whatsapp/listado', title: 'Listado' },
      { path: '/administracion/notificaciones/whatsapp', title: 'Enviar' },
    ]
  },
  
  { 
    path: '/administracion/notificaciones/reporte', icon : 'fa fa-file-pdf', title: 'Reportes PDF',
    children: [
      { path: '/administracion/notificaciones/reporte', title: 'Reportes enviados' },
      { path: '/administracion/notificaciones/reporte/archivos', title: 'Enviar reporte' },
    ]
  },


  { 
    path: '/administracion/noticias', icon : 'fa fa-newspaper', title: 'Noticias',
    children: [
     
      {
        path: '/administracion/noticias', icon: 'fa fa-text-width', title: 'Noticias con texto',
        children: [
          { path: '/administracion/noticias', title: 'Enviadas' },
          { path: '/administracion/noticias/productor', title: 'Enviadas por productor' },
          { path: '/administracion/noticias/registrar/0', title: 'Registrar noticia' },
        ]
      },
      { 
        path: '/administracion/noticias/archivos', icon : 'fa fa-file', title: 'Noticias con archivo',
        children: [
          { path: '/administracion/noticias/archivos', title: 'Archivos enviados' },
          { path: '/administracion/noticias/archivos/registrar', title: 'Enviar archivo' },
        ]
      },

      
    ]
  },

    

{ 
  path: '/administracion/videos', icon: 'fa fa-play', title: 'Videos',
  children: [
        { path: '/administracion/videos', title: 'Listado' },
        { path: '/administracion/videos/registrar', title: 'Registrar' }
  ]
},

{ 
  path: '/administracion/contactos', icon: 'fa fa-users', title: 'Contactos de interés',
  children: [
        { path: '/administracion/contactos', title: 'Listado' },
        { path: '/administracion/contactos/registrar/0', title: 'Registrar' }
  ]
},
{ 
  path: '/administracion/vacas', icon: 'fa fa-list', title: 'Vacas',
  children: [
        { path: '/administracion/vacas/listado', title: 'Listado de vacas' },
        { 
          path: '/administracion/vacas/seguimiento', icon: 'fa fa-list', title: 'Seguimiento',
          children: [
                { path: '/administracion/vacas/seguimiento', title: 'Totales' },
                { path: '/administracion/vacas/seguimiento/detalle', title: 'Detalle' }
          ]
        },
        { path: '/administracion/vacas/eventos', title: 'Vacas Eventos' },
  ]
},
{ 
  path: '/administracion/dynamics', icon : 'fa fa-file-pdf', title: 'FIRA',
  children: [
    { path: '/administracion/reportes/tecnicos/archivos', title: 'Reportes técnicos' },
  ]
},
{ 
  path: '/administracion/historiales', icon: 'fa fa-history', title: 'Historiales',
  children: [
        { path: '/administracion/historiales/inventarios', title: 'Inventarios' },
        { path: '/administracion/historiales/vacas', title: 'Cambios en vacas' },
        { 
          path: '/administracion/historiales/kpis', icon: 'fa fa-list', title: 'KPIs',
          children: [
                { path: '/administracion/historiales/kpis/vacas', title: 'KPIs Vacas' },
                { path: '/administracion/historiales/kpis/hatos', title: 'KPIs Hatos' }
          ]
        }
  ]
},
{ 
  path: '/administracion/dynamics', icon : 'fa fa-info', title: 'Dynamics',
  children: [
    { 
      path: '/administracion/dynamics', title: 'Encuestas mensuales',
      children: [
        { path: '/administracion/dynamics/encuestas', title: 'Búsqueda' },
        { path: '/administracion/dynamics/encuestas/inventarios', title: 'Inventarios' },
        { path: '/administracion/dynamics/encuestas/costos', title: 'Costos' },
        { path: '/administracion/dynamics/encuestas/asistencias', title: 'Asistencia Técnica' }
      ]
    },
    { path: '/administracion/dynamics/calidades', title: 'Calidades' },
    { path: '/administracion/dynamics/liquidaciones', title: 'Liquidaciones' }
  ]
}
//{ 
//  path: '/administracion/importador', icon: 'fa fa-upload', title: 'Importador',
//  children: [
//        { path: '/administracion/importador/excel', title: 'Importador excel' },
//  ]
//}
]
export default MenuAdmin;