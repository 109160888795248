import React, { useEffect } from "react";
import HerramientasTablasFiltroProductorTabla from "./HerramientasTablasFiltroProductorTabla";
import setFiltros from "../../Herramientas/setFiltros";
const HerramientasTablasFiltroProductor = (props) => {
  const { quitarSeleccionados } = props;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeleccionados = (_arr) => {
    let _ids = [];
    for (let a = 0; a < _arr.length; a++) {
      _ids.push(_arr[a].id);
    }
    setFiltros(3, _ids);
  };

  return (
    <>
      <HerramientasTablasFiltroProductorTabla
        onChangeSeleccionados={handleSeleccionados}
        quitarSeleccionados={quitarSeleccionados}
      />
    </>
  );
};
export default HerramientasTablasFiltroProductor;
