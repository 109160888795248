import React from "react";

const DynamicsEncuestasAsistenciasListado = (props) => {

  const setEncuesta = (encuesta) => {
    props.encuestaSeleccionada(encuesta);
  };



  const validaAsistencia = (encuesta,tipo) => {
    let className = "";
    let text = "";
      if((encuesta.tch_areadeactividades === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_administracion === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_administracionotro === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_calidad === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_calidadotro === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_nutricion === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_nutricionotro === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_saludanimal === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_saludanimalotro === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_reproduccion === null ||encuesta.tch_areadeactividades === '') &&
      (encuesta.tch_reproduccionotro === null ||encuesta.tch_areadeactividades === '')){
        text += ",Asistencia técnica: No hay datos";
        className = "btn-danger";
      }


   if(encuesta.array === false){
    className = "btn-danger";
    }

    

  if(encuesta.mysql){
    className = "btn-success";
  }

    if(tipo === 'className'){
      return className;
    }else{
      return text;
    }

  };

  return (
    <div className="row">
		{props.encuestas.length > 0 && (
    <div className="col-md-12">
      <hr />
    <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
				        <th>Encuesta</th>
                <th>ID Margarita</th>
                <th>ID UGRJ</th>
                <th>Rancho</th>
                <th>Fecha</th>
                <th>Área de actividades</th>
                  <th>Administración</th>
                  <th>Administración (Otro)</th>
                  <th>Calidad</th>
                  <th>Calidad (Otro)</th>
                  <th>Nutrición</th>
                  <th>Nutrición (Otro)</th>
                  <th>Salud Animal</th>
                  <th>Salud Animal (Otro)</th>
                  <th>Reproducción</th>
                  <th>Reproducción (Otro)</th>
              </tr>
            </thead>



            <tbody>
              {props.encuestas.map((encuesta,index) => (
              <tr className={validaAsistencia(encuesta,'className')} key={encuesta.tch_visitamensualid}>
                <td>{index + 1}</td>


                {encuesta._tch_idvisitamensual_value ? (
                  <td>
                    <a className="btn" target="_blank" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_visitamensual&id=' + encuesta._tch_idvisitamensual_value}>{'SI'}</a>
                  </td>
                  ) : (
                  <td>
                    {'NO'}
                    </td>
                  )}


                <td>
                  <a className="btn" target="_blank" href={'https://tecnoleche.crm.dynamics.com/main.aspx?appid=22256c81-ba91-eb11-b1ac-000d3a1f5f37&cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_visitatecnica&id=' + encuesta.tch_visitatecnicaid}>{encuesta.tch_foliovisitatecnica}</a>
                </td>
                <td>{encuesta.tch_idProductor.tch_idproductor}</td>
                <td>{encuesta.tch_idProductor.tch_idproveedor}</td>
                <td>{(!encuesta.tch_idRancho) ? '' : encuesta.tch_idRancho.msdyn_name }</td>
                <td>{encuesta.tch_fechavisita}</td>
                <td className={(encuesta.tch_areadeactividades === null) ? 'btn-danger' : ''}>{encuesta.tch_areadeactividades}</td>
                <td className={(encuesta.tch_administracion === null) ? 'btn-danger' : ''}>{encuesta.tch_administracion}</td>
                <td className={(encuesta.tch_administracionotro === null) ? 'btn-danger' : ''}>{encuesta.tch_administracionotro}</td>
                <td className={(encuesta.tch_calidad === null) ? 'btn-danger' : ''}>{encuesta.tch_calidad}</td>
                <td className={(encuesta.tch_calidadotro === null) ? 'btn-danger' : ''}>{encuesta.tch_calidadotro}</td>
                <td className={(encuesta.tch_nutricion === null) ? 'btn-danger' : ''}>{encuesta.tch_nutricion}</td>
                <td className={(encuesta.tch_nutricionotro === null) ? 'btn-danger' : ''}>{encuesta.tch_nutricionotro}</td>
                <td className={(encuesta.tch_saludanimal === null) ? 'btn-danger' : ''}>{encuesta.tch_saludanimal}</td>
                <td className={(encuesta.tch_saludanimalotro === null) ? 'btn-danger' : ''}>{encuesta.tch_saludanimalotro}</td>
                <td className={(encuesta.tch_reproduccion === null) ? 'btn-danger' : ''}>{encuesta.tch_reproduccion}</td>
                <td className={(encuesta.tch_reproduccionotro === null) ? 'btn-danger' : ''}>{encuesta.tch_reproduccionotro}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

  );
  };
export default DynamicsEncuestasAsistenciasListado;
