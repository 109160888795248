import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import NoticiasEnviarTabla from './NoticiasEnviarTabla.jsx';

const NoticiasEnviarListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Listado de noticias</li>
      </ol>
      <h1 className="page-header">Envios de noticias <small></small></h1>
      <Panel>
        <PanelHeader>Noticias enviadas</PanelHeader>
        <PanelBody>
            <NoticiasEnviarTabla/>
            
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default NoticiasEnviarListar;
