import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import Api from "../../../api/global.service";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../../../nuup/componentes/Forms/FormInput.jsx";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Swal from "sweetalert2";
import DynamicsEncuestasListado from "./componentes/DynamicsEncuestasListado";
import DynamicsEncuestasInfo365 from "./componentes/DynamicsEncuestasInfo365";
import DynamicsEncuestasInfoMysql from "./componentes/DynamicsEncuestasInfoMysql";

import Modal from "react-bootstrap/Modal";

const DynamicsEncuestas = () => {
	const [show, setShow] = useState(false);

	const schema = yup.object().shape({
		valor: yup.string("Seleccione un valor"),
		tipo: yup.string("Seleccione un tipo"),
	  });
	
	  const [recarga, setRecarga] = useState(0);
	  const [btncancelar, setBtncancelar] = useState(false);
	  const [encuestas, setEncuestas] = useState([]);
	  const [encuesta, setEncuesta] = useState({});

	  const [state] = useState({
		valor: "",
		tipo: 1,
	  });
	
	  const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	  } = useForm({
		resolver: yupResolver(schema),
		mode: "onBlur",
		defaultValues: state,
	  });

	  const onSubmit = (values) => {
		Swal.showLoading();
		Api.getAll("dynamics365/buscar/encuesta", {
			fechaInicio: values.fechaInicio,
			fechaFin: values.fechaFin,
			valor: values.valor,
			tipo: values.tipo
		}).then((response) => {
			let _result = response.data;
			if (_result.success === 1) {
			  setEncuestas(_result.data);
			  setShow(true);
			  Swal.close();
			} else {
			  alert(_result.message);
			}
		  }).catch((e) => {
			Swal.fire("Error!", "Error al buscar información del usuario.", "error");
		  });
	  };

	  const hideFormEdit = () => {
		setShow(false);
	  };

	  const setEncuestaSeleccionada = (encuesta) => {
		setShow(false);
		setEncuesta(encuesta);
		console.log(encuesta);
	  };

	  


  return (
    <>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Dynamics</Link>
        </li>
        <li className="breadcrumb-item active">Encuetas Mensuales</li>
      </ol>
      <h1 className="page-header">
        Encuestas Mensuales <small>Dynamics</small>
      </h1>
      <Panel>
        <PanelHeader>Buscar encuesta</PanelHeader>
        <PanelBody>
			<>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">

			<div className="col-md-3">
            <FormInput
                  id="fechaInicio"
                  type="date"
                  name="fechaInicio"
                  label="Fecha Inicio"
                  error={errors.fechaInicio}
                  register={register}
                />
			</div>

			<div className="col-md-3">
			<FormInput
                  id="fechaFin"
                  type="date"
                  name="fechaFin"
                  label="Fecha Fin"
                  error={errors.fechaFin}
                  register={register}
                />
			</div>

              <div className="col-md-3">
                <FormInput
                  id="valor"
                  type="text"
                  name="valor"
                  label="ID"
                  error={errors.valor}
                  register={register}
                />
              </div>

              <div className="col-md-3">
                <label>{"Tipo de búsqueda"}</label>
                <select
                  id="tipo"
                  className={`form-control`}
                  name="tipo"
                  {...register("tipo")}
                >
                  <option value="1">ID Margarita</option>
                  <option value="2">ID UGRJ</option>
				  <option value="3">Rancho</option>
                </select>
              </div>

            </div>

            <div className="row">
              <div className="col-md-4 offset-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <button className="btn btn-primary btn-block" type="submit">
                      {"Buscar"}
                    </button>
                  </div>
                </div>
              </div>
			  {encuestas && encuestas.length > 0 && (
			  <div className="col-md-4 offset-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <a onClick={() => setShow(true)} className="btn btn-warning btn-block">
                      {"Seleccionar otra"}
                    </a>
                  </div>
                </div>
              </div>
			  )}
            </div>
          </form>

		<hr />

			<Modal
			show={show}
			onHide={() => hideFormEdit()}
			size="xl"
			>
			<Modal.Header closeButton>
			<Modal.Title id="example-custom-modal-styling-title">
				Resultados de la búsqueda
			</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<DynamicsEncuestasListado encuestaSeleccionada={(encuesta) => setEncuestaSeleccionada(encuesta)} encuestas={encuestas} />
			</Modal.Body>
		</Modal>




		  </>
        </PanelBody>
      </Panel>

	{encuesta.tch_visitamensualid && (
		<>
		<DynamicsEncuestasInfo365 encuesta={encuesta} />
		<DynamicsEncuestasInfoMysql encuesta_uuid={encuesta.tch_visitamensualid} encuesta={encuesta} />
	  </>

	)}

    </>
  );
};

export default DynamicsEncuestas;
