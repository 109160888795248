import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "./../components/panel/panel.jsx";
import TablaTotales from "../nuup/componentes/Tablas/TablaTotales.jsx";
import { toDate } from "date-fns";
import UltimaVezVisto from "../nuup/helpers/UltimaVezVisto.jsx";
const date = toDate(new Date());

const Home = () => {
  const [state, setState] = useState({
    anio: date.getYear() + 1900,
    mes: date.getMonth() + 1,
  });

  return (
    <>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item active">
          <Link to="/">Tablero</Link>
        </li>
      </ol>
      <h1 className="page-header">
        Tablero <small>Información general</small>
      </h1>
      <div className="row">
        <UltimaVezVisto />
        <div className="col-md-6">
          <Panel>
            <PanelHeader>Últimos accesos</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/accesos_apps"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "ultimo_acceso",
                  labelsText: "Productor",
                  dataText: "Último acceso",
                  perPage: 10,
                }}
                params={{
                  tipo: "registros",
                  anio: "",
                  mes: "",
                }}
              />
            </PanelBody>
          </Panel>
        </div>

        <div className="col-md-6">
          <Panel>
            <PanelHeader>Accesos mes actual por productor</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/accesos_apps"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "total",
                  labelsText: "Productor",
                  dataText: "Total accesos",
                  perPage: 10,
                }}
                params={{
                  tipo: "totales",
                  anio: state.anio,
                  mes: state.mes,
                }}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Panel>
            <PanelHeader>Total de accesos por productor</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/accesos_apps"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "total",
                  labelsText: "Productor",
                  dataText: "Total accesos",
                  perPage: 10,
                }}
                params={{
                  tipo: "totales",
                  anio: "",
                  mes: "",
                }}
              />
            </PanelBody>
          </Panel>
        </div>

        <div className="col-md-6">
          <Panel>
            <PanelHeader>Últimos registros de vacas</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/vacas_registradas"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "ultimo_registro",
                  labelsText: "Productor",
                  dataText: "Último registro",
                  perPage: 10,
                }}
                params={{
                  tipo: "registros",
                  anio: "",
                  mes: "",
                }}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <Panel>
            <PanelHeader>
              Vacas registradas mes actual por productor
            </PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/vacas_registradas"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "total",
                  labelsText: "Productor",
                  dataText: "Total vacas",
                  perPage: 10,
                }}
                params={{
                  tipo: "totales",
                  anio: state.anio,
                  mes: state.mes,
                }}
              />
            </PanelBody>
          </Panel>
        </div>

        <div className="col-md-6">
          <Panel>
            <PanelHeader>Vacas totales por productor</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/vacas_registradas"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "total",
                  labelsText: "Productor",
                  dataText: "Total vacas",
                  perPage: 10,
                }}
                params={{
                  tipo: "totales",
                  anio: "",
                  mes: "",
                }}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
};

export default Home;
