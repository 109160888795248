import React,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from "sweetalert2";
import Api from '../../../api/global.service';
import FormInputFile from '../../../nuup/componentes/Forms/FormInputFile.jsx';
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';


const ModalSubirPdf = (props) => {
    var _ruta = props.data.ruta + '';
    _ruta = _ruta.replace('avisos/pdf','storage/documentos');
	const [file,setFile] = useState();

    const schema = yup.object().shape({
		id_ugrj : yup.string('ID_UGRJ no definido'),
        ruta : yup.string('Ruta no definida'),
		file: yup.mixed().required('Requerido')
        .test('fileFormat', 'Solo se permiten archivos .pdf', (value) => {
          return value[0] && ['application/pdf'].includes(value[0].type);
        }),
	});

    const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: {
			id_ugrj : props.data.id_ugrj,
			ruta    : _ruta
		}
	});

	const creaArchivo = () => {
		Swal.showLoading();
		const formData = new FormData();
		formData.append('file',file);
        formData.append('id_ugrj',props.data.id_ugrj);
        formData.append('ruta',_ruta);
		Api.createMultiPart('notificaciones/archivos/subir/pdf',formData).then(response => {
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: 'Archivo cargado correctamente',
				  })
				  window.location.reload(); 
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}


	const onSubmit = () => {
		creaArchivo();
	}

    const onChange = (e) => {
		console.log('onChange');
		setFile(e.target.files[0]);
	  }
  


    const hideFormEdit = () =>{
        props.onCerrarSubirPdf();
    }




    return <>
            <Modal
                show={props.show}
                onHide={() => hideFormEdit()}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                {'Subir archivo PDF'}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
			<form onSubmit={handleSubmit(onSubmit)}>
                <FormInput 
						id="ruta"
						type="hidden"
						name="ruta"
						label="ruta"
						error={errors.ruta}
						register={register}
						/>

                    <FormInput 
						id="id_ugrj"
						type="hidden"
						name="id_ugrj"
						label="id_ugrj"
						error={errors.id_ugrj}
						register={register}
						/>

                <table className="table table-condensed">

                    <tr>
                        <th>
                            {'Productor'}
                        </th>
                        <td>
                            {props.data.firstname + ' ' + props.data.lastname1}
                        </td>
                    </tr>

                    <tr>
                        <th>
                            {'Acceso / Correo Electrónico'}
                        </th>
                        <td>
                            {props.data.username + ' / ' + props.data.email}
                        </td>
                    </tr>

                    <tr>
                        <th>
                            {'ID UGRJ / ID Margarita'}
                        </th>
                        <td>
                            {props.data.id_ugrj  + ' / ' + props.data.id_margarita}
                        </td>
                    </tr>

                    <tr>
                        <th>
                            {'Ruta'}
                        </th>
                        <td>
                            {_ruta}
                        </td>
                    </tr>

                    <tr>
                        <th>
                            {'Subir archivo (PDF)'}
                        </th>
                        <td>

                        <FormInputFile 
							id="file"
							name="file"
							label=""
							keyArray="file"
							labelArray="tipo_documento"
							error={errors.file}
							register={register}
							onChange={onChange}
						/>
                        </td>
                    </tr>
                
                    <tr>
                        <td colSpan="2">
                        <button className="btn btn-primary btn-block" onClick={handleSubmit((d) => onSubmit(d))}>Subir</button>
                        </td>
                    </tr>
                </table>
			</form>
            </Modal.Body>
        </Modal>
    </>
};
export default ModalSubirPdf;