import React, { useEffect, useState } from "react";
import Api from "../../../../api/global.service";
import DataTable from "react-data-table-component";
import estiloGeneral from "../../../../nuup/componentes/Tablas/estiloGeneral";
import "../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import HerramientasTablas from "../../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas";
import getUrlFiltros from "../../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros";
import {pagOptionsESsmall} from "../../../../nuup/helpers/pagOptionsES";

var dataArray = [];
const ListadoProductores = (props) => {
  const [filtradoAlert, setFiltradoAlert] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [seleccionado, setSeleccionado] = useState(0);

  useEffect(() => {
    if (seleccionado > 0) {
      props.productorSeleccionado(seleccionado);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionado]);

	const conditionalRowStyles= [
		{
		  when: row => row.statecode == 1,
		  style: {
			backgroundColor: 'rgba(155, 155, 155, 1)',
			color: 'white',
			'&:hover': {
			  cursor: 'pointer',
			},
		  },
		},
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "#dadada",
        userSelect: "none",
      },
    }
	  ];


  const columns = [
    {
      name: "PROVEEDOR / CONTACTO",
      sortable: true,
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 700 }}>{row.nombre_proveedor}</div>
          {row.firstname + " " + row.lastname1 + " " + row.lastname2}
        </div>
      ),
    },
    {
      name: "ID UGRJ",
      selector: "id_ugrj",
      maxWidth: "100px",
      sortable: true,
      right: false,
    },
  ];

  const handleRowClicked = (row) => {
    const updatedData = datosFiltrados.map((item) => {
      if (row.id !== item.id) {
        return {
          ...item,
          toggleSelected: false,
        };
      } else {
        setSeleccionado(item.id);
        return {
          ...item,
          toggleSelected: true,
        };
      }
    });

    setDatosFiltrados(updatedData);
  };

  useEffect(() => {
    setFiltradoAlert(false);
    obtenerUsuarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerUsuarios = (filtrado = false) => {
    setBlocking(true);
    Api.getAll(getUrlFiltros(filtrado, "usuarios"))
      .then((response) => {
        dataArray = response.data.data;
        setDatosFiltrados(dataArray);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  const filtrarUsuarios = () => {
    // Validar que existan elementos seleccionados
    if (
      localStorage.getItem("filtro_tipo") > 0 &&
      localStorage.getItem("filtro_valor").length > 0
    ) {
      obtenerUsuarios(true);
    } else {
      obtenerUsuarios();
    }
  };

  const handleChange = (datosFiltrados) => {
    setDatosFiltrados(datosFiltrados);
  };

  return (
    <BlockUi tag="div" blocking={blocking}>
      <HerramientasTablas
        txtBusqueda="Buscar usuario"
        datosTabla={dataArray}
        urlRegistrar=""
        columnasFiltrar={[
          "email",
          "nombre_proveedor",
          "tipo_proveedor",
          "username",
          "email_notification",
          "id_margarita",
          "id_ugrj",
          "created_at",
        ]}
        exportar={false}
        onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
        funcionFiltrar={filtrarUsuarios}
        filtrarRegistros={true}
        filtros={props.tecnico ? ["centro", "ruta", "productor"] : ["centro", "ruta", "productor", "nivel"]}
        filtradoAlert={filtradoAlert}
      />

      <DataTable
        pagination
        paginationPerPage={15}
        paginationComponentOptions={pagOptionsESsmall}
        columns={columns}
        data={datosFiltrados}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={estiloGeneral}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
      />
    </BlockUi>
  );
};
export default ListadoProductores;
