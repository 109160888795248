import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import VideosRegistrar from './VideosRegistrar';
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import { Link } from "react-router-dom";
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import VideosExportar from './VideosExportar';
import BotonDestacado from '../../nuup/componentes/Forms/BotonDestacado';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';

var dataArray = [];
const VideosTabla = (props) => {
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [state, setState] = useState({
	id_user : 0,
	usuarios: [],
	currentUsuario: null,
	currentIndex: -1
	});
	
	const columns = [
		{
			name: 'ID',
			selector: 'id_video',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'CATEGORíA',
			selector: 'nombre_categoria',
			sortable: true,
			maxWidth: '250px',
		},


		{
			name: 'TÍTULO',
			selector: 'titulo',
			sortable: true,
			maxWidth: '250px',
			right: false
		},

		{
			name: 'DESCRIPCIÓN',
			selector: 'descripcion',
			sortable: true,
			maxWidth: '350px',
			right: false
		},
		
		{
			name: 'URL',
			sortable: true,
			maxWidth: '150px',
			right: false,
			cell: row => <div><a rel="noopener noreferrer" target="_blank" href={'https://www.youtube.com/watch?v=' + row.url}>VER</a></div>
		},

		{
			name: 'DESTACADO',
			button: true,
			maxWidth: '150px',
			cell: (row) => <><BotonDestacado recargarTabla={obtenerVideos} destacado={row.destacado} uuid={row.uuid}/></>,
		},
		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			maxWidth: '200px',
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Link className="btn btn-xs btn-warning" to={'videos/registrar/' + row.id_video}><i className="fa fa-edit"></i></Link> <DeleteButton onClick={() => eliminaVideo(row.uuid)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];



	useEffect(() => {
		obtenerVideos();
	}, []);

	  const obtenerVideos = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.videos)
		  .then(response => {
			setBlocking(false);
			  dataArray = response.data.data; 
			  setDatosFiltrados(dataArray);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaVideo = (uuid) => {		
		console.log(uuid);
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete('videos', uuid)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Video eliminado.',
						  'success'
						);
					}
				obtenerVideos();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el video.',
					'error'
				  );
				  obtenerVideos();
				});

			}
		  })

	};


	const hideFormEdit = () => {
		setState({...state,id_user : 0});
		setShow(false);
	};
	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}
	return (
		<>
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>

		<HerramientasTablas 
				txtBusqueda="Buscar video" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/videos/registrar/0" 
				columnasFiltrar={["id_video","nombre_categoria","titulo","descripcion","created_at"]}
				exportar={VideosExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		  </BlockUi>

			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Registrar Usuario
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <VideosRegistrar id_user={state.id_user} />
              </Modal.Body>
            </Modal>
		</>
    );
  };
export default VideosTabla;
