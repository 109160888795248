import React from "react";
import MiInventarioInfo from "./MiInventarioInfo";
const MiInventario = (props) => {
  return (
    <>
      <h3>Mi inventario de ganado</h3>
      {props.id_productor > 0 && (
          <>
            <MiInventarioInfo rancho={props.rancho} id_productor={props.id_productor} />
          </>
      )}
    </>
  );
};
export default MiInventario;
