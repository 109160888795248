import React from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import TecnicosArchivosTabla from "./TecnicosArchivosTabla";

const TecnicosArchivosListar = () => {
  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Inicio</Link>
        </li>
        <li className="breadcrumb-item active">FIRA</li>
      </ol>
      <h1 className="page-header">
        Reportes de técnicos FIRA<small></small>
      </h1>
      <Panel>
        <PanelHeader>Archivos registrados</PanelHeader>
        <PanelBody>
          <TecnicosArchivosTabla />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default TecnicosArchivosListar;
