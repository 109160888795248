import React, { useEffect, useState } from "react";
import Api from "../../../../api/global.service";
import ApiUrls from "../../../../constants/Api";
import "../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import FormInput from "../../../../nuup/componentes/Forms/FormInput.jsx";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

var dataArray = [];
const TecnicoInfo = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);
  const [btncancelar, setBtncancelar] = useState(true);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup.object().shape({
    id: yup.number().min(0),
    username: yup
      .string()
      .matches(phoneRegExp, "Registro el número telefónico")
      .required(),
    password: yup.string(),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const [state, setState] = useState({
    id_tecnico: 0,
    rol: 3,
    username: "",
    password: "",
    passwordConfirmation: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: state,
  });

  const onSubmit = (values) => {
    Swal.showLoading();
    Api.update("usuarios/accesos", values.uuid, values)
      .then((response) => {
        let _result = response.data;
        if (_result.success === 1) {
          Swal.fire({
            icon: "success",
            title: "Acceso actualizado",
            text: "La página se actualizará",
          });
          setTimeout(function () {
            window.location.reload();
          }, 1500);
        } else {
          Swal.fire({
            icon: "error",
            title: "",
            text: _result.message,
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: e,
        });
      });
  };

  useEffect(() => {
    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.tecnicos.info + "/" + props.id_tecnico)
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          reset({
            uuid: dataArray[0].uuid,
            rol: 3,
            username: dataArray[0].username,
            email: dataArray[0].email,
          });
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    if (props.id_tecnico > 0) {
      obtenerInfo();
    }
  }, [props.id_tecnico]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {datos[0] && (
          <Card bg="light" style={{ width: "36rem" }}>
            <Card.Body>
              <Card.Title>
                {datos[0].firstname} {datos[0].lastname1} {datos[0].lastname2}
              </Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <strong>Id Técnico: </strong> {datos[0].id_tecnico}
              </ListGroupItem>
              <ListGroupItem>
                <strong>Uuid Dynamics: </strong> {datos[0].uuid_dynamics}
              </ListGroupItem>
              <ListGroupItem>
                <strong>Email: </strong> {datos[0].email}
              </ListGroupItem>
              <ListGroupItem>
                <FormInput
                  id="uuid"
                  type="hidden"
                  name="uuid"
                  label="uuid"
                  error={errors.uuid}
                  register={register}
                />
                <FormInput
                  id="username"
                  type="text"
                  name="username"
                  label="username"
                  error={errors.username}
                  register={register}
                />
                <FormInput
                  id="password"
                  type="text"
                  name="password"
                  label="Contraseña"
                  error={errors.password}
                  register={register}
                />
                <FormInput
                  id="passwordConfirmation"
                  type="text"
                  name="passwordConfirmation"
                  label="Confirmación"
                  error={errors.passwordConfirmation}
                  register={register}
                />

                <div className="col-md-12">
                  <button className="btn btn-primary btn-block" type="submit">
                    {btncancelar ? "Actualizar" : "Guardar"}
                  </button>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        )}
      </form>
    </BlockUi>
  );
};
export default TecnicoInfo;
