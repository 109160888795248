import React, { useEffect, useState } from "react";
import Api from "../../../api/global.service";
import DataTable from "react-data-table-component";
import estiloGeneral from "../../../nuup/componentes/Tablas/estiloGeneral";
import "../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import HerramientasTablas from "../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas";
import Modal from 'react-bootstrap/Modal';
import InfoVacas from './InfoVacas';

var dataArray = [];
const ListadoVacas = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [seleccionado, setSeleccionado] = useState(0);
	const [show, setShow] = useState(false);
  const [idVaca, setIdVaca] = useState(0);

	const [state, setState] = useState({
		id_user : 0,
		accesos: [],
		currentUsuario: null,
		currentIndex: -1
		});

  const columns = [
		{
			name: '',
			button: true,
			cell: (row) => <><button className="btn btn-xs btn-info" onClick={() => clickVacaDetalle(row.id_vaca)} ><i className="fa fa-search"></i></button></>,
		},
    {
      name: "NOMBRE/ESTADO",
      sortable: true,
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 700 }}>{row.nombre}</div>
          {row.nombre_estado_reproductivo}
        </div>
      ),
    }
  ];

  const clickVacaDetalle = (id_vaca) => { 
    setIdVaca(id_vaca);
    setShow(true);
  };

  const handleRowClicked = (row) => {
    const updatedData = datosFiltrados.map((item) => {
      if (row.id_vaca !== item.id_vaca) {
        return {
          ...item,
          toggleSelected: false,
        };
      } else {
        setSeleccionado(item.id_vaca);
        return {
          ...item,
          toggleSelected: true,
        };
      }
    });

    setDatosFiltrados(updatedData);
  };

  useEffect(() => {
    const obtenerVacas = (filtrado = false) => {
      setBlocking(true);
      Api.getAll("vacas/productor", { id_productor: props.id_productor })
        .then((response) => {
          dataArray = response.data.data;
          setDatosFiltrados(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    if (props.id_productor > 0) {
      obtenerVacas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_productor]);

  useEffect(() => {
    props.vacaSeleccionada(seleccionado);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionado]);

  const handleChange = (datosFiltrados) => {
    setDatosFiltrados(datosFiltrados);
  };

	const hideFormEdit = () => {
		setState({...state,id_user : 0});
		setShow(false);
	};


  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "#dadada",
        userSelect: "none",
      },
    },
  ];

  return (
    <BlockUi tag="div" blocking={blocking}>
      <HerramientasTablas
        txtBusqueda="Buscar vaca"
        datosTabla={dataArray}
        urlRegistrar=""
        columnasFiltrar={[
          "nombre",
          "nombre_estado_reproductivo",
          "nombre_tipo_vaca",
          "nombre_raza",
        ]}
        exportar={false}
        onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
      />

      <DataTable
        pagination
        paginationPerPage={15}
        columns={columns}
        data={datosFiltrados}
        Clicked
        noDataComponent={"Seleccione un productor"}
        customStyles={estiloGeneral}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
      />


          <Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Información de la vaca
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InfoVacas vaca={idVaca}/>
              </Modal.Body>
            </Modal>

    </BlockUi>
  );
};
export default ListadoVacas;
