const MenuFira = [
  {
    path: '/home', icon: 'fa fa-home', title: 'Inicio'
  },
  { 
    path: '/docs', icon: 'fa fa-file', title: 'Documentos'
  },
  // { 
  //   path: '/productores', icon: 'fas fa-user-circle', title: 'Productores'
  // }
]
export default MenuFira;