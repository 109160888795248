import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import ListadoProductores from './componentes/ListadoProductores';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import 'bootstrap/dist/css/bootstrap.min.css';
import MiPerfil from './componentes/miperfil/MiPerfil';
import MisVacas from './componentes/misvacas/MisVacas';
import MiInventario from './componentes/miinventario/MiInventario';
import MisBeneficios from './componentes/misbeneficios/MisBeneficios';
import MiNegocio from './componentes/minegocio/MiNegocio';

const UsuariosInformacionGeneral = ({tecnico=false}) => {
	const [productor, setProductor] = useState(0);
	const [idMargarita, setIdMargarita] = useState('');
	const [idUgrj, setIdUgrj] = useState('');
	const [rancho, setRancho] = useState('');

	const productorSeleccionado = (id_productor,id_margarita,id_ugrj,rancho) => {
		setProductor(id_productor);
		setIdMargarita(id_margarita);
		setIdUgrj(id_ugrj);
		setRancho(rancho);
	}


		return (
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item">Mis Productores</li>
					<li className="breadcrumb-item active"><Link to="/tecnicos/productores/info">Información General</Link></li>
				</ol>
				<h1 className="page-header">Información General</h1>
				<Panel>
					<PanelHeader></PanelHeader>
					<PanelBody>
					<div className="row">
						<div className="col-md-3" style={{borderRight : '2px solid #dadada'}}>
							<ListadoProductores productorSeleccionado={productorSeleccionado} tecnico={tecnico} />
						</div>

						<div className="col-md-9 overflow-auto" style={{borderRight : '2px solid #dadada'}}>
						{productor > 0 && 
						<Tabs defaultActiveKey="miperfil" id="uncontrolled-tab-example" className="mb-3">
							<Tab eventKey="miperfil" title="Mi Perfil">
								<MiPerfil id_productor={productor} />
							</Tab>
							<Tab eventKey="misvacas" title="Mis vacas">
								<MisVacas id_productor={productor} />
							</Tab>

							<Tab eventKey="miinventario" title="Mi inventario de ganado">
								<MiInventario id_ugrj={idUgrj} id_margarita={idMargarita} rancho={rancho} id_productor={productor} />
							</Tab>

							<Tab eventKey="misbeneficios" title="Mis beneficios margarita">
								<MisBeneficios id_ugrj={idUgrj} id_margarita={idMargarita} rancho={rancho} id_productor={productor} />
							</Tab>

							<Tab eventKey="minegocio" title="Mi Negocio">
								<MiNegocio id_ugrj={idUgrj} id_margarita={idMargarita} rancho={rancho} id_productor={productor}/>
							</Tab>

							</Tabs>
							}
						</div>
					</div>
					</PanelBody>
				</Panel>
			</div>
		)
};

export default UsuariosInformacionGeneral;