import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Api from "../../../../../api/global.service.js";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../../../../../nuup/componentes/Forms/FormInput.jsx";
import FormSelectApi from "../../../../../nuup/componentes/Forms/FormSelectApi.jsx";
import FormInputTextArea from "../../../../../nuup/componentes/Forms/FormInputTextArea.jsx";
import FormSelectArray from "../../../../../nuup/componentes/Forms/FormSelectArray.jsx";
import FormInputCheckbox from "../../../../../nuup/componentes/Forms/FormInputCheckbox.jsx";
import { format, addDays } from "date-fns";
import Swal from "sweetalert2";
import BlockUi from "react-block-ui";

export const capFirstLetterInSentence = (sentence) =>
  sentence
    .split(" ")
    .map((word) =>
      word.length > 1
        ? word[0].toUpperCase() + word.slice(1).toLowerCase()
        : word
    )
    .join(" ");

const NewEvent = (props) => {
  const calendarContext = useSelector((state) => state.calendarState);
  const eventContext = useSelector((state) => state.eventState);
  const dispatch = useDispatch();

  const { dayDetail, currentMonth, currentYear } = calendarContext;
  const [blocking, setBlocking] = useState(false);
  const [listaProductores, setListaProductores] = useState([]);

  const defaultDate = dayDetail.today
    ? new Date(currentYear, currentMonth - 1, dayDetail.today)
    : new Date();

  const [state] = useState({
    tipo_cita: 0,
    asunto: "",
    uuid_proveedor: 0,
    ubicacion: "",
    fecha_inicio: format(defaultDate, "yyyy-MM-dd"),
    hora_inicio: "07:00",
    fecha_fin: format(defaultDate, "yyyy-MM-dd"),
    hora_fin: "08:00",
    todo_el_dia: false,
    descripcion: "",
  });
  const [allDay, setAllDay] = useState(false);

  const schema = yup.object().shape({
    tipo_cita: yup
      .number()
      .moreThan(0, "Seleccione un tipo de cita")
      .required("Seleccione un tipo de cita"),
    asunto: yup.string().required("Ingrese un asunto"),
    uuid_proveedor: yup.string().nullable(),
    ubicacion: yup.string().nullable(),
    fecha_inicio: yup.string().required("Fecha inicio es requerida"),
    hora_inicio: yup.string().required("Hora inicio es requerida"),
    fecha_fin: yup.string().required("Fecha fin es requerida"),
    hora_fin: yup.string().required("Hora fin es requerida"),
    todo_el_dia: yup.boolean(),
    descripcion: yup.string().nullable(),
  });

  const [recarga, setRecarga] = useState(0);
  const [btncancelar, setBtncancelar] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: state,
  });

  const onSubmit = (rawRavlues) => {
    const todo_el_dia =
      typeof rawRavlues.todo_el_dia == "boolean"
        ? rawRavlues.todo_el_dia
        : rawRavlues.todo_el_dia === "1";
    const values = { ...rawRavlues, todo_el_dia: todo_el_dia };
    if (compareStartFinishDates(values)) {
      if (values.id_tecnico_planeacion > 0) {
        actualizarEvento(values);
      } else {
        crearEvento(values);
      }
    }
  };

  const crearEvento = (values) => {
    var d = new Date();
    var n = d.getTime();
    Swal.showLoading();
    Api.create("tecnicos/planeacion", {
      tipo_cita: values.tipo_cita,
      asunto: values.asunto,
      uuid_proveedor: values.uuid_proveedor,
      ubicacion: values.ubicacion,
      fecha_inicio: values.fecha_inicio,
      hora_inicio: values.hora_inicio,
      fecha_fin: values.fecha_fin,
      hora_fin: values.hora_fin,
      todo_el_dia: values.todo_el_dia,
      descripcion: values.descripcion,
    })
      .then((response) => {
        setRecarga(n);
        reset(state);
        let _result = response.data;
        if (_result.success === 1) {
          props.accionEvento(Date.now());
          props.cerrarModal();
          Swal.close();
          Swal.fire("Guardado!", "Evento creado.", "success");
        }
      })
      .catch((e) => {
        setRecarga(n);
        Swal.fire("Error!", "Error al crear el evento.", "error");
      });
  };

  const actualizarEvento = (values) => {
    Swal.showLoading();
    Api.update("tecnicos/planeacion", values.id_tecnico_planeacion, values)
      .then((response) => {
        let _result = response.data;
        if (_result.success === 1) {
          cancelarEdit();
          props.accionEvento(Date.now());
          props.cerrarModal();
          Swal.fire({
            icon: "success",
            title: "",
            text: _result.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "",
            text: _result.message,
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: e,
        });
      });
  };

  const cancelarEdit = () => {
    setBtncancelar(false);
    reset({
      id: 0,
      email: "",
      id_contacto: "",
      id_margarita: "",
      nivel: 0,
      rol: 0,
      username: "",
      uuid: "",
    });
  };

  useEffect(() => {
    if (props.eventoEditar.id_tecnico_planeacion > 0) {
      setBlocking(true);
      if (listaProductores.length > 0 && !loading) {
        const todoElDia = props.eventoEditar.todo_el_dia === "1";
        reset({
          ...props.eventoEditar,
          todo_el_dia: todoElDia,
        });
        setAllDay(todoElDia);
        setBlocking(false);
      }
    } else {
      reset(state);
    }
  }, [props.eventoEditar.id_tecnico_planeacion, listaProductores, loading]);

  // query productores list API
  useEffect(() => {
    Api.getAll("tecnicos/catalogos/productores")
      .then((response) => {
        let productoresMod = response.data.data.map((item) => {
          const itemProductor = {
            ...item,
            nombre_completo: item.id_ugrj +
              " - " +
              capFirstLetterInSentence(
                item.firstname + " " + item.lastname1 + " " + item.lastname2
              ),
          };
          return itemProductor;
        });

        // only productores Activos => statecode = 0
        productoresMod = productoresMod.filter(productor => productor.statecode == 0)

        // order by Name = ID_UGRJ
        productoresMod.sort((a, b) => a.nombre_completo.localeCompare(b.nombre_completo));
        setListaProductores(productoresMod);
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allDayhandler = (value) => {
    if (value) {
      const { fecha_inicio } = getValues();
      const fecha_dia =
        fecha_inicio !== ""
          ? new Date(fecha_inicio + "T00:00:00.000")
          : new Date();
      const siguiente_dia = addDays(fecha_dia, 1);
      setValue("fecha_inicio", format(fecha_dia, "yyyy-MM-dd"));
      setValue("fecha_fin", format(siguiente_dia, "yyyy-MM-dd"));
      setValue("hora_inicio", "00:00");
      setValue("hora_fin", "00:00");
      setValue("todo_el_dia", value);
      setAllDay(true);
    } else {
      setAllDay(false);
    }
  };

  const compareStartFinishDates = (values) => {
    const { fecha_inicio, fecha_fin, hora_inicio, hora_fin } = values;
    const fecha_inicio_date = new Date(
      fecha_inicio + "T" + hora_inicio + ":00.000"
    );
    const fecha_fin_date = new Date(fecha_fin + "T" + hora_fin + ":00.000");
    if (fecha_inicio_date > fecha_fin_date) {
      const errorDateMessage =
        "La fecha de inicio no puede ser mayor a la fecha final";
      Swal.fire({
        icon: "error",
        title: "",
        text: errorDateMessage,
      });
      setError("fecha_inicio", {
        type: "manual",
        message: errorDateMessage,
      });
      setError("fecha_fin", {
        type: "manual",
        message: errorDateMessage,
      });
      return false;
    } else {
      return true;
    }
  };

  // to define Productor label
  const tipoCita = watch("tipo_cita");
  const tipos_cita_productor = [
    0,
    "0", // EMPTY
    "100000002", // Visita de encuesta de rancho
    "100000006", // Visita a productor (otra)
  ];

  return (
    <BlockUi tag="div" blocking={blocking}>
      <div
        style={{
          top: window.scrollY,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            id="id_tecnico_planeacion"
            type="hidden"
            name="id_tecnico_planeacion"
            label="id_tecnico_planeacion"
            error={errors.id}
            register={register}
          />

          <div className="row">
            <div className="col-md-6">
              <FormSelectApi
                id="tipo_cita"
                name="tipo_cita"
                label="Tipo cita"
                url="tecnicos/catalogos/tipocitas"
                keyArray="tipo_cita"
                labelArray="tipo_cita_desc"
                error={errors.tipo_cita}
                required={true}
                register={register}
                onFinish={() => setLoading(false)}
              />
            </div>
            <div className="col-md-6">
              <FormSelectArray
                id="uuid_proveedor"
                name="uuid_proveedor"
                label={
                  tipos_cita_productor.includes(tipoCita)
                    ? "Productor"
                    : "Asistente"
                }
                keyArray="uuid_dynamics"
                labelArray="nombre_completo"
                error={errors.nivel}
                register={register}
                items={listaProductores}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormInput
                id="asunto"
                type="text"
                name="asunto"
                label="Asunto"
                required={true}
                error={errors.asunto}
                register={register}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormInput
                id="ubicacion"
                type="text"
                name="ubicacion"
                label="Ubicación"
                error={errors.ubicacion}
                register={register}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInput
                id="fecha_inicio"
                type="date"
                name="fecha_inicio"
                label="Fecha Inicio"
                required={true}
                error={errors.fecha_inicio}
                register={register}
                readOnly={allDay}
              />
            </div>

            <div className="col-md-6">
              <FormInput
                id="hora_inicio"
                type="time"
                name="hora_inicio"
                label="Hora Inicio"
                required={true}
                error={errors.hora_inicio}
                register={register}
                readOnly={allDay}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInput
                id="fecha_fin"
                type="date"
                name="fecha_fin"
                label="Fecha Fin"
                required={true}
                error={errors.fecha_fin}
                register={register}
                readOnly={allDay}
              />
            </div>

            <div className="col-md-6">
              <FormInput
                id="hora_fin"
                type="time"
                name="hora_fin"
                label="Hora Fin"
                required={true}
                error={errors.hora_fin}
                register={register}
                readOnly={allDay}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputCheckbox
                id="todo_el_dia"
                type="date"
                name="todo_el_dia"
                label="Todo el día"
                error={errors.todo_el_dia}
                register={register}
                onChecked={(value) => allDayhandler(value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormInputTextArea
                id="descripcion"
                type="date"
                name="descripcion"
                label="Descripción"
                rows="4"
                maxLength="1000"
                error={errors.descripcion}
                register={register}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 offset-md-8">
              <div className="row">
                <div className="col-md-12">
                  <button className="btn btn-primary btn-block" type="submit">
                    {btncancelar ? "Actualizar" : "Guardar"}
                  </button>
                  {btncancelar && (
                    <button
                      className="btn btn-warning btn-block"
                      onClick={cancelarEdit}
                    >
                      Cancelar
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </BlockUi>
  );
};

export default NewEvent;
