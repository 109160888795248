import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Api from "../../../../api/global.service";
import BlockUi from "react-block-ui";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import TablaVacaNotificacionesPasadas from './TablaVacaNotificacionesPasadas';
var dataArray = [];
export default function TablaVacaHistorial(props) {
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [show, setShow] = useState(false);
  const [idVacaEvento, setIdVacaEvento] = useState(0);

  useEffect(() => {

    const obtenerEventos = () => {
      setBlocking(true);
      Api.getAll("vacas/historial_detalle", { id_vaca: props.vaca })
        .then((response) => {
          dataArray = response.data.data;
          setDatosFiltrados(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };
    obtenerEventos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vaca]);


  const obtenerNotificaciones = (id_vaca_evento) => {
    setIdVacaEvento(id_vaca_evento);
    setShow(true);
  };

  const hideFormEdit = () => {
    setShow(false);
  };

  return (
    <BlockUi tag="div" blocking={blocking}>
      <h4 style={{ backgroundColor: "#dadada", padding: 10 }}>
        Historial de actualización
      </h4>
      {datosFiltrados.length > 0 && (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Raza</th>
                <th>Estado Reproductivo</th>
                <th>Tipo vaca</th>
                <th>Último evento</th>
                <th>Creado</th>
                <th>Actualizado</th>
              </tr>
            </thead>

            <tbody>
              {datosFiltrados.map((dato,index) => (
                <tr key={index}>
                  <td>{dato.nombre}</td>
                  <td>{dato.nombre_raza}</td>
                  <td>{dato.nombre_estado_reproductivo}</td>
                  <td>{dato.nombre_tipo_vaca}</td>
                  <td>{dato.id_vaca_evento}</td>
                  <td>{(dato.updated_at === null ) ? dato.created_at : '' }</td>
                  <td>{(dato.updated_at !== null ) ? dato.updated_at : '' }</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {datosFiltrados.length === 0 && (
        <Alert variant={"warning"}>No existen datos por mostrar</Alert>
      )}
    </BlockUi>
  );
}
