
import React,{useState,useEffect} from 'react';
import BlockUi from 'react-block-ui';
import Api from '../../../../api/global.service';
import TablaVacaInfo from './TablaVacaInfo';
import TablaVacaKpis from './TablaVacaKpis';
import TablaVacaEventos from './TablaVacaEventos';
import TablaVacaEventosNotificaciones from './TablaVacaEventosNotificaciones';
import TablaVacaHistorial from './TablaVacaHistorial';



var dataArray = [];
const InfoVacas = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	useEffect(() => {
		const obtenerInfoVaca = ()  => {
			setBlocking(true);
			Api.getAll('vacas/detalle',{id_vaca : props.vaca}).then(response => {
				dataArray = response.data.data; 
				setDatosFiltrados(dataArray);
				setBlocking(false);
			}).catch(e => {
				setDatosFiltrados([]);
				setBlocking(false);
			});
		  }
		obtenerInfoVaca();
	}, [props.vaca]);
		return (
			<BlockUi tag="div" blocking={blocking}>
				<div className="row">
					<div className="col-md-12" style={{borderRight : '2px solid #dadada'}}>
						{datosFiltrados.length > 0 && (
							<>
							<TablaVacaInfo datos={datosFiltrados} />
							<TablaVacaHistorial  vaca={props.vaca} />
							<TablaVacaKpis  vaca={props.vaca} />
							<TablaVacaEventos vaca={props.vaca} />
							<TablaVacaEventosNotificaciones vaca={props.vaca} />
							</>
						)}
					</div>
				</div>
			</BlockUi>
		)
};

export default InfoVacas;