import React, { useEffect, useState } from "react";
import Api from "../../api/global.service";
import ApiUrls from '../../constants/Api';
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";

const Productores = () => {
  const reportId = '171e5e55-5dbe-4d31-a5eb-a59d719e45b8';
  const [embedData, setEmbedData] = useState({
    accessToken: '',
    reportId: '',
    embedUrl: '',
  });

  useEffect(() => {
    Api.getAll(ApiUrls.fira.reporte + '/' + reportId)
      .then((response) => {
        setEmbedData({
          ...embedData, 
          accessToken: response.data.accessToken,
          reportId: response.data.embedUrl[0].reportId,
          embedUrl: response.data.embedUrl[0].embedUrl,
        });
      })
      .catch((e) => {
        console.log('Error' + e);
      });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Panel className="mb-0">
            <PanelHeader>Reporte de productividad por productor de cada técnico</PanelHeader>
            <PanelBody className="mb-0">
              <PowerBIEmbed
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual and qna
                  id: embedData.reportId,
                  embedUrl: embedData.embedUrl,
                  accessToken: embedData.accessToken,
                  tokenType: models.TokenType.Embed,
                  settings: {
                    zoomLevel: 0.7, // 100% zoom
                    panes: {
                      filters: {
                        expanded: false,
                        visible: true,
                      },
                    },
                    background: models.BackgroundType.Transparent,
                  },
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      function () {
                        console.log("Report loaded");
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        console.log("Report rendered");
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log(event.detail);
                      },
                    ],
                  ])
                }
                cssClassName={"powerbi-embedded-report"}
                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                }}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
};

export default Productores;