import React, { useState, useEffect } from "react";
import Api from "../../../../../../../../api/global.service";
import conviertArray from "../../../../../../../../nuup/helpers/conviertArray";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const HerramientasTablasFiltroFechas = (props) => {
  const { quitarSeleccionados } = props;
  const [state, setState] = useState({
    fecha_inicio: "",
    fecha_fin: "",
    items: [],
  });

  const schema = yup.object().shape({
    fecha_inicio: yup
      .string("Introduzca fecha inicio")
      .required("Introduzca fecha inicio"),
    fecha_fin: yup
      .string("Introduzca fecha fin")
      .required("Introduzca fecha fin"),
  });

  const { handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: state,
  });

  useEffect(() => {
    Api.getAll("notificaciones/reporte/agrupado")
      .then((response) => {
        let _items = conviertArray(response.data.data);
        setState({ ...state, items: _items });
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quitarSeleccionados]);

  const onSubmit = (_data) => {
    console.log(_data);
    //setFiltros(5,_ids);
  };

  const handleChange = (key, val) => {
    console.log(key + ":" + val);
    //setState({...state,fecha_inicio : state.fecha_inicio,fecha_fin : state.fecha_fin});
    //console.log(state);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <input
              type="date"
              onChange={() => handleChange("fecha_inicio", this.value)}
            />
          </div>
          <div className="col-md-6">
            <input
              type="date"
              onChange={() => handleChange("fecha_fin", this.value)}
            />
          </div>
        </div>
      </form>
    </>
  );
};
export default HerramientasTablasFiltroFechas;
