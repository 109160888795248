import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../../../../api/global.service.js";
import ApiTecnicosUrls from "../../../../../constants/ApiTecnicos";
import moment from "moment";
import BlockUi from "react-block-ui";
import Swal from "sweetalert2";
import { capFirstLetterInSentence } from "./NewEvent";
import { format, parseISO, parse, isEqual } from 'date-fns'; 
import { setDayDetailObj } from "../actions/actionCreatorsObj";

const DayDetail = (props) => {
  const dispatch = useDispatch();
  const [eventos, setEventos] = useState([]);
  const [listaProductores, setListaProductores] = useState([])

  const [blocking, setBlocking] = useState(false);
  const calendarContext = useSelector((state) => state.calendarState);

  const { detailSidebarToggled, dayDetail, currentMonth, currentYear } =
    calendarContext;

  useEffect(() => {
    obtenerEventos({
      dia: dayDetail.today,
      mes: currentMonth,
      ano: currentYear,
    });
  }, [currentMonth, dayDetail.today, props.refreshData]);

  const obtenerEventos = async (params) => {
    setBlocking(true);
    // catalogo de productores
    const resProductores = await Api.getAll("tecnicos/catalogos/productores");
    const productoresMod = resProductores.data.data.map(item => {
      return {
        ...item,
        nombre_completo: item.id_ugrj + " - " + capFirstLetterInSentence(item.firstname + " " + item.lastname1 + " " + item.lastname2)
      }
    });
    setListaProductores(productoresMod);
    // listado de eventos
    Api.getAll(ApiTecnicosUrls.planeacion.listado, params)
      .then((response) => {
        setEventos(response.data.data);
        setBlocking(false);
      })
      .catch((e) => {
        setEventos([]);
        setBlocking(false);
      });
  };

  const setEliminar = (id_tecnico_planeacion) => {
    Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
        Swal.showLoading()
        setBlocking(true);
          Api.delete('tecnicos/planeacion',id_tecnico_planeacion)
          .then(response => {
            let _result = response.data;
            if(_result.success === 1){
              obtenerEventos({
                dia: dayDetail.today,
                mes: currentMonth,
                ano: currentYear,
              });
              Swal.close();
              Swal.fire(
                'Eliminado!',
                'Evento eliminado.',
                'success'
              );
            }
  
          }).catch(e => {
            setBlocking(false);
            Swal.fire(
            'Error!',
            'Error al eliminar el evento.',
            'error'
            );
          });
			}
		  })
  };



  return (
    <div
      className={
        detailSidebarToggled
          ? "detail-sidebar toggled"
          : "detail-sidebar"
      }
      style={{
        // top: window.scrollY,
        height: 630,
      }}
    >
      <BlockUi tag="div" blocking={blocking} style={{height: '100%'}}>
        <div className="title">
          <div className="row">
            <div className="col-md-12">
  <h2>{`${moment.months(currentMonth - 1)} ${
              dayDetail.today ? dayDetail.today + "," : ""
            } ${currentYear}`}</h2>
            </div>
          </div>
          <div className="row"> 
            <div className="col-md-12"> 
              <a
                onClick={() => [props.eventoEditar({id_tecnico_planeacion : 0}),props.registrarEvento(true)]}
                className="btn btn-dark btn-sm"
                style={{float : 'right',position: 'relative'}}
                title="Agregar/Editar evento"
              >
                <i className="fa fa-plus"></i> Agregar
              </a>
              <a
                onClick={() =>{ 
                    dispatch(setDayDetailObj(null, []));
                    obtenerEventos({
                      dia: null,
                      mes: currentMonth,
                      ano: currentYear,
                    });
                  }
                }
                className="btn btn-dark btn-sm"
                style={{float : 'right',position: 'relative', marginRight: '5px'}}
                title="Ver todos los eventos del mes"
              >
                Ver todos los eventos
              </a>
            </div>
          </div>
        </div>
        <div className="container"  style={{height: '85%', overflowY: 'auto', padding: 0}}>
          <table className="table table-bordered">
            <tr>
              <th>Tipo cita</th>
              <th>Asunto</th>
              <th>Inicio / Fin</th>
              <th>Acciones</th>
            </tr>
            {eventos.length > 0 && eventos.map((evento) => (
              <tr key={evento.id_tecnico_planeacion}>
                <td>
                  {evento.tipo_cita_desc}
                  <figcaption className="blockquote-footer">
                    {evento.uuid_proveedor != 0 && listaProductores.find(p => p.uuid_dynamics == evento.uuid_proveedor).nombre_completo}
                  </figcaption>
                </td>
                <td>{evento.asunto}</td>
                <td>
                  { evento.todo_el_dia == 0 ? (
                    <>
                      { isEqual(parseISO(evento.fecha_inicio), parseISO(evento.fecha_fin)) ? (
                        <>
                          { format(parseISO(evento.fecha_inicio), "dd/MM/yyyy") + " " + 
                            format(parse(evento.hora_inicio, 'HH:mm:ss', new Date), "hh:mm a") }
                          <br />
                          { format(parse(evento.hora_fin, 'HH:mm:ss', new Date), "hh:mm a") }
                        </>
                      ): (
                        <>
                          { format(parseISO(evento.fecha_inicio), "dd/MM/yyyy") + " " + 
                            format(parse(evento.hora_inicio, 'HH:mm:ss', new Date), "hh:mm a") }
                          <br />
                          { format(parseISO(evento.fecha_fin), "dd/MM/yyyy") + " " +  
                            format(parse(evento.hora_fin, 'HH:mm:ss', new Date), "hh:mm a") }
                        </>
                      )}
                    </>
                  ): (
                    <>
                      { format(parseISO(evento.fecha_inicio), "dd/MM/yyyy") }
                      {/*
                      <br>
                      { format(parseISO(evento.fecha_fin), "dd/MM/yyyy") }
                      */}
                      <span className="badge badge-secondary">todo el día</span>
                    </>
                  )}
                </td>
                <td>
                  <a onClick={() => props.eventoEditar(evento)} className="btn btn-xs btn-primary" style={{ marginRight: '4px'}}>
                    <i className="fa fa-edit"></i>
                  </a>
                  <a onClick={() => setEliminar(evento.id_tecnico_planeacion)} className="btn btn-xs btn-danger">
                    <i className="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            ))}
            {eventos.length === 0 && (
              <tr>
                <td colSpan="4">
                  <center>No hay eventos para este día/mes</center>
                </td>
              </tr>
            )}
          </table>
        </div>
      </BlockUi>
    </div>
  );
};

export default DayDetail;
