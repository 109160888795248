import React, { useEffect,useState } from "react";
import Api from '../../../../api/global.service';
import DataTable from 'react-data-table-component';
import  '../../../../nuup/styles/Tablas.css'
import HerramientasTablas from '../../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import getUrlFiltros from '../../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import estiloGeneral from '../../../../nuup/componentes/Tablas/estiloGeneral';
import WhatsappExportar from './WhatsappExportar';
import BlockUi from 'react-block-ui';

var dataArray = [];
const WhatsappDetalleTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const columns = [
		{
			name: 'PRODUCTOR',
			sortable: true,
			right: false,
			cell: row => <div>{row.nombre_proveedor}</div>
		},
		{
			name: 'FECHA ENVÍO',
			selector: 'creado',
			maxWidth: '200px',
			sortable: true,
			right: false
		}
	];


	const obtenerAvisos = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,'avisos/whatsapp/detalle?id_aviso_whatsapp=' + props.id_aviso_whatsapp)).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(response.data.data);
			setBlocking(false);
		  }).catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }

	useEffect(() => {
		if (props.id_aviso_whatsapp > 0) {
			obtenerAvisos(false);
		}
		   // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id_aviso_whatsapp]);



	  const filtrarAvisos = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerAvisos(true);
		}else{
			obtenerAvisos(false);
		}
	};



	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" blocking={blocking}>
			<HerramientasTablas 
				txtBusqueda="Buscar avisos" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["productor","visto_fecha","creado"]}
				exportar={WhatsappExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				funcionFiltrar={filtrarAvisos}
				filtrarRegistros={true}
				filtros={['centro','ruta','productor']}
				filtradoAlert={false}
			/>

		<DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />

		</BlockUi>
    );
  };
export default WhatsappDetalleTabla;
