import React, { useEffect } from "react";
import HerramientasTablasFiltroCentrosTabla from "./HerramientasTablasFiltroCentrosTabla";
import setFiltros from "../../Herramientas/setFiltros";
const HerramientasTablasFiltroCentros = (props) => {
  const { quitarSeleccionados } = props;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeleccionados = (_arr) => {
    let _ids = [];
    for (let a = 0; a < _arr.length; a++) {
      _ids.push(_arr[a].id_centro_colecta);
    }
    setFiltros(1, _ids);
  };

  return (
    <>
      <HerramientasTablasFiltroCentrosTabla
        onChangeSeleccionados={handleSeleccionados}
        quitarSeleccionados={quitarSeleccionados}
      />
    </>
  );
};
export default HerramientasTablasFiltroCentros;
