import React,{useState} from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import WhatsappTabla from './WhatsappTabla.jsx';
import WhatsappDetalleTabla from './WhatsappDetalleTabla.jsx';

const WhatsappListar = () => {
  const [aviso, setAviso] = useState(0);

	const avisoSeleccionado = (aviso) => {
		setAviso(aviso);
	}

    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Listado de whatsApps</li>
      </ol>
      <h1 className="page-header">WhatsApps enviados <small></small></h1>
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>

        <div className="row">
						<div className="col-md-4" style={{borderRight : '2px solid #dadada'}}>
              <WhatsappTabla avisoSeleccionado={avisoSeleccionado} />
						</div>
						<div className="col-md-8" style={{borderRight : '2px solid #dadada'}}>
              <WhatsappDetalleTabla id_aviso_whatsapp={aviso} />
						</div>
					</div>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default WhatsappListar;
