import React, { useEffect, useState } from "react";
import Api from "../../../api/global.service";
import ApiUrls from '../../../constants/Api';
import DataTable from "react-data-table-component";
import estiloGeneral from "../../../nuup/componentes/Tablas/estiloGeneral";
import "../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import HerramientasTablas from "../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas";
import getUrlFiltros from "../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros";
import ListadoTecnicosAccesosExportar from './ListadoTecnicosAccesosExportar';

var dataArray = [];
const ListadoTecnicosAccesos = (props) => {
  const [filtradoAlert, setFiltradoAlert] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [seleccionado, setSeleccionado] = useState(0);

  const columns = [
    {
      name: "Técnico",
      sortable: true,
      selector: "nombre_proveedor"
    },
    {
      name: "Fecha",
      selector: "fecha",
      sortable: true,
      right: false,
    },
    {
      name: "Sistema",
      selector: "sistema",
      sortable: true,
      right: false,
    }
  ];

  useEffect(() => {
    obtenerAccesos();
  }, []);

  const obtenerAccesos = (filtrado = false) => {
    setBlocking(true);
    Api.getAll(getUrlFiltros(filtrado, ApiUrls.tecnicos.accesos))
      .then((response) => {
        dataArray = response.data.data;
        setDatosFiltrados(dataArray);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  const filtrarUsuarios = () => {
    // Validar que existan elementos seleccionados
    if (
      localStorage.getItem("filtro_tipo") > 0 &&
      localStorage.getItem("filtro_valor").length > 0
    ) {
      obtenerAccesos(true);
    } else {
      obtenerAccesos();
    }
  };

  const handleChange = (datosFiltrados) => {
    setDatosFiltrados(datosFiltrados);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "#dadada",
        userSelect: "none",
      },
    },
  ];

  return (
    <BlockUi tag="div" blocking={blocking}>
      <HerramientasTablas
        txtBusqueda="Buscar"
        datosTabla={dataArray}
        urlRegistrar=""
        columnasFiltrar={[
          "nombre_proveedor",
          "fecha",
          "sistema"
        ]}
        exportar={ListadoTecnicosAccesosExportar}
        onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
        funcionFiltrar={filtrarUsuarios}
        filtrarRegistros={false}
        filtros={[]}
        filtradoAlert={filtradoAlert}
      />

      <DataTable
        pagination
        paginationPerPage={15}
        columns={columns}
        data={datosFiltrados}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={estiloGeneral}
        conditionalRowStyles={conditionalRowStyles}
      />
    </BlockUi>
  );
};
export default ListadoTecnicosAccesos;
