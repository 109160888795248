import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import { Link } from "react-router-dom";
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import ContactosExportar from './ContactosExportar';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';

var dataArray = [];
const ContactosTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	
	const columns = [
		{
			name: 'ID',
			selector: 'id_contacto_interes',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Nombre',
			selector: 'nombre',
			sortable: true,
		},


		{
			name: 'Teléfonico',
			selector: 'numero_telefonico',
			sortable: true,
			right: false
		},

		{
			name: 'CREADO',
			selector: 'created_at',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Link className="btn btn-xs btn-warning" to={'contactos/registrar/' + row.id_contacto_interes}><i className="fa fa-edit"></i></Link> <DeleteButton onClick={() => eliminaContacto(row.uuid)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];



	useEffect(() => {
		obtenerContactos();
	}, []);

	  const obtenerContactos = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.contactos)
		  .then(response => {
			setBlocking(false);
			  dataArray = response.data.data; 
			  setDatosFiltrados(dataArray);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaContacto = (uuid) => {		
		console.log(uuid);
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.contactos, uuid)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Contacto eliminado.',
						  'success'
						);
					}
				obtenerContactos();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el contacto.',
					'error'
				  );
				  obtenerContactos();
				});

			}
		  })

	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}
	return (
		<>
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>

		<HerramientasTablas 
				txtBusqueda="Buscar contacto" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/contactos/registrar/0" 
				columnasFiltrar={["id_contacto_interes","nombre","numero_telefonico","created_at"]}
				exportar={ContactosExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		  </BlockUi>
		</>
    );
  };
export default ContactosTabla;
