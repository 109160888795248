import React, { useEffect, useState } from "react";
import Api from "../../../../../api/global.service";
import ApiUrls from '../../../../../constants/Api';
import "../../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

var meses = [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

var dataArray = [];
const MiInventarioInfo = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {

    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.infogeneral.miinventario, { rancho: props.rancho })
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };
  

    if (props.id_productor > 0) {
      obtenerInfo();
    }
  }, [props.id_productor]);




  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
    {datos[0] && 
      datos.map((dato) =>
      <>
      <div className="col-md-3">
        <Card bg='light' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{meses[dato.mes - 1]} {dato.ano}</Card.Title>
          </Card.Body>
          <ListGroup className="list-group-flush">
          <ListGroupItem><strong>Fecha: </strong>{dato.fecha}</ListGroupItem>
          <ListGroupItem><strong>Total: </strong>{dato.total}</ListGroupItem>
            <ListGroupItem><strong>En línea: </strong>{dato.linea}</ListGroupItem>
            <ListGroupItem><strong>Secas: </strong>{dato.secas}</ListGroupItem>
            <ListGroupItem><strong>Becerras: </strong>{dato.becerras}</ListGroupItem>
            <ListGroupItem><strong>Novillas: </strong>{dato.novillas}</ListGroupItem>
            <ListGroupItem><strong>Vaquillas: </strong>{dato.vaquillas}</ListGroupItem>
            <ListGroupItem><strong>Toros: </strong>{dato.toros}</ListGroupItem>
            <ListGroupItem><strong>Promedio leche (En linea): </strong>{dato.promedio_leche_vacas}</ListGroupItem>
            <ListGroupItem><strong>Promedio leche (Hato): </strong>{dato.promedio_leche}</ListGroupItem>
          </ListGroup>
        </Card>
        <br />
        </div>
        </>
      )
    }
    </div>
    </BlockUi>
  );
};
export default MiInventarioInfo;
