import React from "react";
import TecnicoInfo from "./TecnicoInfo";
const Tecnico = (props) => {
  return (
    <>
      {props.id_tecnico > 0 && (
        <>
          <div className="row">
            <div className="col-md-3">
              <TecnicoInfo id_tecnico={props.id_tecnico} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Tecnico;
