import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import FormInputTextArea from '../../../nuup/componentes/Forms/FormInputTextArea.jsx';
import procesarCola from '../../../nuup/helpers/procesarCola';
import WhatsappUsuariosTabla from './WhatsappUsuariosTabla';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';
import Alert from 'react-bootstrap/Alert';

const WhatsappEnviarForm = () => {
	const [centro] = useState(0);
	const [ruta] = useState(0);
	const [blocking, setBlocking] = useState(false);


	const schema = yup.object().shape({
		mensaje : yup.string('Introduzca mensaje').required('Introduzca mensaje')
	});
	const [seleccionados,setSeleccionados] = useState([]);


	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: {
			mensaje : ''
		}
	});



	const onSubmit = data => {
		enviarNotificaciones(data);
	}

	
	const enviarNotificaciones = (data) => {
		if(seleccionados.length > 0){
			setBlocking(true);
			Api.create('avisos/whatsapp',{
				ids : seleccionados,
				mensaje : data.mensaje
			}).then(response => {
				setBlocking(false);
				procesarCola();
				let _result = response.data;
				if(_result.success === 1){
					Swal.close();
					Swal.fire(
						'Enviado!',
						'Notificaciones enviadas.',
						'success'
					);
				}
			}).catch(e => {
				setBlocking(false);
				Swal.fire(
				'Error!',
				'Error al enviar notificaciones.',
				'error'
				);
			});
			
		}else{
			setBlocking(false);
			Swal.fire(
				'Error',
				'Seleccione al menos un registro para enviar',
				'question'
			  )
			
		}
	};


	const handleSeleccionados = (_arr) => {
		console.log('handleSeleccionados');
		console.log(_arr);
		let _ids = [];
		for (let a = 0; a < _arr.length; a++) {
			_ids.push(_arr[a].id);			
		}
        setSeleccionados(_ids);
    }


	return (
		<>
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/administracion/noticias">Enviar notificación (Solo Push)</Link></li>
				<li className="breadcrumb-item active"></li>
			</ol>
			<h1 className="page-header">Enviar Whatsapp <small></small></h1>
			<div className="row">
			<div className="col-md-12">
				<Panel>
					<PanelHeader>Información a enviar</PanelHeader>
					<PanelBody>
					<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>

					<Alert variant="danger">
						Actualmente se está usando un ambiente de pruebas en Twilio, para recibir mensajes es necesario enviar <strong>join constantly-felt</strong> al número <strong>+1 (415) 523-8886</strong> dentro de la app de whatsapp 
					</Alert>

						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12">
								<FormInputTextArea 
									id="mensaje"
									name="mensaje"
									label="Mensaje"
									error={errors.mensaje}
									register={register}
									/>
								</div>
							</div>
							<button className="btn btn-primary btn-block" type="submit">Enviar mensaje</button>
						</form>			
						</BlockUi>
					</PanelBody>
				</Panel>
				<WhatsappUsuariosTabla id_centro_colecta={centro} id_ruta={ruta} onChangeSeleccionados={handleSeleccionados} />			

			</div>
			</div>

			</div>
		</>
	);
};
export default WhatsappEnviarForm;
