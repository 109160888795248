import React,{useEffect,useState} from "react";
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import getUrlFiltros from '../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';

var dataArray = [];

const AsistenteUsuariosInfo = () => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
  let { id_user } = useParams();

	useEffect(() => {
    localStorage.setItem('filtro_tipo', 3);
    localStorage.setItem('filtro_valor', id_user);
		obtenerUsuario(true);
	}, []);

	  const obtenerUsuario = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,'usuarios')).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		}).catch(e => {
			setBlocking(false);
		});
	  }



  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Información del usuarios</Link></li>
        <li className="breadcrumb-item active">Información General</li>
      </ol>
      <h1 className="page-header">Información del usuario <small></small></h1>
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
        <>
        <Link className="btn btn-block btn-info" to={'/'}>Regresar al listado de usuarios</Link>
        <Link className="btn btn-block btn-warning" to={'/asistente/usuarios/vacas/eventos/' + id_user}>Ver Vacas</Link>
        </>
        <hr />
        <BlockUi tag="div" blocking={blocking}>
          {datosFiltrados.map((item, index) =>
          <table key={index} className="table table-striped table-bordered">
            <tbody>
              <tr><td>ID Usuario  :</td><td>{item['id']}</td></tr>
              <tr><td>Acceso  :</td><td>{item['username']}</td></tr>
              <tr><td>ID Margarita  :</td><td>{item['id_margarita']}</td></tr>
              <tr><td>ID UGRJ  :</td><td>{item['id_ugrj']}</td></tr>
              <tr><td>Nombre Proveedor  :</td><td>{item['nombre_proveedor']}</td></tr>
              <tr><td>Tipo  :</td><td>{item['tipo_proveedor']}</td></tr>
              <tr><td>Nivel  :</td><td>{item['nombre_nivel']}</td></tr>
              <tr><td>Centro de colecta  :</td><td>{item['centro']['centro_colecta']}</td></tr>
              <tr><td>Ruta  :</td><td>{item['ruta']['nombre_ruta']}</td></tr>
            </tbody>
          </table>
          )}
          </BlockUi>
        </PanelBody>
      </Panel>
    </div>
  );
  
};
export default AsistenteUsuariosInfo;
