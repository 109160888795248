import React, { useEffect, useState } from "react";
import Api from "../../../../api/global.service";
import ApiUrls from "../../../../constants/Api";
import "../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";

var dataArray = [];
const TecnicoProductoresListado = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.tecnicos.productores, { id_tecnico: props.id_tecnico })
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    if (props.id_tecnico > 0) {
      obtenerInfo();
    }
  }, [props.id_tecnico]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
        <table className="table">
          <thead>
            <tr>
              <th>uuid_dynamics:</th>
              <th>Acceso:</th>
              <th>Nombre:</th>
              <th>Apellido Paterno:</th>
              <th>Apellido Materno:</th>
              <th>ID Margarita:</th>
              <th>ID UGRJ:</th>
              <th>Rancho:</th>
            </tr>
          </thead>
          <tbody>
            {datos[0] &&
              datos.map((dato) => (
                  <tr key={dato.id}>
                    <td>{dato.uuid_dynamics}</td>
                    <td>{dato.username}</td>
                    <td>{dato.firstname}</td>
                    <td>{dato.lastname1}</td>
                    <td>{dato.lastname2}</td>
                    <td>{dato.id_margarita}</td>
                    <td>{dato.id_ugrj}</td>
                    <td>{dato.rancho}</td>
                  </tr>
              ))}
          </tbody>
        </table>
      </div>
    </BlockUi>
  );
};
export default TecnicoProductoresListado;
