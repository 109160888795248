import React from 'react';
import LoginV3 from './../pages/login-v3.js';
import AsistenteUsuariosListar from './../pages/usuarios/Asistente/AsistenteUsuariosListar';
import AsistenteUsuariosInfo from './../pages/usuarios/Asistente/AsistenteUsuariosInfo';
import AsistenteVacasEventos from './../pages/vacas/Asistente/AsistenteVacasEventos';


const routesAsistente = [
  {
    path: '/login',
    exact: true,
    title: 'Acceder',
    component: () => <LoginV3 />
  },
  {
    path: '/',
    exact: true,
    title: 'Usuarios Registrados',
    component: () => <AsistenteUsuariosListar />
  },

  {
    path: '/asistente/usuarios/info/:id_user',
    exact: true,
    title: 'Información del usuario',
    component: () => <AsistenteUsuariosInfo />
  },
  {
    path: '/asistente/usuarios/vacas/eventos/:id_user',
    exact: true,
    title: 'Vacas - Eventos',
    component: () => <AsistenteVacasEventos />
  },
  
];

export default routesAsistente;