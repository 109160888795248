import xlsx from "json-as-xlsx";
import moment from 'moment';
const NoticiasExportarProductor = (content) => {
 
   let columns = [
        { label: 'ID Noticia', value: 'id_aviso' },
        { label: 'Categoría', value: 'categoria' },
        { label: 'Título', value: 'titulo' },
        { label: 'Noticia', value: 'aviso' },
        { label: 'Productor', value: 'nombre_proveedor' },
        { label: 'Visto', value: 'visto' },
        { label: 'Fecha visto', value: 'visto_fecha' },
        { label: 'Fecha registro', value: 'creado' }
      ];
      
      let settings = {
        sheetName: 'Noticias',
        fileName: 'Noticias_por_productor' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default NoticiasExportarProductor;
