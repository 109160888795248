import React from "react";
import TecnicoProductoresListado from "./TecnicoProductoresListado";
const TecnicoProductores = (props) => {
  return (
    <>
      {props.id_tecnico > 0 && (
          <>
            <TecnicoProductoresListado id_tecnico={props.id_tecnico} />
          </>
      )}
    </>
  );
};
export default TecnicoProductores;
