import React from "react";

const DynamicsLiquidacionesListado = (props) => {


  const setLiquidacion = (liquidacion) => {
    props.liquidacionSeleccionada(liquidacion);
  };

  const validaLiquidacion = (liquidacion) => {
    let className = "table-success";
    if(liquidacion.Amount === null || liquidacion.Amount === 0){
      className = "table-danger";
    }
    return className;
  };

  return (
    <div className="row">
		{props.liquidaciones.length > 0 && (
    <div className="col-md-12">
    <table className="table table-bordered">
            <thead>
              <tr>
				        <th>Folio</th>
                <th>ID UGRJ</th>
                <th>Productor</th>
                <th>Fecha</th>
                <th>Fecha doucmento</th>
                <th>Tipo</th>
                <th>Estatus</th>
                <th>Total</th>
                <th>Total con impuestos</th>
                <th>Status sincronización</th>
              </tr>
            </thead>
            <tbody>
              {props.liquidaciones.map((liquidacion) => (
              <tr className={validaLiquidacion(liquidacion)} key={liquidacion.No}>
                <td>{liquidacion.No}</td>
                <td>{liquidacion.Buy_from_Vendor_No}</td>
                <td>{liquidacion.Buy_from_Vendor_Name}</td>
                <td>{liquidacion.Posting_Date}</td>
                <td>{liquidacion.Document_Date}</td>
                <td>{liquidacion.Document_Type}</td>
                <td>{liquidacion.Status}</td>
                <td>{liquidacion.Amount}</td>
                <td>{liquidacion.Amount_Including_VAT}</td>
                <td>
                    <button className="btn btn-dark btn-sm" onClick={() => setLiquidacion(liquidacion)}><i className="fa fa-arrow-right"></i></button>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

  );
  };
export default DynamicsLiquidacionesListado;
