import React, { useEffect,useState } from "react";
import Api from '../../../../api/global.service';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../../nuup/componentes/Generales/DeleteButton';
import  '../../../../nuup/styles/Tablas.css'
import ModalPdf from '../../../../nuup/componentes/Generales/ModalPdf';
import HerramientasTablas from '../../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import getUrlFiltros from '../../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import estiloGeneral from '../../../../nuup/componentes/Tablas/estiloGeneral';
import AvisosReportesExportar from './AvisosReportesExportar';
import BlockUi from 'react-block-ui';

var dataArray = [];
const ReportesDetalleTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [verpdf, setVerpdf] = useState(false);
	const [urlpdf, setUrlpdf] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const columns = [
		{
			name: 'PRODUCTOR',
			sortable: true,
			right: false,
			cell: row => <div>{row.productor}</div>
		},
		{
			name: 'FECHA ENVÍO',
			selector: 'creado',
			maxWidth: '200px',
			sortable: true,
			right: false
		},

		{
			name: 'VISTO',
			maxWidth: '50px',
			sortable: true,
			right: false,
			cell: row => <div>{(row.visto === '1') ? 'SI' : 'NO'}</div>
		},
		{
			name: 'FECHA VISTO',
			selector: 'visto_fecha',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'ARCHIVO',
			maxWidth: '50px',
			sortable: true,
			right: false,
			cell: row => <div><button className="btn btn-xs btn-info" onClick={() => abrirPdf(row)}><i className="fa fa-file"></i></button></div>
		},

		{
			name: 'OPCIONES',
			button: true,
			maxWidth: '150px',
			cell: (row) => <><DeleteButton onClick={() => deleteAviso(row.uuid)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];

	useEffect(() => {
		console.log('ReportesDetalleTabla:useEffect');
		if (typeof props.aviso.id_aviso !== 'undefined') {
			obtenerAvisos(false);
		}
		   // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.aviso]);


	  const obtenerAvisos = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,'notificaciones/reporte?id_aviso=' + props.aviso.id_aviso + '&titulo=' + props.aviso.titulo + '&aviso=' + props.aviso.aviso + '&creado=' + props.aviso.creado)).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(response.data.data);
			setBlocking(false);
		  }).catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }

	  const filtrarAvisos = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerAvisos(true);
		}else{
			obtenerAvisos(false);
		}
		   // eslint-disable-next-line react-hooks/exhaustive-deps
	};



	const deleteAviso = (uuid) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete('notificaciones/texto/eliminar',uuid)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Usuario eliminado.',
						  'success'
						);
					}
				obtenerAvisos(false);
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el usuario.',
					'error'
				  );
				  obtenerAvisos(false);
				});

			}
		  })

	};

	
	const onCerrarPdf = () => {
		setVerpdf(false);
	}

	
	const abrirPdf = (row) => {
		let _row = JSON.parse(row.data);
		let url = process.env.REACT_APP_API_URL + '/api/' + _row.url;

		setUrlpdf(url);
		setVerpdf(true);
	
	}

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" blocking={blocking}>
			<HerramientasTablas 
				txtBusqueda="Buscar avisos" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["productor","visto_fecha","creado"]}
				exportar={AvisosReportesExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				funcionFiltrar={filtrarAvisos}
				filtrarRegistros={true}
				filtros={['tipodocumento','centro','ruta','productor']}
				filtradoAlert={false}
			/>

		<DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />

			<ModalPdf show={verpdf} url={urlpdf} titulo="Archivo enviado" onCerrarPdf={onCerrarPdf} />
		</BlockUi>
    );
  };
export default ReportesDetalleTabla;
