import React, { useEffect,useState } from "react";
import Api from '../../../../../../../../api/global.service';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../../../../../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../../../../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';

var dataArray = [];
const HerramientasTablasFiltroNivelesTabla = (props) => {
	
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [toggleCleared] = useState(false);

	const columns = [
		{
			name: 'ID',
			selector: 'id_nivel',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'NIVEL',
			selector: 'nombre_nivel',
			sortable: true,
			right: false
		}
	];



	useEffect(() => {
		obtenerRutas();
	}, [props.quitarSeleccionados]);

	  const obtenerRutas = ()  => {
		setBlocking(true);
		Api.getAll('catalogos/usuarios/niveles')
		  .then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);

		  });
	  }

	const handleRowSelected = React.useCallback(state => {
		props.onChangeSeleccionados(state.selectedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<BlockUi tag="div" blocking={blocking}>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={''}
			selectableRows
			onSelectedRowsChange={handleRowSelected}
			clearSelectedRows={toggleCleared}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default HerramientasTablasFiltroNivelesTabla;
