import React from 'react';

const FormSelectAno = ({register,error,label,id,...inputProps}) => {
    const newDate = new Date();
    return <>
    	<div className="form-group">
            <label htmlFor={id}>{label}</label>
            <select 
                {...register(id)}
                id={id}
                className={`form-control ${error ? "is-invalid" : ""}`}
                {...inputProps}
            >
                <option value="0">--- SELECCIONE ---</option>
                <option value={newDate.getFullYear()}>{newDate.getFullYear()}</option>

			</select>
            {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
    </>
};
export default FormSelectAno;