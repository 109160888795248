//const version = 'v1';
export default {
    Url : process.env.REACT_APP_API_URL,
    user : {
        login : 'login',
        info : 'user/info',
        perfil : 'user/perfil',
    },
    catalogs : {
        formularios : {
            categoriasvideos : 'catalogos/videos/categorias',
            proveedores : 'catalogos/tecnicos/proveedores',
        },
        fertilizantes : {
            general : 'administracion/catalogos/fertilizantes',
            nutrientes : 'administracion/catalogos/fertilizantes_nutriente',
            precios : 'administracion/catalogos/fertilizantes_precios',
        },
    },
    videos : 'videos',
    tecnicos : {
        info : 'tecnicos/show',
        listado : 'tecnicos',
        accesos : 'tecnicos/accesos',
        productores : 'tecnicos/misproductores',
        proveedor : {
            asociar : 'tecnicos/asociar/proveedor',
            desasociar : 'tecnicos/desasociar/proveedor'
        },
        equipo : 'tecnicos/equipo'
    },
    contactos : 'contactosinteres',
    noticias : {
        detalles : 'noticias/detalle',
        texto : 'noticias/texto',
        archivos : 'noticias/archivos',
        archivosdetalle : 'noticias/archivosdetalles'
    },
    infogeneral : {
        miperfil : {
            usuario : 'usuarios',
            rancho : 'ranchos/ranchoProductor'
        },
        misvacas : 'vacas/productor',
        miinventario : 'inventarios/inventarioRancho',
        misbeneficios : {
            mitecnico : 'tecnicos/tecnicoProductor',
            misasistencias : 'tecnicos/asistenciasProductor'
        },
        minegocio : {
            mileche : 'leche/entregada/reporte',
            misingresos : 'leche/misingresos/reporte',
            misgastos : 'costos/reporte',
            micalidad : 'leche/micalidad/reporte'
        }
    },
    vacas : {
        listado : 'vacas/reporte',
        historial : 'vacas/historial',
        eventos : {
            seguimiento : {
                totales : 'vacas/eventos/notificaciones/seguimiento',
                detalles : 'vacas/eventos/notificaciones/seguimiento/detalles',
            }
        },
        kpis : 'vacas/historial/kpis'
    },
    hatos : {  
        kpis : 'vacas/historial/hatos/kpis'
    },
    ranchos : {
        historial : 'ranchos/historial/inventarios',
    },
    fira : {
        reporte: 'fira/reporte'
    }
};