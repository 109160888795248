import xlsx from "json-as-xlsx";
import moment from 'moment';

const ListadoTecnicosAccesosExportar = (content) => {
    let columns = [
        { label: 'Técnico', value: 'nombre_proveedor' },
        { label: 'Fecha', value: 'fecha' },
        { label: 'Sistema', value: 'sistema' }
      ];
      
      let settings = {
        sheetName: 'Tecnicos_accesos',
        fileName: 'Tecnicos_acc_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default ListadoTecnicosAccesosExportar;
