import xlsx from "json-as-xlsx";
import moment from 'moment';
const ContactosExportar = (content) => {
    //{ label: 'Age', value: row => (row.age + ' years') }, // Run functions
    //{ label: 'Phone', value: row => (row.more ? row.more.phone || '' : '') }, // Deep props
    let columns = [
        { label: 'ID Contacto', value: 'id_contacto_interes' },
        { label: 'Nombre', value: 'nombre' },
        { label: 'Teléfonico', value: 'numero_telefonico' },
        { label: 'Fecha registro', value: 'created_at' }
      ]
      
      let settings = {
        sheetName: 'Contactos',
        fileName: 'Contactos_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default ContactosExportar;
