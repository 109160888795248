import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import ListadoTecnicos from './componentes/ListadoTecnicos';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tecnico from './componentes/Tecnico/Tecnico';
import TecnicoProductores from './componentes/TecnicoProductores/TecnicoProductores';
const Tecnicos = () => {
	const [tecnico, setTecnico] = useState(0);
	const [uuidTecnico, setUuidTecnico] = useState(0);

	const tecnicoSeleccionado = (id_tecnico) => {
		setTecnico(id_tecnico);
	}

	const tecnicoUuidSeleccionado = (uuid_tecnico) => {
		setUuidTecnico(uuid_tecnico);
	}

		return (
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item"><Link to="/">Técnicos</Link></li>
					<li className="breadcrumb-item active">Listado de técnicos</li>
				</ol>
				<h1 className="page-header">Técnicos</h1>
				<Panel>
					<PanelHeader></PanelHeader>
					<PanelBody>
					<div className="row">
						<div className="col-md-4" style={{borderRight : '2px solid #dadada'}}>
							<ListadoTecnicos tecnicoUuidSeleccionado={tecnicoUuidSeleccionado} tecnicoSeleccionado={tecnicoSeleccionado}  personalizado={0} />
						</div>

						<div className="col-md-8" style={{borderRight : '2px solid #dadada'}}>
						{tecnico > 0 && 
						<Tabs defaultActiveKey="info
						" id="uncontrolled-tab-example" className="mb-3">
							<Tab eventKey="info
							" title="Información">
								<Tecnico id_tecnico={tecnico} />
							</Tab>
							<Tab eventKey="productores" title="Productores asociados">
								<TecnicoProductores uuid_tecnico={uuidTecnico} id_tecnico={tecnico} />
							</Tab>
							</Tabs>
							}
						</div>
					</div>
					</PanelBody>
				</Panel>
			</div>
		)
};

export default Tecnicos;