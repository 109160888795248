import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Api from "../../../../api/global.service";
import BlockUi from "react-block-ui";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import TablaVacaNotificacionesPasadas from './TablaVacaNotificacionesPasadas';
var dataArray = [];
export default function TablaVacaEventos(props) {
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [show, setShow] = useState(false);
  const [idVacaEvento, setIdVacaEvento] = useState(0);

  useEffect(() => {

    const obtenerEventos = () => {
      setBlocking(true);
      Api.getAll("vacas/detalle/eventos", { id_vaca: props.vaca })
        .then((response) => {
          dataArray = response.data.data;
          setDatosFiltrados(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };
    obtenerEventos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vaca]);


  const obtenerNotificaciones = (id_vaca_evento) => {
    setIdVacaEvento(id_vaca_evento);
    setShow(true);
  };

  const hideFormEdit = () => {
    setShow(false);
  };

  return (
    <BlockUi tag="div" blocking={blocking}>
      <h4 style={{ backgroundColor: "#dadada", padding: 10 }}>
        Últimos eventos
      </h4>
      {datosFiltrados.length > 0 && (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Fecha evento</th>
                <th>Evento</th>
                <th>Tipo</th>
                <th>Fecha registro</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {datosFiltrados.map((dato,index) => (
                <tr key={index}>
                  <td>{dato.id_vaca_evento}</td>
                  <td>{dato.fecha_evento}</td>
                  <td>{dato.nombre_tipo_evento}</td>
                  <td>{dato.nombre_tipo_evento_categoria}</td>
                  <td>{dato.created_at}</td>
                  <th>
                    {index > 0 &&
                    <button
                      onClick={() => obtenerNotificaciones(dato.id_vaca_evento)}
                      className="btn btn-info btn-xs"
                    >
                      <i className="fa fa-info" />
                      </button>
                    }
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal
            show={show}
            onHide={() => hideFormEdit()}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Notificaciones del evento
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TablaVacaNotificacionesPasadas vaca={props.vaca} id_vaca_evento={idVacaEvento}  />
            </Modal.Body>
          </Modal>
        </>
      )}
      {datosFiltrados.length === 0 && (
        <Alert variant={"warning"}>No existen datos por mostrar</Alert>
      )}
    </BlockUi>
  );
}
