import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../../api/global.service';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../../nuup/componentes/Forms/FormInput.jsx';
import FormInputFile from '../../../../nuup/componentes/Forms/FormInputFile.jsx';
import FormSelectApi from '../../../../nuup/componentes/Forms/FormSelectApi.jsx';
import Swal from "sweetalert2";
import formatDate from '../../../../nuup/helpers/formatDate.jsx';
import formatDateMoment from '../../../../nuup/helpers/formatDateMoment.jsx';

const AvisosArchivosSubirForm = () => {
	const newDate = new Date();
	const schema = yup.object().shape({
		id_avisos_tipos_categoria : yup.number('Seleccione tipo de documento').min(1,'Seleccione tipo de documento').required('Seleccione tipo de documento'),
		file: yup.mixed().required('Requerido')
        .test('fileFormat', 'Solo se permiten archivos .zip', (value) => {
			return value[0] && ['application/x-zip-compressed','application/zip'].includes(value[0].type);
		}),
		fecha_inicio : yup.date('Seleccione fecha de inicio').required('Seleccione fecha de inicio'),
		fecha_fin : yup.date('Seleccione fecha fin').required('Seleccione fecha fin')
	});

	const [file,setFile] = useState();
	const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: {
			id_aviso_archivo : 0,
			id_avisos_tipos_categoria : 0,
			fecha_inicio : formatDate(newDate),
			fecha_fin : formatDate(newDate),
			numero_registros : 0
		}
	});


	const creaArchivo = values => {
		Swal.showLoading();
		const formData = new FormData();
		var _values = Object.keys(values).map((key) => [key, values[key]]);
		_values.forEach(function (item) {
			if(item[0] !== 'file'){
				if(item[0] === 'fecha_inicio'){
					formData.append(item[0], Date.parse(item[1]));
				}

				if(item[0] === 'fecha_fin'){
					formData.append(item[0], Date.parse(item[1]));
				}

				formData.append(item[0], item[1]);
			}
		});

		formData.append('file',file)
		Api.createMultiPart('notificaciones/archivos/subir',formData).then(response => {
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: 'Archivo creado correctamente',
				  })
				  window.location.reload(); 
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}


	const onSubmit = values => {
		creaArchivo(values);
	}

    const onChange = (e) => {
		console.log('onChange');
		setFile(e.target.files[0]);
	  }
  
	  const handleChange = () => {
		setValue('aviso' ,formatDateMoment(getValues('fecha_inicio')) + ' - ' + formatDateMoment(getValues('fecha_fin')));
	}

	


	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-6">
					<FormInput 
						id="id_aviso_archivo"
						type="hidden"
						name="id"
						label="ID"
						error={errors.id}
						register={register}
						/>
						</div>
					</div>


				<div className="row">
					<div className="col-md-6">

					<FormSelectApi 
							id="id_avisos_tipos_categoria"
							name="id_avisos_tipos_categoria"
							label="Tipo de documento"
							url="notificaciones/archivos/tipos"
							keyArray="id_avisos_tipos_categoria"
							labelArray="aviso_categoria"
							error={errors.id_avisos_tipos_categoria}
							register={register}
						/>


					</div>
					<div className="col-md-6">
						<FormInputFile 
							id="file"
							name="file"
							label="Archivo Zip"
							keyArray="file"
							labelArray="tipo_documento"
							error={errors.file}
							register={register}
							onChange={onChange}
						/>

				</div>
				</div>
				<hr />

				<div className="row">
					<div className="col-md-6">
					<FormInput 
						id="fecha_inicio"
						type="date"
						name="fecha_inicio"
						label="Fecha Inicial"
						error={errors.fecha_inicio}
						register={register}
						onChange={handleChange}
						/>
					</div>
					<div className="col-md-6">
					<FormInput 
						id="fecha_fin"
						type="date"
						name="fecha_fin"
						label="Fecha Final"
						error={errors.fecha_fin}
						register={register}
						onChange={handleChange}
						/>
					</div>
				</div>


				<button className="btn btn-primary btn-block" type="submit">Guardar</button>
			</form>
		</>
	);
};
export default AvisosArchivosSubirForm;
