import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import Api from "../../../api/global.service";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../../../nuup/componentes/Forms/FormInput.jsx";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import MenuMeses from "./componentes/MenuMeses";

import DynamicsEncuestasAsistenciasListado from "./componentes/DynamicsEncuestasAsistenciasListado";


const DynamicsEncuestasAsistencias = () => {
	const [show, setShow] = useState(false);

	const schema = yup.object().shape({
		valor: yup.string("Seleccione un valor"),
		tipo: yup.string("Seleccione un tipo"),
	  });
	
	  const [recarga, setRecarga] = useState(0);
	  const [btncancelar, setBtncancelar] = useState(false);
	  const [encuestas, setEncuestas] = useState([]);
	  const [encuesta, setEncuesta] = useState({});

	  const [state] = useState({
		valor: "",
		tipo: 1,
	  });
	
	  const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	  } = useForm({
		resolver: yupResolver(schema),
		mode: "onBlur",
		defaultValues: state,
	  });

	  const getData = function(mes, anio) {
		Swal.showLoading();
		Api.getAll("dynamics365/buscar/encuesta/asistencias", {
			mes: mes,
			anio: anio,
		}).then((response) => {
			let _result = response.data;
			if (_result.success === 1) {
			  setEncuestas(_result.data);
			  setShow(true);
			  Swal.close();
			} else {
			  alert(_result.message);
			}
		  }).catch((e) => {
			Swal.fire("Error!", "Error al buscar información del usuario.", "error");
		  });
	  }

	  const hideFormEdit = () => {
		setShow(false);
	  };

	  const setEncuestaSeleccionada = (encuesta) => {
		setShow(false);
		setEncuesta(encuesta);
		console.log(encuesta);
	  };

	  


  return (
    <>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Encuestas Mensuales</Link>
        </li>
        <li className="breadcrumb-item active">Asistencia Técnica</li>
      </ol>
      <h1 className="page-header">
	  Asistencia Técnica <small>Encuestas Mensuales</small>
      </h1>
      <Panel>
        <PanelHeader>Seleccione un mes</PanelHeader>
        <PanelBody>
			<>
			<MenuMeses getData={(mes,anio) => getData(mes,anio)} />
		  </>
		  <DynamicsEncuestasAsistenciasListado encuestaSeleccionada={(encuesta) => setEncuestaSeleccionada(encuesta)} encuestas={encuestas} />
        </PanelBody>
      </Panel>
    </>
  );
};

export default DynamicsEncuestasAsistencias;
