import React from 'react';

const FormInputFile = ({register,error,label,id,onChange,...inputProps}) => {
    return <>
    	<div className="form-group">
            <label htmlFor={id}>{label}</label>
            <input
                {...register(id)}
                id={id}
                type="file"
                className={`form-control ${error ? "is-invalid" : ""}`}
                onChange={onChange}
                {...inputProps}
            />
            {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
    </>
};
export default FormInputFile;