export default {
    Url : process.env.REACT_APP_API_URL,
    planeacion : {
        listado : 'tecnicos/planeacion',
        agregar : 'tecnicos/planeacion',
        actualizar : 'tecnicos/planeacion',
        eliminar : 'tecnicos/planeacion'
    },
    reportes : {
        edorep : {
            vacas : 'tecnicos/reportes/edorep/vacas',
            totales : 'tecnicos/reportes/edorep/totales'
        },
        tecnicos: 'reportes/tecnicos'
    },
    accesos : 'auth/user/access'
};