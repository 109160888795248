import React, { useEffect, useState } from "react";
import Api from "../../../../../api/global.service";
import ApiUrls from '../../../../../constants/Api';
import "../../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';


var dataArray = [];
const MiPerfilRancho = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {

    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.infogeneral.miperfil.rancho, { id_productor: props.id_productor })
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };
  

    if (props.id_productor > 0) {
      obtenerInfo();
    }
  }, [props.id_productor]);



  return (
    <BlockUi tag="div" blocking={blocking}>

    {datos[0] && 
        <Card bg='light' style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>Mi rancho</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroupItem><strong>Localidad: </strong>{datos[0].nombre_localidad}</ListGroupItem>
          <ListGroupItem><strong>Municipio: </strong>{datos[0].nombre_municipio}</ListGroupItem>
          <ListGroupItem><strong>Código postal: </strong>{datos[0].cp}</ListGroupItem>
          <ListGroupItem><strong>Rancho compartido con: </strong>{datos[0].compartido_con}</ListGroupItem>
          <ListGroupItem><strong>Tanque de entrega: </strong>{datos[0].tanque}</ListGroupItem>
        </ListGroup>
      </Card>
    }
    </BlockUi>
  );
};
export default MiPerfilRancho;
