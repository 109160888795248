import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Api from "../../../../api/global.service";
import BlockUi from "react-block-ui";
import Alert from "react-bootstrap/Alert";

var dataArray = [];
export default function TablaVacaKpis(props) {
  const [blocking, setBlocking] = useState(false);
  const [kpisActual, setKpisActual] = useState([]);
  const [kpisHistorial, setKpisHistorial] = useState([]);

  useEffect(() => {

    const obtenerKpis = () => {
      setBlocking(true);
      Api.getAll("vacas/detalle/kpis", { id_vaca: props.vaca })
        .then((response) => {
          dataArray = response.data.data;
          setKpisActual(dataArray.actual);
          setKpisHistorial(dataArray.historial);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };
    obtenerKpis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vaca]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <h4 style={{ backgroundColor: "#dadada", padding: 10 }}>
        KPIs
      </h4>
      <>
      <p><b>Actual</b></p>
        <Table striped bordered hover responsive>
          <tbody>
            <tr>
              <th>Días abiertos</th>
              <th>Días leche</th>
              <th>Intervalo entre partos</th>
            </tr>
            <tr>
              <td>{kpisActual.diasAbiertos}</td>
              <td>{kpisActual.diasLeche}</td>
              <td>{kpisActual.intervaloPartos}</td>

            </tr>
          </tbody>
        </Table>

        {kpisHistorial.length > 0 && (
          <>
          <p><b>Historial</b></p>
          <Table striped bordered hover responsive>
            <thead>
            <tr>
            <th>Fecha</th>
            <th>Días abiertos</th>
            <th>Días leche</th>
            <th>Intervalo entre partos</th>
            </tr>
            </thead>
            <tbody>
          {kpisHistorial.map((dato,index) => (

            <tr key={index}>
            <td>{dato.fecha}</td>
            <td>{dato.dias_abiertos}</td>
            <td>{dato.dias_leche}</td>
            <td>{dato.intervalo_partos}</td>
            </tr>
          ))}
            </tbody>


          </Table>
          </>
        )}

      </>
      {kpisHistorial.length === 0 && (
        <Alert variant={"warning"}>No existen datos por mostrar</Alert>
      )}
    </BlockUi>
  );
}
