import React, { useEffect,useState } from "react";
import Api from '../../../../api/global.service';
import ApiUrls from '../../../../constants/Api';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import getUrlFiltros from '../../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import ListadoKpisHatosExportar from './ListadoKpisHatosExportar';

var dataArray = [];
const ListadoKpisHatos = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [filtradoAlert, setFiltradoAlert] = useState(false);

	const columns = [
		{
			name: 'Id Historial  KPI',
			selector: 'id_historial_hatos_kpi',
			sortable: true,
			right: false
		},

		{
			name: 'Proveedor',
			selector: 'nombre_proveedor',
			sortable: true,
			right: false
		},
		{
			name: 'ID UGRJ',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.id_ugrj}</div></div>
		},
		{
			name: 'Id Rancho',
			selector: 'id_rancho',
			sortable: true,
			right: false
		},
		{
			name: 'Fecha de cálculo',
			selector: 'fecha',
			sortable: true,
			right: false
		},
		{
			name: 'Días abiertos',
			selector: 'dias_abiertos',
			sortable: true,
			right: false
		},
		{
			name: 'Días leche',
			selector: 'dias_leche',
			sortable: true,
			right: false
		}
	];

	useEffect(() => {
		obtenerSeguimiento();
	}, []);

	  const obtenerSeguimiento = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,ApiUrls.hatos.kpis)).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		}).catch(e => {
			setBlocking(false);
		});
	  }

	const filtrarUsuarios = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerSeguimiento(true);
		}else{
			obtenerSeguimiento();
		}
	};


	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	const conditionalRowStyles = [
		{
		  when: row => row.toggleSelected,
		  style: {
			backgroundColor: "#dadada",
			userSelect: "none"
		  }
		}
	  ];

	return (
		<BlockUi tag="div" blocking={blocking}>

			<HerramientasTablas 
				txtBusqueda="Buscar" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["nombre_proveedor","id_ugrj"]}
				exportar={ListadoKpisHatosExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				funcionFiltrar={filtrarUsuarios}
				filtrarRegistros={false}
				filtros={['productor']}
				filtradoAlert={filtradoAlert}
			/>

          <DataTable
            pagination
			paginationPerPage={15}
            columns={columns}
            data={datosFiltrados}
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
			conditionalRowStyles={conditionalRowStyles}
          />

		</BlockUi>
    );
  };
export default ListadoKpisHatos;
