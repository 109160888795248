import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Api from "../../../../api/global.service";
import Alert from "react-bootstrap/Alert";
var dataArray = [];
export default function TablaVacaNotificacionesPasadas(props) {
  const [datosFiltrados, setDatosFiltrados] = useState([]);

  useEffect(() => {
    const obtenerNotificaciones = () => {
      Api.getAll("vacas/detalle/eventos/notificaciones/pasadas", {
        id_vaca: props.vaca,
        id_vaca_evento: props.id_vaca_evento,
      })
        .then((response) => {
          dataArray = response.data.data;
          setDatosFiltrados(dataArray);
        })
        .catch((e) => {});
    };

    obtenerNotificaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_vaca_evento]);

  const respuestaAtendida = (completada, completada_fecha) => {
    if (completada === null && completada_fecha === null) {
      return "-";
    } else {
      let resp = "RESPUESTA:";
      resp += completada === 1 ? "SI" : "NO";
      return resp + " | " + completada_fecha;
    }
  };

  return (
    <>
      {datosFiltrados.length > 0 && (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>ID Evento</th>
              <th>Fecha notificación</th>
              <th>Notificación</th>
              <th>Pregunta</th>
              <th>Pregunta Si</th>
              <th>Atendida</th>
            </tr>
          </thead>
          <tbody>
            {datosFiltrados.map((dato, index) => (
              <tr key={index}>
                <td>{dato.id_vaca_evento_notificacion}</td>
                <td>{dato.id_vaca_evento}</td>
                <td>{dato.fecha_notificacion}</td>
                <td>{dato.notificacion}</td>
                <td>{dato.pregunta}</td>
                <td>{dato.pregunta_si}</td>
                <td>
                  {respuestaAtendida(dato.completada, dato.completada_fecha)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {datosFiltrados.length === 0 && (
        <Alert variant={"warning"}>No existen datos por mostrar</Alert>
      )}
    </>
  );
}
