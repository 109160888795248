import React, { useEffect,useState } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../components/panel/panel.jsx";
import Api from '../../../../api/global.service';
import Swal from "sweetalert2";

const DynamicsEncuestasInfoMysql = (props) => {
  const [data, setData] = useState(false);

  useEffect(() => {
    obtenerInfo({encuesta_uuid : props.encuesta_uuid});
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.encuesta_uuid]);
  
  const obtenerInfo = (params)  => {
    Swal.showLoading();	
    Api.getAll('dynamics365/buscar/encuesta/proceso',params)
      .then(response => {
      Swal.close();
      setData(response.data.data);
      })
      .catch(e => {
      Swal.close();
      console.log(e);
      });
    }


  return (
    <Panel>
      <PanelHeader>Información proceso sincronización</PanelHeader>
      <PanelBody>
        <div className="row">
            <div className="col-md-12">
            {data && (
              <>
              <div style={{backgroundColor : '#d4d4d4',padding : 10,fontSize : 18,fontWeight : 'bold'}} > Tablas temporales </div>
              <h5 style={{color : data.encuestas_mensuales !== null ? 'green' : 'red'}}>365_encuestas_mensuales</h5>

              {data.encuestas_mensuales !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>ID Margarita / ID UGRJ / Rancho</th>
                  <th>Encuesta_uuid (Dynamics)</th>
                  <th>Folio</th>
                  <th>Fecha</th>
                  <th>Fecha primera_ejecucion</th>
                  <th>Fecha ultima_ejecucion</th>
                  <th>Fecha creación Dynamics</th>
                  <th>Fecha creación MySQL</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.encuestas_mensuales.encuesta_uuid + "_1"}>
                <td>{data.encuestas_mensuales.id_encuesta_mensual}</td>
                <td>{data.encuestas_mensuales.productor_id_margarita + '|' +  data.encuestas_mensuales.productor_id_ugrj + '|' +  data.encuestas_mensuales.productor_rancho  } </td>             
                <td>{data.encuestas_mensuales.encuesta_uuid}</td>
                <td>{data.encuestas_mensuales.encuesta_folio}</td>
                <td>{data.encuestas_mensuales.encuesta_fecha}</td>
                <td>{data.encuestas_mensuales.fecha_primera_ejecucion}</td>
                <td>{data.encuestas_mensuales.fecha_ultima_ejecucion}</td>
                <td>{data.encuestas_mensuales.createdon_dynamics}</td>
                <td>{data.encuestas_mensuales.created_at}</td>
                </tr>
              </tbody>
            </table>
            )}
              <hr />

              <h5 style={{color : data.encuestas_mensuales_encuestas !== null ? 'green' : 'red'}}>365_encuestas_mensuales_encuestas</h5>

          {data.encuestas_mensuales_encuestas !== null && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>encuesta_egreso_total_mes</th>
                  <th>encuesta_costo_litro_producido</th>
                  <th>encuesta_costo_litro_alimentacion</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.encuestas_mensuales_encuestas.encuesta_uuid + "_2"}>
                <td>{data.encuestas_mensuales_encuestas.id_encuesta_mensual_encuesta}</td>
                <td>{data.encuestas_mensuales_encuestas.encuesta_egreso_total_mes}</td>
                <td>{data.encuestas_mensuales_encuestas.encuesta_costo_litro_producido}</td>
                <td>{data.encuestas_mensuales_encuestas.encuesta_costo_litro_alimentacion}</td>
                <td>{data.encuestas_mensuales_encuestas.created_at}</td>
                </tr>
              </tbody>
            </table>
              )}

              <hr />

              <h5 style={{color : data.encuestas_mensuales_egresos !== null ? 'green' : 'red'}}>365_encuestas_mensuales_egresos</h5>

              {data.encuestas_mensuales_egresos !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Folio</th>
                  <th>Egreso diferente a alimentación</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.encuestas_mensuales_egresos.encuesta_uuid + "_3"}>
                <td>{data.encuestas_mensuales_egresos.id_encuesta_mensual_egreso}</td>
                <td>{data.encuestas_mensuales_egresos.egreso_folio}</td>
                <td>{data.encuestas_mensuales_egresos.egreso_diferente_alimentacion}</td>
                <td>{data.encuestas_mensuales_egresos.created_at}</td>
                </tr>
              </tbody>
            </table>

            )}

              <hr />

              <h5 style={{color : data.encuestas_mensuales_dietas !== null ? 'green' : 'red'}}>365_encuestas_mensuales_dietas</h5>


              {data.encuestas_mensuales_dietas !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Folio</th>
                  <th>Costo alimentación</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.encuestas_mensuales_dietas.encuesta_uuid + "_4"}>
                <td>{data.encuestas_mensuales_dietas.id_encuesta_mensual_dieta}</td>
                <td>{data.encuestas_mensuales_dietas.dieta_folio}</td>
                <td>{data.encuestas_mensuales_dietas.dieta_costo_alimentacion}</td>
                <td>{data.encuestas_mensuales_dietas.created_at}</td>
                </tr>
              </tbody>
            </table>

            )}

              <hr />

              <h5 style={{color : data.encuestas_mensuales_produccion !== null ? 'green' : 'red'}}>365_encuestas_mensuales_produccion</h5>
              {data.encuestas_mensuales_produccion !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Folio</th>
                  <th>Total</th>
                  <th>Linea</th>
                  <th>Altas</th>
                  <th>Medias</th>
                  <th>Bajas</th>
                  <th>Secas</th>
                  <th>Becerras</th>
                  <th>Novillas</th>
                  <th>Vaquillas</th>
                  <th>Terneros</th>
                  <th>Toros</th>
                  <th>Promedio leche (Hato)</th>
                  <th>Promedio leche (Linea)</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.encuestas_mensuales_produccion.encuesta_uuid + "_5"}>
                <td>{data.encuestas_mensuales_produccion.id_encuesta_mensual_produccion}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_folio}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_total}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_linea}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_altas}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_medias}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_bajas}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_secas}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_becerras}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_novillas}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_vaquillas}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_terneros}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_toros}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_promedio_leche}</td>
                <td>{data.encuestas_mensuales_produccion.produccion_promedio_leche_vacas}</td>
                <td>{data.encuestas_mensuales_produccion.created_at}</td>
                </tr>
              </tbody>
            </table>

            )}

              <hr />

              <h5 style={{color : data.encuestas_mensuales_bitacoras !== null ? 'green' : 'red'}}>365_encuestas_mensuales_bitacoras</h5>
              {data.encuestas_mensuales_bitacoras !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Folio</th>
                  <th>Área de actividades</th>
                  <th>Administración</th>
                  <th>Administración (Otro)</th>
                  <th>Calidad</th>
                  <th>Calidad (Otro)</th>
                  <th>Nutrición</th>
                  <th>Nutrición (Otro)</th>
                  <th>Salud Animal</th>
                  <th>Salud Animal (Otro)</th>
                  <th>Reproducción</th>
                  <th>Reproducción (Otro)</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.encuestas_mensuales_bitacoras.encuesta_uuid + "_6"}>
                <td>{data.encuestas_mensuales_bitacoras.id_encuesta_mensual_bitacora}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_folio}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_areadeactividades}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_administracion}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_administracionotro}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_calidad}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_calidadotro}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_nutricion}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_nutricionotro}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_saludanimal}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_saludanimalotro}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_reproduccion}</td>
                <td>{data.encuestas_mensuales_bitacoras.bitacora_reproduccionotro}</td>
                <td>{data.encuestas_mensuales_bitacoras.created_at}</td>
                </tr>
              </tbody>
            </table>

            )}

              <hr />


            <div className="col-md-12">
              <div style={{backgroundColor : '#d4d4d4',padding : 10,fontSize : 18,fontWeight : 'bold'}} > Tablas Producción </div>
            </div>


            <h5 style={{color : data.inventarios !== null ? 'green' : 'red'}}>inventarios</h5>
              {data.inventarios !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UUID</th>
                  <th>ID Margarita / ID UGRJ / Rancho</th>
                  <th>Fecha (Año / Mes))</th>
                  <th>Total</th>
                  <th>Linea</th>
                  <th>Secas</th>
                  <th>Becerras</th>
                  <th>Novillas</th>
                  <th>Vaquillas</th>
                  <th>Terneros</th>
                  <th>Toros</th>
                  <th>Promedio Leche (Hato)</th>
                  <th>Promedio Leche (Linea)</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.inventarios.uuid + "_7"}>
                <td>{data.inventarios.id_inventario}</td>
                <td>{data.inventarios.uuid}</td>
                <td>{data.inventarios.id_margarita + ' / ' + data.inventarios.id_ugrj + ' / ' + data.inventarios.rancho}</td>
                <td>{data.inventarios.fecha + '(' + data.inventarios.ano + ' / ' + data.inventarios.mes  + ')' }</td>
                <td>{data.inventarios.total}</td>
                <td>{data.inventarios.linea}</td>
                <td>{data.inventarios.secas}</td>
                <td>{data.inventarios.becerras}</td>
                <td>{data.inventarios.novillas}</td>
                <td>{data.inventarios.vaquillas}</td>
                <td>{data.inventarios.terneros}</td>
                <td>{data.inventarios.toros}</td>
                <td>{data.inventarios.promedio_leche}</td>
                <td>{data.inventarios.promedio_leche_vacas}</td>
                <td>{data.inventarios.created_at}</td>
                </tr>
              </tbody>
            </table>
            )}

             <hr />

             <h5 style={{color : data.costos_mensuales !== null ? 'green' : 'red'}}>costos_mensuales</h5>
              {data.costos_mensuales !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UUID</th>
                  <th>ID Margarita / ID UGRJ / Rancho</th>
                  <th>Fecha (Año / Mes))</th>
                  <th>costo_alimentacion</th>
                  <th>costo_litro_alimentacion</th>
                  <th>costo_otros</th>
                  <th>costo_litro_otros</th>
                  <th>costo_produccion</th>
                  <th>costo_litro_produccion</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.costos_mensuales.uuid + "_8"}>
                <td>{data.costos_mensuales.id_costo_mensual}</td>
                <td>{data.costos_mensuales.uuid}</td>
                <td>{data.costos_mensuales.id_margarita + ' / ' + data.costos_mensuales.id_ugrj + ' / ' + data.costos_mensuales.rancho}</td>
                <td>{data.costos_mensuales.fecha + '(' + data.costos_mensuales.ano + ' / ' + data.costos_mensuales.mes  + ')' }</td>
                <td>{data.costos_mensuales.costo_alimentacion}</td>
                <td>{data.costos_mensuales.costo_litro_alimentacion}</td>
                <td>{data.costos_mensuales.costo_otros}</td>
                <td>{data.costos_mensuales.costo_litro_otros}</td>
                <td>{data.costos_mensuales.costo_produccion}</td>
                <td>{data.costos_mensuales.costo_litro_produccion}</td>
                <td>{data.costos_mensuales.created_at}</td>
                </tr>
              </tbody>
            </table>
            )}

             <hr />

             <h5 style={{color : data.asistencias !== null ? 'green' : 'red'}}>asistencias</h5>
              {data.asistencias !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UUID</th>
                  <th>ID Margarita / ID UGRJ / Rancho</th>
                  <th>Fecha</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.asistencias.uuid + "_9"}>
                <td>{data.asistencias.id_asistencia}</td>
                <td>{data.asistencias.uuid}</td>
                <td>{data.asistencias.id_margarita + ' / ' + data.asistencias.id_ugrj + ' / ' + data.asistencias.rancho}</td>
                <td>{data.asistencias.fecha}</td>
                <td>{data.asistencias.created_at}</td>
                </tr>
              </tbody>
            </table>
            )}

             <hr />

            </>

            )}
          </div>
        </div>
      </PanelBody>
    </Panel>
  );
};
export default DynamicsEncuestasInfoMysql;
