import xlsx from "json-as-xlsx";
import moment from 'moment';

const AvisosReportesExportar = (content) => {
    let columns = [
        { label: 'id_aviso', value: 'id_aviso' },
        { label: 'uuid', value: 'uuid' },
        { label: 'tipo', value: 'tipo' },
        { label: 'categoria', value: 'categoria' },
        { label: 'titulo', value: 'titulo' },
        { label: 'aviso', value: 'aviso' },
        { label: 'archivo', value: 'archivo' },
        { label: 'id_user', value: 'id_user' },
        { label: 'visto', value: 'visto' },
        { label: 'visto_fecha', value: 'visto_fecha' },
        { label: 'creado', value: 'creado' },
        { label: 'deleted_at', value: 'deleted_at' },
        { label: 'productor', value: 'productor' },
      ];
      
      let settings = {
        sheetName: 'Avisos',
        fileName: 'avisos_reportes' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default AvisosReportesExportar;
