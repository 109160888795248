import React from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import ListadoSeguimientos from './componentes/ListadoSeguimientos';

const VacasSeguimientoTotales = () => {
		return (
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item"><Link to="/">Vacas Seguimiento Totales</Link></li>
					<li className="breadcrumb-item active">Active Page</li>
				</ol>
				<h1 className="page-header">Vacas Seguimiento Totales</h1>
				<Panel>
					<PanelHeader></PanelHeader>
					<PanelBody>
					<div className="row">
						<div className="col-md-12" style={{borderRight : '2px solid #dadada'}}>
							<ListadoSeguimientos />
						</div>
					</div>
					</PanelBody>
				</Panel>
			</div>
		)
};

export default VacasSeguimientoTotales;