import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import { Link } from "react-router-dom";
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import UsuariosExportar from './UsuariosExportar';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import getUrlFiltros from '../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import Button from 'react-bootstrap/Button';
import TablaDatosDispositivo from '../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Dispositivos/TablaDatosDispositivo';

var dataArray = [];
const UsuariosTabla = (props) => {
	const [show, setShow] = useState(false);
	const [filtradoAlert, setFiltradoAlert] = useState(false);
	const [datosDispositivo, setDatosDispositivo] = useState({});
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [state, setState] = useState({
	id_user : 0,
	usuarios: [],
	currentUsuario: null,
	currentIndex: -1
	});

	const modalDispositivo = (device) => {
		device = device.replace(/\\n/g, "\\n")  
		.replace(/\\'/g, "\\'")
		.replace(/\\"/g, '\\"')
		.replace(/\\&/g, "\\&")
		.replace(/\\r/g, "\\r")
		.replace(/\\t/g, "\\t")
		.replace(/\\b/g, "\\b")
		.replace(/\\f/g, "\\f");
		let deviceParse = JSON.parse("[" + device + "]");
		setDatosDispositivo(JSON.parse(deviceParse));
		setShow(true);
	}

	const conditionalRowStyles= [
		{
		  when: row => row.statecode == 1,
		  style: {
			backgroundColor: 'rgba(155, 155, 155, 1)',
			color: 'white',
			'&:hover': {
			  cursor: 'pointer',
			},
		  },
		},
	  ];


	const columns = [
		{
			name: 'ID',
			selector: 'id',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'ROL',
			sortable: true,
			maxWidth: '150px',
			cell: row => <div><div style={{ fontWeight: 700 }}>{(typeof row.roles[0] !== 'undefined') ? row.roles[0].name : 'SIN ROL'}</div></div>
		},

		{
			name: 'TIPO PROVEEDOR',
			selector: 'tipo_proveedor',
			maxWidth: '150px',
			sortable: true,
			right: false
		},

		{
			name: 'PROVEEDOR / CONTACTO',
			sortable: true,
			maxWidth: '300px',
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.nombre_proveedor}</div>{row.firstname + ' ' + row.lastname1 + ' ' + row.lastname2}</div>
		},
		{
			name: 'ID UGRJ/ID MARGARITA',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.id_ugrj}</div>{row.id_margarita}</div>
		},

		{
			name: 'RANCHO',
			selector: 'rancho',
			sortable: true,
			right: false
		},

		{
			name: 'EMAIL/EMAIL NOTIFICACIÓN',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.email}</div>{row.email_notification}</div>
		},

		{
			name: 'USUARIO',
			selector: 'username',
			sortable: true,
			right: false
		},


		{
			name: 'RUTA/CENTRO',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.ruta.nombre_ruta}</div>{row.centro.centro_colecta}</div>
		},
		{
			name: 'NIVEL',
			selector: 'nombre_nivel',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'INFORMACIÓN DISPOSITIVO',
			sortable: true,
			cell: row => <div>{(row.device) ? <Button onClick={() => modalDispositivo(row.device)} variant="primary" size="xs"><i className="fa fa-tablet" /></Button> : '' }</div>
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Link className="btn btn-xs btn-warning" to={'usuarios/actualizar/' + row.uuid_proveedor + '/' + row.uuid_contacto  + '/' + row.uuid_user}><i className="fa fa-edit"></i></Link> <DeleteButton onClick={() => deleteUser(row.uuid)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];

	useEffect(() => {
		setFiltradoAlert(false);
		obtenerUsuarios();
	}, []);

	  const obtenerUsuarios = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,'usuarios')).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		}).catch(e => {
			setBlocking(false);
		});
	  }


	const deleteUser = (uuid) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete('usuarios',uuid)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Usuario eliminado.',
						  'success'
						);
					}
				obtenerUsuarios();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el usuario.',
					'error'
				  );
				  obtenerUsuarios();
				});

			}
		  })

	};
	

	const filtrarUsuarios = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerUsuarios(true);
		}else{
			obtenerUsuarios();
		}
	};

	const hideFormEdit = () => {
		setState({...state,id_user : 0});
		setShow(false);
	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" blocking={blocking}>
	
			<HerramientasTablas 
				txtBusqueda="Buscar usuario" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/usuarios/registrar" 
				columnasFiltrar={["email","nombre_proveedor","tipo_proveedor","username","email_notification","id_margarita","id_ugrj","created_at"]}
				exportar={UsuariosExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				funcionFiltrar={filtrarUsuarios}
				filtrarRegistros={true}
				filtros={['statecode','centro','ruta','productor','nivel']}
				filtradoAlert={filtradoAlert}
			/>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
			conditionalRowStyles={conditionalRowStyles}
          />

			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Información del dispositivo
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
			  <TablaDatosDispositivo datos={datosDispositivo} />
              </Modal.Body>
            </Modal>
		</BlockUi>
    );
  };
export default UsuariosTabla;
