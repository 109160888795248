import React from "react";
import Table from "react-bootstrap/Table";
export default function TablaVacaInfo(props) {
  const { datos } = props;
  return (
    <>
		<h4 style={{ backgroundColor : '#dadada',padding : 10}}>Información general</h4>
      	<Table striped bordered hover responsive>
				<tbody>
					<tr>
						<th>ID</th>
						<td>{datos[0].id_vaca}</td>
						<th>ID SINIIGA</th>
						<td>{datos[0].id_siniiga}</td>
					</tr>

					<tr>
						<th>Nombre</th>
						<td>{datos[0].nombre}</td>
						<th>Hato</th>
						<td>{datos[0].designName}</td>
					</tr>

					<tr>
						<th>Tipo</th>
						<td>{datos[0].nombre_tipo_vaca}</td>

						<th>Raza</th>
						<td>{datos[0].nombre_raza}</td>
					</tr>

					<tr>

						<th>Estado reproductivo</th>
						<td colSpan={3}>{datos[0].nombre_estado_reproductivo}</td>
					</tr>

						<tr>
							<th>{datos[0].tipo_fecha_nombre}</th>
							<td>{ (datos[0].tipo_fecha === "1") ? datos[0].fecha_nacimiento : datos[0].fecha_ingreso }</td>
							<th>Fecha registro</th>
							<td>{datos[0].created_at}</td>
						</tr>
						<tr>
							<th>Última inseminación (Registro inicial)</th>
							<td>{datos[0].vacas_fecha_ultima_inseminacion}</td>
							<th>Último parto (Registro inicial)</th>
							<td>{datos[0].vacas_fecha_ultimo_parto}</td>
						</tr>

				</tbody>
				</Table>

				<h4 style={{ backgroundColor : '#dadada',padding : 10}}>Últimas fechas</h4>
				<Table striped bordered hover responsive>
					<tbody>
						<tr>
							<th>Último parto</th>
							<th>Último celo</th>
							<th>Último diagnóstico de preñez</th>
							<th>Última inseminación</th>
							<th>Último monto</th>
							<th>Último secar</th>
						</tr>
						<tr>
							<td>{datos[0].fecha_ultimo_parto}</td>
							<td>{datos[0].fecha_ultimo_celo}</td>
							<td>{datos[0].fecha_ultimo_diagnostico_prenez}</td>
							<td>{datos[0].fecha_ultima_inseminacion}</td>
							<td>{datos[0].fecha_ultimo_monto}</td>
							<td>{datos[0].fecha_ultimo_secar}</td>
						</tr>
					</tbody>
				</Table>
			</>
  );
}