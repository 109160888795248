import React from "react";

const DynamicsEncuestasCostosListado = (props) => {


  const setEncuesta = (encuesta) => {
    props.encuestaSeleccionada(encuesta);
  };

  const validaCostos = (encuesta,tipo) => {
    let className = "";
    let text = "";


   // Falta información en la encuesta
   if(encuesta.tch_costoporlitroalimentacion === null || encuesta.tch_costoporlitroproducido === null || encuesta.tch_egresototalmes === null){
    text += "Encuesta: ";
    if(encuesta.tch_costoporlitroalimentacion === null){
      text += "tch_costoporlitroalimentacion, ";
    }

    if(encuesta.tch_costoporlitroproducido === null){
      text += "tch_costoporlitroproducido, ";
    }

    if(encuesta.tch_egresototalmes === null){
      text += "tch_egresototalmes";
    }
    className = "btn-danger";
  }

  // Falta información en egreso
  if(encuesta.tch_idEgreso){
    if(encuesta.tch_idEgreso.tch_egresodiferentealimentacionmes === null){
      text += ",Egreso: tch_egresodiferentealimentacionmes";
    className = "btn-danger";
  }
}

  // Falta información en dieta
  if(encuesta.tch_idDietaTotal){
    if(encuesta.tch_idDietaTotal.tch_costoalimentaciondia === null){
      text += "tch_egresototalmes";
    }
  }


  if(encuesta.mysql){
    className = "btn-success";
  }

    if(tipo === 'className'){
      return className;
    }else{
      return text;
    }

  };

  return (
    <div className="row">
		{props.encuestas.length > 0 && (
    <div className="col-md-12">
      <hr />
    <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
				        <th>Folio Encuesta</th>
                <th>Folios (Egresos / Dietas)</th>
                <th>ID Margarita</th>
                <th>ID UGRJ</th>
                <th>Rancho</th>
                <th>Fecha</th>
                <th>Costo litro alimentación</th>
                <th>Costo litro producido</th>
                <th>Egreso total mes</th>
                <th>Costo alimentación por día</th>
                <th>Egreso diferente a alimentación</th>
              </tr>
            </thead>

            <tbody>
              {props.encuestas.map((encuesta,index) => (
              <tr className={validaCostos(encuesta,'className')} key={encuesta.tch_visitamensualid}>
                <td>{index + 1}</td>
                <td>
                  <a className="btn" target="_blank" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_visitamensual&id=' + encuesta.tch_visitamensualid}>{encuesta.tch_foliodevisitamensual}</a>
                </td>
                <td>
                  <a className="btn" target="_blank" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_egresovm&id=' + encuesta.tch_idEgreso.tch_egresovmid}>{encuesta.tch_idEgreso.tch_folioegreso}</a>
                  <a className="btn" target="_blank" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_dietatotal&id=' + encuesta.tch_idDietaTotal.tch_dietatotalid}>{encuesta.tch_idDietaTotal.tch_foliodietatotal}</a>
                </td>

                <td>{encuesta.tch_idProductor.tch_idproductor}</td>
                <td>{encuesta.tch_idProductor.tch_idproveedor}</td>
                <td>{(!encuesta.tch_idRancho) ? '' : encuesta.tch_idRancho.msdyn_name }</td>
                <td>{encuesta.tch_fechavisita}</td>
                <td className={(encuesta.tch_costoporlitroalimentacion === null) ? 'bg-danger' : ''}>{encuesta.tch_costoporlitroalimentacion}</td>
                  <td className={(encuesta.tch_costoporlitroproducido === null) ? 'bg-danger' : ''}>{encuesta.tch_costoporlitroproducido}</td>
                  <td className={(encuesta.tch_egresototalmes === null) ? 'bg-danger' : ''}>{encuesta.tch_egresototalmes}</td>
                  <td className={(encuesta.tch_idDietaTotal.tch_costoalimentaciondia === null) ? 'bg-danger' : ''}>{encuesta.tch_idDietaTotal.tch_costoalimentaciondia}</td>
                  <td className={(encuesta.tch_idEgreso.tch_egresodiferentealimentacionmes === null) ? 'bg-danger' : ''}>{encuesta.tch_idEgreso.tch_egresodiferentealimentacionmes}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

  );
  };
export default DynamicsEncuestasCostosListado;
