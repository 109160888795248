import React,{useState} from 'react';
import { Link } from "react-router-dom";
import filterDataTables from '../../../helpers/filterDataTables';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './HerramientasTablas.css'
import PantallaFiltros from './Modulos/Filtrado/PantallaFiltros';
import Alert from 'react-bootstrap/Alert';
import resetFiltros from './Modulos/Filtrado/Herramientas/resetFiltros';
import descripcionFiltros from './Modulos/Filtrado/Herramientas/descripcionFiltros';

export default function HerramientasTablas(props) {
  const { txtBusqueda ,datosTabla,urlRegistrar,columnasFiltrar,exportar,onFiltrar,filtrarRegistros = false,filtros = ['centro','ruta','productor'],filtradoAlert = false } = props;
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    onFiltrar(filterDataTables(datosTabla, e.target.value, columnasFiltrar));		  
  }

  const exportarRegistros = () => {
    exportar(datosTabla);
  }

  const filtrar = (reset = false) => {
    if(reset){
      console.log('reset');
      setShowAlert(false);
      //filtradoAlert = false;
      resetFiltros();
      props.funcionFiltrar();
    }else{
      console.log('Filtrar');
      setShowAlert(true);
      //filtradoAlert = true;
      props.funcionFiltrar();
      handleClose();
    }
  }

  return (
    <>
        <div className="row margin_bottom">
                <div className="col-md-4">
                    {(txtBusqueda) ? <input type="text" className="form-control" placeholder={txtBusqueda} onChange={handleChange}/> : ''}
                </div>
                <div className="col-md-4">

                </div>
                <div className="col-md-4">
                    <div className="float-right">
                        {urlRegistrar.length > 0 && (
                        <Link className="btn btn-secondary margin_right" to={urlRegistrar}><i className="fa fa-plus" /> Agregar</Link>
                        )}

                        {urlRegistrar.length === 0 && props.funcionRegistrar && (
                        <button className="btn btn-secondary margin_right" onClick={() => props.funcionRegistrar()}><i className="fa fa-plus" /> Agregar</button>
                        )}

                      {exportar !== false && (
                        <button className="btn btn-primary margin_right" onClick={() => exportarRegistros()} ><i className="fa fa-download" /> </button>
                       )}

                       {filtrarRegistros &&  (
                          <Button variant="primary" onClick={handleShow}>
                          <i className="fa fa-filter" />
                        </Button>
                        )}
                    </div>
                </div>

            </div>

            <div className="row">
          <div className="col-md-12">
            <Alert variant="warning" show={showAlert || filtradoAlert} onClose={() => filtrar(true)} dismissible>
              <Alert.Heading>Filtro aplicado!</Alert.Heading>
              <p>
                Los registros se han filtrado por <b>{ descripcionFiltros() } </b>.
              </p>
            </Alert>
          </div>
        </div>

            {filtrarRegistros && (
            <Modal 
            show={show}
            onHide={handleClose}
            size="lg"
            >
            <Modal.Header closeButton>
              <Modal.Title>Seleccione el tipo de filtro</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PantallaFiltros
                filtros={filtros}
                />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button variant="primary" onClick={() => filtrar(false)}>
                Filtrar
              </Button>
            </Modal.Footer>
          </Modal>
        )}
          </>
  );
}