import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setDayDetailObj, addEventDate, toggleDetailSidebarObj, toggleEventsSidebarObj, toggleNewEventSidebarObj } from "../actions/actionCreatorsObj";

const Day = ({ day: { visible, dayOfMonth, date } }) => {

  const calendarContext = useSelector(state => state.calendarState);
  const dispatch = useDispatch();

  const {
    events,
    dayDetail
  } = calendarContext;

  let todaysEvents = [];
  events.forEach(event => {
    if (date === event.date) {
      todaysEvents.push(event);
    }
  });

  const d = new Date();
  const today = d.getDate();
  let classes = "day";

  // style for today
  if (dayOfMonth !== dayDetail.today && dayOfMonth === today) classes = "day is-today";
  
  // style for selected day (default is today)
  const currentDay = dayDetail.today!== null ? dayDetail.today : today;
  if (currentDay === dayOfMonth) classes = "day current-day";
  
  if (!visible) classes = "day hidden";

  return (
    <button
      className={classes}
      onClick={() => {
        dispatch(setDayDetailObj(dayOfMonth, todaysEvents))
        dispatch(toggleDetailSidebarObj(true));
        dispatch(toggleEventsSidebarObj(false));
        dispatch(toggleNewEventSidebarObj(false));
        dispatch(addEventDate(dayOfMonth))
      }}
    >
      {dayOfMonth}
      <div>
        {todaysEvents.map((el, index) => (
          <span key={index} el={el}>
            {" "}
            <i className={`bt btn-info`}></i>
          </span>
        ))}
      </div>
    </button>
  );
};

export default Day;
