import React from "react";
import Home from "./../pages/tecnicos/Home";
import LoginV3 from "./../pages/login-v3.js";
import UsuariosInformacionGeneral from "./../pages/usuarios/InformacionGeneral/UsuariosInformacionGeneral";
import VacasEventos from "./../pages/vacas/eventos/VacasEventos";
import TecnicosPleneacion from "./../pages/tecnicos/planeacion/TecnicosPleneacion";
import TecnicosEstadoReproductivo from "./../pages/tecnicos/reportes/edorep/TecnicosEstadoReproductivo";
import TecnicosATAvanzado from "./../pages/tecnicos/reportes/TecnicosATAvanzado";
import AdopcionApp from "./../pages/tecnicos/reportes/AdopcionApp";

import { Provider } from "react-redux";
import store from "../pages/tecnicos/planeacion/calendario/store";

const routes = [
  {
    path: "/",
    exact: true,
    title: "Home",
    component: () => <Home />,
  },
  {
    path: "/login",
    exact: true,
    title: "Acceder",
    component: () => <LoginV3 />,
  },
  {
    path: "/tecnicos/productores/info",
    exact: true,
    title: "Vacas / Eventos",
    component: () => <UsuariosInformacionGeneral tecnico />,
  },
  {
    path: "/tecnicos/vacas/eventos",
    exact: true,
    title: "Vacas / Eventos",
    component: () => <VacasEventos tecnico />,
  },
  {
    path: "/tecnicos/planeacion",
    exact: true,
    title: "Vacas / Eventos",
    component: () => (
      <>
        <Provider store={store}>
          <TecnicosPleneacion />
        </Provider>
      </>
    ),
  },
  {
    path: "/tecnicos/reporte_at",
    exact: true,
    title: "Reporte avanzado",
    component: () => <TecnicosATAvanzado />,
  },
  {
    path: "/tecnicos/reporte_app",
    exact: true,
    title: "Reporte adopción",
    component: () => <AdopcionApp />,
  },
  {
    path: "/tecnicos/reporte",
    exact: true,
    title: "Reporte Estado reproductivo",
    component: () => <TecnicosEstadoReproductivo />,
  },
];

export default routes;
