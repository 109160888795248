import React, { useEffect, useState } from "react";
import Api from "../../../api/global.service";
import ApiTecnicos from "../../../constants/ApiTecnicos";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import TecnicosArchivosSubirForm from "./TecnicosArchivosSubirForm";
import Button from "../../../nuup/componentes/Generales/Button";
import DeleteButton from "../../../nuup/componentes/Generales/DeleteButton";
import "../../../nuup/styles/Tablas.css";
import estiloGeneral from "../../../nuup/componentes/Tablas/estiloGeneral";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import ModalPdf from "../../../nuup/componentes/Generales/ModalPdf";
import { Link } from "react-router-dom";
import {
  Export,
  downloadCSV,
} from "../../../nuup/componentes/Tablas/exportarCsv";
var dataArray = [];

const TecnicosArchivosTabla = (props) => {
  const [show, setShow] = useState(false);
  const [verpdf, setVerpdf] = useState(false);
  const [urlpdf, setUrlpdf] = useState(false);
  const [state, setState] = useState({
    id_user: 0,
    archivos: [],
    currentUsuario: null,
    currentIndex: -1,
  });

  const columns = [
    {
      name: "ID",
      selector: "id_tecnico_reporte",
      maxWidth: "150px",
      sortable: true,
      right: false,
    },
    {
      name: "NOMBRE DEL TÉCNICO",
      maxWidth: "250px",
      sortable: true,
      cell: (row) => (
        <div>
          {row.nombre} {row.apellido_paterno} {row.apellido_materno}
        </div>
      ),
    },
    {
      name: "MES",
      selector: "mes",
      maxWidth: "150px",
      sortable: true,
      cell: (row) => (
        <div>
          {format(new Date(row.ano, row.mes - 1, 1), "MMMM", { locale: es })}
        </div>
      ),
      right: false,
    },
    {
      name: "AÑO",
      selector: "ano",
      maxWidth: "150px",
      sortable: true,
      right: false,
    },
    {
      name: "OPCIONES",
      button: true,
      cell: (row) => <div>{row.archivo.length > 0 ? opcionesButtons(row) : <></>}</div>,
    },
  ];

  useEffect(() => {
    obtenerArchivos();
  }, []);

  const obtenerArchivos = () => {
    Swal.showLoading();
    Api.getAll(ApiTecnicos.reportes.tecnicos)
      .then((response) => {
        Swal.close();
        dataArray = response.data.data;
        setState({
          archivos: response.data.data,
        });
      })
      .catch((e) => {
        Swal.close();
        console.log(e);
      });
  };

  const abrirPdf = (archivo) => {
    let url = process.env.REACT_APP_API_URL + "/api/" + archivo;
    setUrlpdf(url);
    setVerpdf(true);
  };

  const onCerrarPdf = () => {
    setVerpdf(false);
  };

  const deleteArchivo = (id_tecnico_reporte) => {
    Swal.fire({
      title: "¿Esta seguro?",
      text: "El registro se eliminará",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete(ApiTecnicos.reportes.tecnicos, id_tecnico_reporte)
          .then((response) => {
            let _result = response.data;
            if (_result.success === 1) {
              Swal.close();
              Swal.fire("Eliminado!", "Reporte eliminado.", "success");
            }
            obtenerArchivos();
          })
          .catch((e) => {
            Swal.fire("Error!", "Error al eliminar el reporte.", "error");
            obtenerArchivos();
          });
      }
    });
  };

  const hideFormEdit = () => {
    setState({ ...state, id_user: 0 });
    setShow(false);
  };

  const opcionesButtons = (row) => (
    <>
      <a className="btn btn-xs btn-info mr-1" onClick={() => abrirPdf(row.archivo)}>
        <i className="fa fa-file"></i>
      </a>
      <DeleteButton onClick={() => deleteArchivo(row.id_tecnico_reporte)}>
        <i className="fa fa-trash"></i>
      </DeleteButton>
    </>
  );

  const actionsMemo = React.useMemo(
    () => (
      <div className="mb-2">
        <Button className="btn btn-secondary" onClick={() => setShow(true)}>
          Subir Archivo
        </Button>{" "}
        {/* <Export onExport={() => downloadCSV(dataArray, "avisos_reporte")} /> */}
      </div>
    ),
    []
  );

  return (
    <div>
      <DataTable
        pagination
        columns={columns}
        data={state.archivos}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={estiloGeneral}
        actions={actionsMemo}
      />
      {/* Upload file modal */}
      <Modal
        show={show}
        onHide={() => hideFormEdit()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Subir Archivo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TecnicosArchivosSubirForm id_user={state.id_user} />
        </Modal.Body>
      </Modal>
      {/* PDF modal */}
      <ModalPdf
        show={verpdf}
        url={urlpdf}
        titulo="Archivo guardado"
        onCerrarPdf={onCerrarPdf}
      />
    </div>
  );
};
export default TecnicosArchivosTabla;
