import React from "react";

const FormSelectArray = ({ register, error, label, id,items = [],keyArray,labelArray, ...inputProps }) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <select
          {...register(id)}
          id={id}
          className={`form-control ${error ? "is-invalid" : ""}`}
          {...inputProps}
        >
          <option value={0}>--- SELECCIONE ---</option>

          {items.map((item, index) => (
            <option key={index} value={item[keyArray]}>
              {item[labelArray]}
            </option>
          ))}
        </select>
        {error && <div className="invalid-feedback">{error.message}</div>}
      </div>
    </>
  );
};
export default FormSelectArray;
