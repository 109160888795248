import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import TablaVacas from './componentes/TablaVacas';
import ListadoProductores from '../../../usuarios/InformacionGeneral/componentes/ListadoProductores.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';

const TecnicosEstadoReproductivo = () => {
	const [productor, setProductor] = useState(0);
	const [idMargarita, setIdMargarita] = useState('');
	const [idUgrj, setIdUgrj] = useState('');
	const [rancho, setRancho] = useState('');

	const productorSeleccionado = (id_productor,id_margarita,id_ugrj,rancho) => {
		setProductor(id_productor);
		setIdMargarita(id_margarita);
		setIdUgrj(id_ugrj);
		setRancho(rancho);
	}


		return (
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item active"><Link to="/tecnicos/reporte">Reporte estado reproductivo</Link></li>
				</ol>
				<h1 className="page-header">Reporte estado reproductivo</h1>
				<Panel>
					<PanelHeader></PanelHeader>
					<PanelBody>
					<div className="row">
						<div className="col-md-3" style={{borderRight : '2px solid #dadada'}}>
							<ListadoProductores productorSeleccionado={productorSeleccionado} />
						</div>

						<div className="col-md-9" style={{borderRight : '2px solid #dadada'}}>
						{productor > 0 && (
							<TablaVacas id_productor={productor} id_margarita={idMargarita} id_ugrj={idUgrj} rancho={rancho} />
						)}
						</div>
					</div>
					</PanelBody>
				</Panel>
			</div>
		)
};

export default TecnicosEstadoReproductivo;