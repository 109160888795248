import React, { useState, useEffect } from 'react'
import Api from '../../../api/global.service'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import DataTable from 'react-data-table-component'
import HerramientasTablas from './HerramientasTablas/HerramientasTablas'
import estiloGeneral from './estiloGeneral'
import {pagOptionsES} from '../../helpers/pagOptionsES'

var dataArray = []

const TablaTotales = ({ url, titulo = '', config = {}, params = {} }) => {
  const [filtradoAlert, setFiltradoAlert] = useState(false)
  const [datosFiltrados, setDatosFiltrados] = useState([])

  const [blocking, setBlocking] = useState(true)

  const columns = [
    {
      name: config.labelsText,
      selector: config.labelsKey,
      sortable: true,
      right: false,
    },
    {
      name: config.dataText,
      selector: config.dataKey,
      sortable: true,
      right: false,
    },
  ]

  const handleChange = (datosFiltrados) => {
    setDatosFiltrados(datosFiltrados)
  }

  useEffect(() => {
    let label = []
    let data = []
    Api.getAll(url, params)
      .then((response) => {
        setBlocking(false)
        dataArray = response.data.data
        setDatosFiltrados(dataArray)
        setBlocking(false)
      })
      .catch((e) => {
        setBlocking(false)
        console.log(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <BlockUi tag="div" blocking={blocking}>
        <HerramientasTablas
          datosTabla={datosFiltrados}
          urlRegistrar=""
          columnasFiltrar={['proveedor', 'total']}
          exportar={false}
          onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
          funcionFiltrar={false}
          filtrarRegistros={false}
          filtros={[]}
          filtradoAlert={filtradoAlert}
        />

        <DataTable
          title={titulo}
          pagination
          paginationPerPage={config.perPage}
          paginationComponentOptions={pagOptionsES}
          columns={columns}
          data={datosFiltrados}
          noDataComponent={'Sin datos por mostrar'}
          customStyles={estiloGeneral}
        />
      </BlockUi>
    </>
  )
}

export default TablaTotales
