import React, { useEffect, useState } from "react";
import Api from "../../../../../../api/global.service";
import ApiUrls from "../../../../../../constants/Api";
import "../../../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import { Button, Collapse } from "reactstrap";

var dataArray = [];
const TablaDatosMisIngresos = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);
  const [bonoOpen, setBonoOpen] = useState('');
  const localeFormat = "en-US";
  const formatNumber = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  };

  useEffect(() => {
    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.infogeneral.minegocio.misingresos, {
        id_ugrj: props.id_ugrj,
        tipo: props.tipo,
      })
        .then((response) => {
          dataArray = response.data.data;
          bonosRetencionesTotal(dataArray);
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    if (props.id_productor > 0) {
      obtenerInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_productor]);


  // totales bonos y retenciones
  const bonosRetencionesTotal = () => {
    let total;
    dataArray.forEach(d => {
      total = 0;
      d.data.forEach(b => {
        b.nombre_tipo_liquidacion != 'Liquidación de leche' && (total = total + parseFloat(b.total));
      });
      d.dataBonos = total;
    });
  }

  const toggle = (item) => {
    item == bonoOpen ? setBonoOpen('') : setBonoOpen(item);
  };

  const formatMNX = n => new Intl.NumberFormat(localeFormat, formatNumber).format(n)

  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
        <div className="col-md-12">
          <h5>{props.titulo}</h5>
          {datos[0] &&
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {datos.map((dato, i) => (
                <tr key={i}>
                  <td>{dato.desc}</td>
                  <td>
                    {dato.data.map((ingreso) => (
                      <span>
                        {ingreso.nombre_tipo_liquidacion == 'Liquidación de leche' && (
                          <p><strong>{ingreso.nombre_tipo_liquidacion}:</strong> {formatMNX(ingreso.total)}</p>
                        )}
                      </span>
                    ))}
                    {props.tipo != 1 && dato.data.length > 1 && (
                      <div>
                        <strong>Bonos/Retenciones: </strong>
                        <Button 
                          onClick={() => toggle(dato.desc)} 
                          size="small" 
                          color="default"
                          style={{background: '#e4e7ea'}}
                        >
                          {formatMNX(dato.dataBonos)}
                        </Button>
                        <Collapse isOpen={bonoOpen == dato.desc}>
                          <ul className="m-0 mt-1 p-0">
                            {dato.data.filter(d => d.nombre_tipo_liquidacion != 'Liquidación de leche').map(bono => (
                                <li>
                                  <small>{bono.nombre_tipo_liquidacion}:</small> 
                                  <span className={bono.total > 0 ? 'd-block' : 'd-block text-danger'}>
                                    {formatMNX(bono.total)}
                                  </span>
                                </li>
                            ))}
                          </ul>
                        </Collapse>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          }
        </div>
      </div>
    </BlockUi>
  );
};
export default TablaDatosMisIngresos;
