import React, { useEffect, useState } from "react";
import Api from "../../../../../../api/global.service";
import ApiUrls from "../../../../../../constants/Api";
import "../../../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
var dataArray = [];
const TablaDatosMisGastos = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {

    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.infogeneral.minegocio.misgastos, {
        id_margarita: props.id_margarita,
        id_ugrj: props.id_ugrj,
        rancho: props.rancho,
        tipo: props.tipo,
      })
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    if (props.id_productor > 0) {
      obtenerInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_productor]);



  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
        <div className="col-md-12">
          <h5>{props.titulo}</h5>
          {datos[0] &&
          <table className="table table-striped table-bordered">
            <tr>
              <th rowSpan={2}></th>
              <th className="bg-light" colSpan={2}><center>Gasto total</center></th>
              <th colSpan={2}><center>Gasto alimentación</center></th>
              <th className="bg-light" colSpan={2}><center>Otros gastos</center></th>
            </tr>

            <tr>
              <th className="bg-light"><center>Total</center></th>
              <th className="bg-light"><center>Por Litro</center></th>
              <th><center>Total</center></th>
              <th><center>Por Litro</center></th>
              <th className="bg-light"><center>Total</center></th>
              <th className="bg-light"><center>Por Litro</center></th>
            </tr>
              {datos.map((dato) => (
                <tr>
                  <th>{dato.desc}</th>
                  <td className="bg-light"><center>{dato.costo_total}</center></td>
                  <td className="bg-light"><center>{dato.costo_total_litros}</center></td>
                  <td><center>{dato.costo_alimentacion}</center></td>
                  <td><center>{dato.costo_litro_alimentacion}</center></td>
                  <td className="bg-light"><center>{dato.costo_otros}</center></td>
                  <td className="bg-light"><center>{dato.costo_litro_otros}</center></td>
                </tr>
              ))}
          </table>
          }
        </div>
      </div>
    </BlockUi>
  );
};
export default TablaDatosMisGastos;
