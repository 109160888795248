import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import UsuariosLoginTabla from './UsuariosLoginTabla.jsx';

const UsuariosLoginListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Inicios de sesión</Link></li>
        <li className="breadcrumb-item active">Listado de inicios de sesión</li>
      </ol>
      <h1 className="page-header">Inicios de sesión <small></small></h1>
      <Panel>
        <PanelHeader>Inicios de sesión</PanelHeader>
        <PanelBody>
            <UsuariosLoginTabla/>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default UsuariosLoginListar;
