import xlsx from "json-as-xlsx";
import moment from 'moment';

const UsuariosExportar = (content) => {
    let columns = [
        { label: 'ID Usuario', value: 'id' },
        { label: 'Rol', value: row => (row.roles[0].name) },
        { label: 'Usuario', value: 'username' },
        { label: 'Tipo proveedor', value: 'tipo_proveedor' },
        { label: 'Email', value: 'email' },
        { label: 'Email notificación', value: 'email_notification' },
        { label: 'Nombre', value: row => (row.firstname + ' ' + row.lastname1 + ' ' + ((row.lastname2 === null) ? '' : row.lastname2)) }, // Run functions
        { label: 'ID Margarita', value: 'id_margarita' },
        { label: 'ID UGRJ', value: 'id_ugrj' },
        { label: 'Rancho', value: 'rancho' },
        { label: 'Centro de colecta', value: row => (row.centro.centro_colecta) },
        { label: 'Ruta', value: row => (row.ruta.nombre_ruta) },
        { label: 'Genero', value: 'gender' },
        { label: 'Fecha de Nacimiento', value: 'birthdate' },
        { label: 'Fecha registro', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'Usuarios',
        fileName: 'Usuarios_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default UsuariosExportar;
