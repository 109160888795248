import React from "react";

const DynamicsCalidadesListado = (props) => {


  const setCalidad = (calidad) => {
    props.calidadSeleccionada(calidad);
  };


  return (
    <div className="row">
		{props.calidades.length > 0 && (
    <div className="col-md-12">
    <table className="table table-bordered">
            <thead>
              <tr>
				        <th>Folio</th>
                <th>ID Margarita</th>
                <th>ID UGRJ</th>
                <th>Fecha</th>
                <th>Grasas</th>
                <th>Proteinas</th>
                <th>Células Somáticas</th>
                <th>Status sincronización</th>
                <th>Ver en dynamics</th>
              </tr>
            </thead>
            <tbody>

              {props.calidades.map((calidad) => (
              <tr key={calidad.tch_calidadproductorid}>
                <td>{calidad.tch_foliocalidad}</td>
                <td>{calidad.tch_proveedorproductorID.tch_idproductor}</td>
                <td>{calidad.tch_proveedorproductorID.tch_idproveedor}</td>
                <td>{calidad.tch_fecharuta}</td>
                <td className={(calidad.tch_resultadograsas === null) ? 'bg-danger' : ''}>{calidad.tch_resultadograsas}</td>
                <td className={(calidad.tch_resultadoproteinas === null) ? 'bg-danger' : ''}>{calidad.tch_resultadoproteinas}</td>
                <td className={(calidad.tch_resultadoscelulassomaticas === null) ? 'bg-danger' : ''}>{calidad.tch_resultadoscelulassomaticas}</td>
			          <td>
                    <button className="btn btn-dark btn-sm" onClick={() => setCalidad(calidad)}><i className="fa fa-arrow-right"></i></button>
                </td>
			          <td>
                <a className="btn btn-info" target="_blank" rel="noopener noreferrer" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_calidadproductor&id=' + calidad.tch_calidadproductorid}><i className="fa fa-link"></i></a>
                </td>

              </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

  );
  };
export default DynamicsCalidadesListado;
