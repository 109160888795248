import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import getUrlFiltros from '../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import TablaDatosDispositivo from '../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Dispositivos/TablaDatosDispositivo';
import { Link } from "react-router-dom";

var dataArray = [];
const AsistenteUsuariosTabla = (props) => {
	const [show, setShow] = useState(false);
	const [filtradoAlert, setFiltradoAlert] = useState(false);
	const [datosDispositivo, setDatosDispositivo] = useState({});
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [state, setState] = useState({
	id_user : 0,
	usuarios: [],
	currentUsuario: null,
	currentIndex: -1
	});


	const columns = [
		{
			name: '',
			button: true,
			cell: (row) => <><Link className="btn btn-xs btn-info" to={'asistente/usuarios/info/' + row.id}><i className="fa fa-search"></i></Link> &nbsp; <Link className="btn btn-xs btn-warning" to={'/asistente/usuarios/vacas/eventos/' + row.id}><i className="fa fa-list"></i></Link></>,
		},

		{
			name: 'PROVEEDOR / CONTACTO',
			sortable: true,
			maxWidth: '300px',
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.nombre_proveedor}</div>{row.firstname + ' ' + row.lastname1 + ' ' + row.lastname2}</div>
		},
		{
			name: 'ID UGRJ/ID MARGARITA',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.id_ugrj}</div>{row.id_margarita}</div>
		}
	];

	useEffect(() => {
		setFiltradoAlert(false);
		obtenerUsuarios();
	}, []);

	  const obtenerUsuarios = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,'usuarios')).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		}).catch(e => {
			setBlocking(false);
		});
	  }

	const filtrarUsuarios = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerUsuarios(true);
		}else{
			obtenerUsuarios();
		}
	};

	const hideFormEdit = () => {
		setState({...state,id_user : 0});
		setShow(false);
	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" blocking={blocking}>

			<HerramientasTablas 
				txtBusqueda="Buscar usuario" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["nombre_proveedor","firstname","lastname1","lastname2","username","id_margarita","id_ugrj","nombre_nivel"]}
				exportar={false}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				filtradoAlert={filtradoAlert}
			/>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />

			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Información del dispositivo
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
			  <TablaDatosDispositivo datos={datosDispositivo} />
              </Modal.Body>
            </Modal>
		</BlockUi>
    );
  };
export default AsistenteUsuariosTabla;
