import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import DataTable from 'react-data-table-component';
import  '../../../nuup/styles/Tablas.css'
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import getUrlFiltros from '../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import BlockUi from 'react-block-ui';
var dataArray = [];

const WhatsappUsuariosTabla = (props) => {
	const [toggleCleared] = React.useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [blocking, setBlocking] = useState(false);

	const columns = [
		{
			name: 'ID',
			selector: 'id',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'NOMBRE',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.firstname}</div>{row.lastname1 + ' ' + row.lastname2}</div>
		},
		{
			name: 'ROL',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{(typeof row.roles[0] !== 'undefined') ? row.roles[0].name : 'SIN ROL'}</div></div>
		},
		{
			name: 'USUARIO',
			selector: 'username',
			sortable: true,
			right: false
		},

		{
			name: 'ID UGRJ',
			selector: 'id_ugrj',
			sortable: true,
			right: false
		},

		{
			name: 'RUTA',
			selector: 'ruta.nombre_ruta',
			sortable: true,
			right: false,
		}


	];

	useEffect(() => {
		obtenerUsuarios(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id_centro_colecta,props.id_ruta]);


	const obtenerUsuarios = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,'usuarios')).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		  }).catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }

	const handleRowSelected = React.useCallback(state => {
		props.onChangeSeleccionados(state.selectedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const filtrarUsuarios = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerUsuarios(true);
		}else{
			obtenerUsuarios();
		}
	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (

		<Panel>
			<PanelHeader>Productores a enviar</PanelHeader>
			<PanelBody>

			<BlockUi tag="div" blocking={blocking}>
			<HerramientasTablas 
				txtBusqueda="Buscar usuario" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["email","nombre_proveedor","tipo_proveedor","username","email_notification","id_margarita","id_ugrj","created_at"]}
				exportar={false}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				funcionFiltrar={filtrarUsuarios}
				filtrarRegistros={true}
				filtros={['centro','ruta','productor','nivel']}
				filtradoAlert={false}
			/>
			<DataTable
				pagination
				columns={columns}
				data={datosFiltrados}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				selectableRows
				onSelectedRowsChange={handleRowSelected}
				clearSelectedRows={toggleCleared}
			/>
			</BlockUi>
		</PanelBody>
		</Panel>
    );
  };
export default WhatsappUsuariosTabla;
