import React,{useState} from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import ReportesTabla from './ReportesTabla.jsx';
import ReportesDetalleTabla from './ReportesDetalleTabla.jsx';

const AvisosListar = () => {
  const [aviso, setAviso] = useState({id_aviso : 0});

	const avisoSeleccionado = (aviso) => {
		setAviso(aviso);
	}


    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Listado de notificaciones</li>
      </ol>
      <h1 className="page-header">Reportes enviados <small></small></h1>
      <Panel>
        <PanelHeader>Notificaciones enviadas</PanelHeader>
        <PanelBody>

        <div className="row">
						<div className="col-md-4" style={{borderRight : '2px solid #dadada'}}>
              <ReportesTabla avisoSeleccionado={avisoSeleccionado} />
						</div>
						<div className="col-md-8" style={{borderRight : '2px solid #dadada'}}>
              <ReportesDetalleTabla aviso={aviso} />
						</div>
					</div>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default AvisosListar;
