import React from 'react';

const FormInput = ({register,error,label,id,...inputProps}) => {
    return <>
    	<div className="form-group">
            {inputProps.type !== 'hidden' && (
                <label htmlFor={id}>
                    {label}
                    {inputProps.required && (<span className='badge badge-primary ml-1 pb-0 pr-1 pl-1'>*</span>)}
                </label>
            )}
            <input
                id={id}
                className={`form-control ${error ? "is-invalid" : ""}`}
                {...inputProps}
                {...register(id)}
            />
            {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
    </>
};
export default FormInput;