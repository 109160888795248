import React from "react";
import { Route, withRouter } from "react-router-dom";
import routes from "./../../config/page-route.jsx";
import routesAdmin from "./../../config/page-route-admin.jsx";
import routesTecnico from "./../../config/page-route-tecnico.jsx";
import routesAsistente from "./../../config/page-route-asistente.jsx";
import routesFIRA from "./../../config/page-route-fira.jsx";
import { PageSettings } from "./../../config/page-settings.js";

if (localStorage.getItem("esAdmin") === "true") {
  var _routes = routesAdmin;
} else if (localStorage.getItem("esTecnico") === "true") {
  var _routes = routesTecnico;
} else if (localStorage.getItem("esAsistente") === "true") {
  var _routes = routesAsistente;
} else if (localStorage.getItem("esFIRA") === "true") {
  var _routes = routesFIRA;
}else{
  var _routes = routes;
}

function setTitle(path, routeArray) {
  var pageTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = "TecnoLeche | " + routeArray[i].title;
    }
  }
  document.title = pageTitle ? pageTitle : "TecnoLeche | Administrador";
}

class Content extends React.Component {
  componentDidMount() {
    setTitle(this.props.history.location.pathname, _routes);
  }
  componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, _routes);
    });
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({
          pageContentFullWidth,
          pageContentClass,
          pageContentInverseMode,
        }) => (
          <div
            className={
              "content " +
              (pageContentFullWidth ? "content-full-width " : "") +
              (pageContentInverseMode ? "content-inverse-mode " : "") +
              pageContentClass
            }
          >
            {_routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          </div>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default withRouter(Content);
