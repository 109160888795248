import React from "react";
import MisAsistenciasListado from "./MisAsistenciasListado";
const MisAsistencias = (props) => {
  return (
    <>
      <h4 style={{backgroundColor : '#d4d4d4',padding : 10}}>Mi Asistencias técnicas</h4>
      {props.id_productor > 0 && (
          <>
            <MisAsistenciasListado id_productor={props.id_productor} />
          </>
      )}
    </>
  );
};
export default MisAsistencias;
