import React, { useEffect,useState } from "react";
import Api from '../../../../api/global.service';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import AvisosArchivosSubirForm from './AvisosArchivosSubirForm';
import Button from '../../../../nuup/componentes/Generales/Button';
import DeleteButton from '../../../../nuup/componentes/Generales/DeleteButton';
import  '../../../../nuup/styles/Tablas.css'
import estiloGeneral from '../../../../nuup/componentes/Tablas/estiloGeneral';
import { Link  } from "react-router-dom";
import {Export,downloadCSV} from '../../../../nuup/componentes/Tablas/exportarCsv';
var dataArray = [];
const AvisosArchivosSubirTabla = (props) => {
	const [show, setShow] = useState(false);
	const [state, setState] = useState({
	id_user : 0,
	archivos: [],
	currentUsuario: null,
	currentIndex: -1
	});


	const columns = [
		{
			name: 'ID',
			selector: 'id_aviso_archivo',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'TIPO DE REPORTE',
			maxWidth: '250px',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{(typeof row.tipodocumento !== 'undefined') ? row.tipodocumento.aviso_categoria : 'SIN TIPO'}</div></div>
		},

		{
			name: 'RUTA DE LOS ARCHIVOS',
			maxWidth: '450px',
			sortable: true,
			cell: row => <div>{'storage/documentos/' + row.carpeta + '/'  +  row.fecha_inicio.replace('-', '').replace('-', '') + '_' + row.fecha_fin.replace('-', '').replace('-', '')  }</div>
		},
		{
			name: 'FECHA INICIO',
			selector: 'fecha_inicio',
			maxWidth: '150px',
			sortable: true,
			right: false
		},

		{
			name: 'FECHA FIN',
			selector: 'fecha_fin',
			maxWidth: '150px',
			sortable: true,
			right: false
		},

		{
			name: 'NÚMERO DE REGISTROS',
			selector: 'numero_registros',
			maxWidth: '250px',
			sortable: true,
			right: false
		},
		{
			name: 'FECHA SUBIDA',
			selector: 'created_at',
			sortable: true,
			right: false
		},

		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Link className="btn btn-warning btn-xs" to={'/administracion/notificaciones/reporte/enviar/' + row.id_aviso_archivo }><i className="fa fa-arrow-right"></i></Link><DeleteButton onClick={() => deleteArchivo(row.id_aviso_archivo)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];

	useEffect(() => {
		obtenerArchivos();
	}, []);

	  const obtenerArchivos = ()  => {
		Swal.showLoading();	
		Api.getAll('notificaciones/archivos/listado')
		  .then(response => {
			Swal.close();
			dataArray = response.data.data;
			setState({
			  archivos: response.data.data
			});
			console.log(response.data.data);
		  })
		  .catch(e => {
			Swal.close();
			console.log(e);
		  });
	  }

	  

	const deleteArchivo = (id_archivo_aviso) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete('notificaciones/archivos/eliminar',id_archivo_aviso)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Usuario eliminado.',
						  'success'
						);
					}
				obtenerArchivos();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el usuario.',
					'error'
				  );
				  obtenerArchivos();
				});

			}
		  })

	};

	

	
	const hideFormEdit = () => {
		setState({...state,id_user : 0});
		setShow(false);
	};

	const actionsMemo = React.useMemo(() => <><Button className="btn btn-secondary" onClick={() => setShow(true)}>Subir Archivo</Button> <Export onExport={() => downloadCSV(dataArray,'avisos_reporte')} /></>, []);

	return (
      <div>
          <DataTable
            pagination
            columns={columns}
            data={state.archivos}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
			actions={actionsMemo}
          />

			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Subir Archivo
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AvisosArchivosSubirForm id_user={state.id_user} />
              </Modal.Body>
            </Modal>


		</div>
    );
  };
export default AvisosArchivosSubirTabla;
