import React,{useState,useEffect} from 'react';
import Api from '../../../api/global.service';
import conviertArray from '../../helpers/conviertArray'; 
import BlockUi from 'react-block-ui';

const FormSelectApi = (props) => {
	const [state, setState] = useState({
		items : [],
    blocking: true
	});

    const onFinish = () => {
        props.onFinish();
    };

	useEffect(() => {
        Api.getAll(props.url).then(response => {
            let _items = conviertArray(response.data.data);
            setState({items : _items,blocking : false});
            onFinish();
        }).catch(e => {
			console.log(e);
        });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return <>
            <div className="form-group">
                <label htmlFor={props.id}>
                    {props.label}
                    {props.required && (<span className='badge badge-primary ml-1 pb-0 pr-1 pl-1'>*</span>)}
                </label>
                <BlockUi tag="div" blocking={state.blocking}>
                    <select 
                    {...props.register(props.id)}
                    id={props.id}
                    name={props.name}
                    className={`form-control ${props.error ? "is-invalid" : ""}`}
                    defaultValue={0}
                    {...(props.onChange ? {onChange : props.onChange} : {})}
                    >
                    <option value={0}>--- SELECCIONE ---</option>

                    {state.items.map((item,index) =>
                        <option key={index} value={item[props.keyArray]}>
                            { !Array.isArray(props.labelArray)
                                            ? item[props.labelArray]
                                            : props.labelArray.reduce((pv, cv) => pv + item[cv] + ' ', '')
                            }
                        </option>
                    )}
                </select>
                {props.error && <div className="invalid-feedback">{props.error.message}</div>}
                </BlockUi>
            </div>
    </>
};
export default FormSelectApi;