import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import Api from "../../../api/global.service";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../../../nuup/componentes/Forms/FormInput.jsx";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Swal from "sweetalert2";
import DynamicsCalidadesListado from "./componentes/DynamicsCalidadesListado";
import DynamicsCalidadesInfo365 from "./componentes/DynamicsCalidadesInfo365";
import DynamicsCalidadesInfoMysql from "./componentes/DynamicsCalidadesInfoMysql";

import Modal from "react-bootstrap/Modal";

const DynamicsCalidades = () => {
	const [show, setShow] = useState(false);

	const schema = yup.object().shape({
		valor: yup.string(),
		tipo: yup.string(),
	  });
	
	  const [recarga, setRecarga] = useState(0);
	  const [btncancelar, setBtncancelar] = useState(false);
	  const [calidades, setCalidades] = useState([]);
	  const [calidad, setCalidad] = useState({});

	  const [state] = useState({
		valor: "",
		tipo: 1,
	  });
	
	  const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	  } = useForm({
		resolver: yupResolver(schema),
		mode: "onBlur",
		defaultValues: state,
	  });

	  const onSubmit = (values) => {
		Swal.showLoading();
		Api.getAll("dynamics365/buscar/calidad", {
			fechaInicio: values.fechaInicio,
			fechaFin: values.fechaFin,
			valor: values.valor,
			tipo: values.tipo
		}).then((response) => {
			let _result = response.data;
			if (_result.success === 1) {
			  setCalidades(_result.data);
			  setShow(true);
			  Swal.close();
			} else {
			  alert(_result.message);
			}
		  }).catch((e) => {
			Swal.fire("Error!", "Error al buscar información del usuario.", "error");
		  });
	  };

	  const hideFormEdit = () => {
		setShow(false);
	  };

	  const setCalidadSeleccionada = (calidad) => {
		setShow(false);
		setCalidad(calidad);
	  };

	  


  return (
    <>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Dynamics</Link>
        </li>
        <li className="breadcrumb-item active">Calidades</li>
      </ol>
      <h1 className="page-header">
        Calidades <small>Dynamics</small>
      </h1>
      <Panel>
        <PanelHeader>Buscar registros de calidad</PanelHeader>
        <PanelBody>
			<>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">

			<div className="col-md-3">
            <FormInput
                  id="fechaInicio"
                  type="date"
                  name="fechaInicio"
                  label="Fecha Inicio"
                  error={errors.fechaInicio}
                  register={register}
                />
			</div>

			<div className="col-md-3">
			<FormInput
                  id="fechaFin"
                  type="date"
                  name="fechaFin"
                  label="Fecha Fin"
                  error={errors.fechaFin}
                  register={register}
                />
			</div>

              <div className="col-md-3">
                <FormInput
                  id="valor"
                  type="text"
                  name="valor"
                  label="ID"
                  error={errors.valor}
                  register={register}
                />
              </div>

              <div className="col-md-3">
                <label>{"Tipo de búsqueda"}</label>
                <select
                  id="tipo"
                  className={`form-control`}
                  name="tipo"
                  {...register("tipo")}
                >
                  <option value="1">ID Margarita</option>
                  <option value="2">ID UGRJ</option>
				  <option value="3">Rancho</option>
                </select>
              </div>

            </div>

            <div className="row">
              <div className="col-md-4 offset-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <button className="btn btn-primary btn-block" type="submit">
                      {"Buscar"}
                    </button>
                  </div>
                </div>
              </div>
			  {calidades && calidades.length > 0 && (
			  <div className="col-md-4 offset-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <a onClick={() => setShow(true)} className="btn btn-warning btn-block">
                      {"Seleccionar otra"}
                    </a>
                  </div>
                </div>
              </div>
			  )}
            </div>
          </form>

		<hr />

			<Modal
			show={show}
			onHide={() => hideFormEdit()}
			size="xl"
			>
			<Modal.Header closeButton>
			<Modal.Title id="example-custom-modal-styling-title">
				Resultados de la búsqueda
			</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<DynamicsCalidadesListado calidadSeleccionada={(calidad) => setCalidadSeleccionada(calidad)} calidades={calidades} />
			</Modal.Body>
		</Modal>




		  </>
        </PanelBody>
      </Panel>

	{calidad.tch_calidadproductorid && (
		<>
		<DynamicsCalidadesInfo365 calidad={calidad} />
		<DynamicsCalidadesInfoMysql calidad_uuid={calidad.tch_calidadproductorid} calidad={calidad} />
	  </>

	)}

    </>
  );
};

export default DynamicsCalidades;
