import React, { useEffect, useState } from "react";
import Api from "../../../../../api/global.service";
import "../../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';


var dataArray = [];
const MiPerfilInfo = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {

    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll("usuarios", { tipo: 3,valor: props.id_productor })
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    if (props.id_productor > 0) {
      obtenerInfo();
    }
  }, [props.id_productor]);



  return (
    <BlockUi tag="div" blocking={blocking}>
    {datos[0] && 
        <Card bg='light' style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{datos[0].nombre_proveedor}</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
        <ListGroupItem><strong>Tipo de proveedor: </strong>{datos[0].tipo_proveedor}</ListGroupItem>
          <ListGroupItem><strong>ID Margarita: </strong>{datos[0].id_margarita}</ListGroupItem>
          <ListGroupItem><strong>ID UGRJ: </strong>{datos[0].id_ugrj}</ListGroupItem>
          <ListGroupItem><strong>Centro de colecta: </strong>{datos[0].centro.centro_colecta}</ListGroupItem>
          <ListGroupItem><strong>Ruta: </strong>{datos[0].ruta.nombre_ruta}</ListGroupItem>
        </ListGroup>
      </Card>
    }
    </BlockUi>
  );
};
export default MiPerfilInfo;
