import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import procesarCola from '../../../nuup/helpers/procesarCola';
import SoloPushUsuariosTabla from './SoloPushUsuariosTabla';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';

const SoloPushEnviarForm = () => {
	const [centro] = useState(0);
	const [ruta] = useState(0);
	const [blocking, setBlocking] = useState(false);


	const schema = yup.object().shape({
		titulo : yup.string('Introduzca título').required('Introduzca título'),
		mensaje : yup.string('Introduzca mensaje').required('Introduzca mensaje'),
	});
	const [seleccionados,setSeleccionados] = useState([]);


	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: {
			titulo : '',
			mensaje : '',
		}
	});



	const onSubmit = data => {
		enviarNotificaciones(data);
	}

	
	const enviarNotificaciones = (data) => {
		if(seleccionados.length > 0){
			setBlocking(true);
			Api.create('notificaciones/push',{
				ids : seleccionados,
				titulo : data.titulo,
				mensaje : data.mensaje
			}).then(response => {
				setBlocking(false);
				procesarCola();
				let _result = response.data;
				if(_result.success === 1){
					Swal.close();
					Swal.fire(
						'Enviado!',
						'Notificaciones enviadas.',
						'success'
					);
				}
			}).catch(e => {
				setBlocking(false);
				Swal.fire(
				'Error!',
				'Error al enviar notificaciones.',
				'error'
				);
			});
			
		}else{
			setBlocking(false);
			Swal.fire(
				'Error',
				'Seleccione al menos un registro para enviar',
				'question'
			  )
			
		}
	};


	const handleSeleccionados = (_arr) => {
		console.log('handleSeleccionados');
		console.log(_arr);
		let _ids = [];
		for (let a = 0; a < _arr.length; a++) {
			_ids.push(_arr[a].id);			
		}
        setSeleccionados(_ids);
    }


	return (
		<>
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/administracion/noticias">Enviar notificación (Solo Push)</Link></li>
				<li className="breadcrumb-item active"></li>
			</ol>
			<h1 className="page-header">Enviar notificación <small></small></h1>
			<div className="row">
			<div className="col-md-12">
				<Panel>
					<PanelHeader>Información a enviar</PanelHeader>
					<PanelBody>
					<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-6">
								<FormInput 
									id="titulo"
									type="text"
									name="titulo"
									label="Título"
									error={errors.titulo}
									register={register}
									/>
								</div>
								<div className="col-md-6">
								<FormInput 
									id="mensaje"
									name="mensaje"
									label="Mensaje"
									error={errors.mensaje}
									register={register}
									/>
								</div>
							</div>
							<SoloPushUsuariosTabla id_centro_colecta={centro} id_ruta={ruta} onChangeSeleccionados={handleSeleccionados} />			
							<button className="btn btn-primary btn-block" type="submit">Enviar notificación</button>
						</form>			
						</BlockUi>
					</PanelBody>
				</Panel>
			</div>
			</div>

			</div>
		</>
	);
};
export default SoloPushEnviarForm;
