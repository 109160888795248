import React from "react";
import MiPerfilInfo from "./MiPerfilInfo";
import MiPerfilRancho from "./MiPerfilRancho";
const MiPerfil = (props) => {
  return (
    <>
      <h3>Mi perfil</h3>
      {props.id_productor > 0 && (
        <>
          <div className="row">
            <div className="col-md-3">
              <MiPerfilInfo id_productor={props.id_productor} />
            </div>
            <div className="col-md-3">
              <MiPerfilRancho id_productor={props.id_productor} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default MiPerfil;
