import React from "react";
import HerramientasTablasFiltroStateCodeTabla from "./HerramientasTablasFiltroStateCodeTabla";
import setFiltros from "../../Herramientas/setFiltros";
const HerramientasTablasFiltroStateCode = (props) => {
  const { quitarSeleccionados } = props;

  const handleSeleccionados = (_arr) => {
    let _ids = [];
    for (let a = 0; a < _arr.length; a++) {
      _ids.push(_arr[a].id_estatus);
    }
    setFiltros(7, _ids);
  };

  return (
    <>
      <HerramientasTablasFiltroStateCodeTabla
        onChangeSeleccionados={handleSeleccionados}
        quitarSeleccionados={quitarSeleccionados}
      />
    </>
  );
};
export default HerramientasTablasFiltroStateCode;
