import React from "react";
import TablaDatosMisIngresos from "../tablas/TablaDatosMisIngresos";

const MisIngresos = (props) => {
  return (
    <>
      <h4 style={{ backgroundColor: "#d4d4d4", padding: 10 }}>Mis Ingresos</h4>
      {props.id_productor > 0 && (
        <>
          <div className="row">
            <div className="col-md-4">
              <TablaDatosMisIngresos
                titulo="Semana"
                tipo={1}
                id_ugrj={props.id_ugrj}
                id_productor={props.id_productor}
                rancho={props.rancho}
              />
            </div>
            <div className="col-md-4">
              <TablaDatosMisIngresos
                titulo="Últimas 4 semanas"
                tipo={2}
                id_ugrj={props.id_ugrj}
                id_productor={props.id_productor}
                rancho={props.rancho}
              />
            </div>
            <div className="col-md-4">
              <TablaDatosMisIngresos
                titulo="Año"
                tipo={3}
                id_ugrj={props.id_ugrj}
                id_productor={props.id_productor}
                rancho={props.rancho}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default MisIngresos;
