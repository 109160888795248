import React, { useState } from "react";
import "./PantallaFiltros.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import HerramientasTablasFiltroReportes from "./Filtros/HerramientasTablasFiltroReportes/HerramientasTablasFiltroReportes";
import HerramientasTablasFiltroCentros from "./Filtros/HerramientasTablasFiltroCentros/HerramientasTablasFiltroCentros";
import HerramientasTablasFiltroStateCode from "./Filtros/HerramientasTablasFiltroStateCode/HerramientasTablasFiltroStateCode";
import HerramientasTablasFiltroProductor from "./Filtros/HerramientasTablasFiltroProductor/HerramientasTablasFiltroProductor";
import HerramientasTablasFiltroRutas from "./Filtros/HerramientasTablasFiltroRutas/HerramientasTablasFiltroRutas";
import HerramientasTablasFiltroTipoDocumentos from "./Filtros/HerramientasTablasFiltroTipoDocumentos/HerramientasTablasFiltroTipoDocumentos";
import HerramientasTablasFiltroFechas from "./Filtros/HerramientasTablasFiltroFechas/HerramientasTablasFiltroFechas";
import HerramientasTablasFiltroNiveles from "./Filtros/HerramientasTablasFiltroNiveles/HerramientasTablasFiltroNiveles";

import resetFiltros from "./Herramientas/resetFiltros";
export default function PantallaFiltros(props) {
  const { filtros } = props;
  const [quitarReportes, setQuitarReportes] = useState(0);
  const [quitarCentros, setQuitarCentros] = useState(0);
  const [quitarStateCode, setQuitarStateCode] = useState(0);
  const [quitarRutas, setQuitarRutas] = useState(0);
  const [quitarProductores, setQuitarProductores] = useState(0);
  const [quitarTipoDocumentos, setQuitarTipoDocumentos] = useState(0);
  const [quitarFechas, setQuitarFechas] = useState(0);
  const [quitarNiveles, setQuitarNiveles] = useState(0);

  const limpiarFiltros = () => {
    setQuitarReportes(0);
    setQuitarCentros(0);
    setQuitarStateCode(0);
    setQuitarRutas(0);
    setQuitarProductores(0);
    setQuitarTipoDocumentos(0);
    setQuitarFechas(0);
    setQuitarNiveles(0);
    resetFiltros();
  };

  const cambioTab = (activeKey) => {
    // Quitar seleccionados de las tabs no seleccionadas
    limpiarFiltros();
    // eslint-disable-next-line
    filtros.map(function (filtro) {
      if (filtro !== activeKey) {
        switch (filtro) {
          case "reporte":
            setQuitarReportes(1);
            break;
          case "statecode":
            setQuitarStateCode(1);
            break;
          case "centro":
            setQuitarCentros(1);
            break;
          case "ruta":
            setQuitarRutas(1);
            break;
          case "productor":
            setQuitarProductores(1);
            break;
          case "tipodocumento":
            setQuitarTipoDocumentos(1);
            break;
          case "fecha":
            setQuitarFechas(1);
            break;
          case "nivel":
            setQuitarNiveles(1);
            break;
            default:
        }
      }
    });
  };

  return (
    <>
      <Tabs
        defaultActiveKey={filtros[0]}
        transition={false}
        id="noanim-tab-example"
        className="mb-3"
        onSelect={(activeKey) => cambioTab(activeKey)}
      >
        {filtros.includes("fecha") && (
          <Tab eventKey="fecha" title="Por fecha">
            <HerramientasTablasFiltroFechas
              quitarSeleccionados={quitarFechas}
            />
          </Tab>
        )}

        {filtros.includes("tipodocumento") && (
          <Tab eventKey="tipodocumento" title="Por tipo de documento">
            <HerramientasTablasFiltroTipoDocumentos
              quitarSeleccionados={quitarTipoDocumentos}
            />
          </Tab>
        )}

        {filtros.includes("reporte") && (
          <Tab eventKey="reporte" title="Por Reporte">
            <HerramientasTablasFiltroReportes
              quitarSeleccionados={quitarReportes}
            />
          </Tab>
        )}

        {filtros.includes("statecode") && (
          <Tab eventKey="statecode" title="Por activo / inactivo">
            <HerramientasTablasFiltroStateCode
              quitarSeleccionados={quitarStateCode}
            />
          </Tab>
        )}

        {filtros.includes("centro") && (
          <Tab eventKey="centro" title="Por centro de colecta">
            <HerramientasTablasFiltroCentros
              quitarSeleccionados={quitarCentros}
            />
          </Tab>
        )}

        {filtros.includes("ruta") && (
          <Tab eventKey="ruta" title="Por ruta">
            <HerramientasTablasFiltroRutas quitarSeleccionados={quitarRutas} />
          </Tab>
        )}
        {filtros.includes("productor") && (
          <Tab eventKey="productor" title="Por productor">
            <HerramientasTablasFiltroProductor
              quitarSeleccionados={quitarProductores}
            />
          </Tab>
        )}
        {filtros.includes("nivel") && (
          <Tab eventKey="nivel" title="Por nivel">
            <HerramientasTablasFiltroNiveles
              quitarSeleccionados={quitarNiveles}
            />
          </Tab>
        )}
      </Tabs>
    </>
  );
}
