import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import  '../../nuup/styles/Tablas.css'

const NoticiasUsuariosTabla = (props) => {
	const [data, setData] = useState([]);
	const [toggleCleared] = React.useState(false);


	const conditionalRowStyles= [
		{
		  when: row => row.statecode == 1,
		  style: {
			backgroundColor: 'rgba(155, 155, 155, 1)',
			color: 'white',
			'&:hover': {
			  cursor: 'pointer',
			},
		  },
		},
	  ];


	const columns = [
		{
			name: 'ID',
			selector: 'id',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'NOMBRE',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.firstname}</div>{row.lastname1 + ' ' + row.lastname2}</div>
		},
		{
			name: 'NOMBRE',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{(typeof row.roles[0] !== 'undefined') ? row.roles[0].name : 'SIN ROL'}</div></div>
		},
		{
			name: 'USUARIO',
			selector: 'username',
			sortable: true,
			right: false
		},

		{
			name: 'ID UGRJ',
			selector: 'id_ugrj',
			sortable: true,
			right: false
		},

		{
			name: 'RUTA',
			selector: 'ruta.nombre_ruta',
			sortable: true,
			right: false,
		}


	];

	useEffect(() => {
		let propsParams = {
			id_centro_colecta : props.id_centro_colecta,
			id_ruta : props.id_ruta,
			role: props.role,
			tipo: 8,
		};
		props.role == 'Productor' && (propsParams.statecode = 0);
		obtenerUsuarios(propsParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id_centro_colecta, props.id_ruta, props.role]);

	const obtenerUsuarios = (params)  => {
		Swal.showLoading();	
		Api.getAll('usuarios',params)
		.then(response => {
			Swal.close();
			setData(response.data.data);
		})
		.catch(e => {
			Swal.close();
			console.log(e);
		});
	}

	const handleRowSelected = React.useCallback(state => {
		props.onChangeSeleccionados(state.selectedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
      <div>
          <DataTable
			pagination
			columns={columns}
			data={data}
			Clicked
			noDataComponent={'Sin datos por mostrar'}
			selectableRows
			onSelectedRowsChange={handleRowSelected}
			clearSelectedRows={toggleCleared}
			conditionalRowStyles={conditionalRowStyles}
          />

		</div>
    );
  };
export default NoticiasUsuariosTabla;
