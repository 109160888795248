import React from "react";
import Table from "react-bootstrap/Table";
export default function TablaDatosDispositivo(props) {
  const { datos } = props;
  return (
    <>
      <Table striped bordered hover>
				<thead>
					<tr>
						<th>Variable</th>
						<th>Valor</th>
					</tr>
				</thead>
				<tbody>
					<tr>
					<th>Brand</th>
					<td>{datos.brand}</td>
					</tr>
					<tr>
						<th>OS</th>
						<td>{datos.osName}</td>
					</tr>
					<tr>
						<th>osVersion</th>
						<td>{datos.osVersion}</td>
					</tr>
					<tr>
						<th>platformApiLevel</th>
						<td>{datos.platformApiLevel}</td>
					</tr>
					<tr>
						<th>designName</th>
						<td>{datos.designName}</td>
					</tr>
					<tr>
						<th>dimensions</th>
						<td>{JSON.stringify(datos.dimensions)}</td>
					</tr>
					<tr>
						<th>totalMemory</th>
						<td>{datos.totalMemory}</td>
					</tr>
					<tr>
						<th>versionCode</th>
						<td>{datos.versionCode}</td>
					</tr>

				</tbody>
				</Table>
    </>
  );
}