import React,{useState,useEffect} from 'react';
import Api from '../../../../../../../../api/global.service';
import conviertArray from '../../../../../../../../nuup/helpers/conviertArray'; 

const HerramientasTablasFiltroReportes = ({showLoading,...inputProps}) => {
	const [state, setState] = useState({
		items : []
	});

	useEffect(() => {
        Api.getAll('notificaciones/reporte/agrupado').then(response => {
            let _items = conviertArray(response.data.data);
            setState({items : _items});
        }).catch(e => {
			console.log(e);
        });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return <>
            <select 
                className={`form-control`}
                {...inputProps}
            >
                <option value={0} selected>--- TODOS ---</option>

                {state.items.map((item,index) =>
                <option key={index} value={item.id}>
                        {item.creado + ' | ' + item.aviso}
                    </option>
                )}
			</select>
    </>
};
export default HerramientasTablasFiltroReportes;