import React, { useEffect, useState } from "react";
import Api from "../../../../api/global.service";
import ApiUrls from "../../../../constants/Api";
import "../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import Modal from "react-bootstrap/Modal";

var dataArrayAsociados = [];
var dataArray = [];

const TecnicoAsociarProductoresListado = (props) => {
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [productoresAsociados, setProductoresAsociados] = useState([]);
  const [productores, setProductores] = useState([]);

  const obtenerProductores = (filtrado = false) => {
    setBlocking(true);
    setProductores([]);
    Api.getAll(ApiUrls.tecnicos.productores, {})
      .then((response) => {
        dataArray = response.data.data;
        setProductores(dataArray);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };


  const obtenerProductoresAsociados = (filtrado = false) => {
    setBlocking(true);
    setProductoresAsociados([]);
    Api.getAll(ApiUrls.tecnicos.productores, { id_tecnico: props.id_tecnico })
      .then((response) => {
        dataArrayAsociados = response.data.data;
        setProductoresAsociados(dataArrayAsociados);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  useEffect(() => {
    obtenerProductores();
    if (props.id_tecnico > 0) {
      obtenerProductoresAsociados();
    }
  }, [props.id_tecnico]);

  const desasociarProductor = (uuid_account) => {
    Api.update(
      ApiUrls.tecnicos.proveedor.desasociar,
      props.uuid_tecnico + "/" + uuid_account,
      {}
    )
      .then((response) => {
        obtenerProductoresAsociados();
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  const asociarProductor = (uuid_account) => {
    Api.create(ApiUrls.tecnicos.proveedor.asociar, {
      uuid_tecnico: props.uuid_tecnico,
      uuid_proveedor: uuid_account,
    })
      .then((response) => {
        obtenerProductoresAsociados();
        setShow(false);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  const listadoProductores = () => {
    console.log("listadoProductores");
  };

  const hideFormEdit = () => {
    setShow(false);
  };

  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
        <a className="btn btn-info btn-xs" onClick={() => setShow(true)}>
          Asociar técnico
        </a>

        <table className="table">
          <thead>
            <tr>
              <th>uuid_dynamics:</th>
              <th>Acceso:</th>
              <th>Nombre:</th>
              <th>Apellido Paterno:</th>
              <th>Apellido Materno:</th>
              <th>ID Margarita:</th>
              <th>ID UGRJ:</th>
              <th>Rancho:</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productoresAsociados[0] &&
              productoresAsociados.map((dato) => (
                <tr key={dato.id}>
                  <td>{dato.uuid_dynamics}</td>
                  <td>{dato.username}</td>
                  <td>{dato.firstname}</td>
                  <td>{dato.lastname1}</td>
                  <td>{dato.lastname2}</td>
                  <td>{dato.id_margarita}</td>
                  <td>{dato.id_ugrj}</td>
                  <td>{dato.rancho}</td>
                  <td>
                    <a
                      onClick={() => desasociarProductor(dato.uuid_dynamics)}
                      className="btn btn-danger btn-xs"
                    >
                      Quitar
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal
        show={show}
        onHide={() => hideFormEdit()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Seleccione productor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th>Nombre:</th>
                <th>Apellido Paterno:</th>
                <th>Apellido Materno:</th>
                <th>ID Margarita:</th>
                <th>ID UGRJ:</th>
                <th>Rancho:</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {productores[0] &&
                productores.map((dato) => (
                  <tr key={dato.id}>
                    <td>{dato.firstname}</td>
                    <td>{dato.lastname1}</td>
                    <td>{dato.lastname2}</td>
                    <td>{dato.id_margarita}</td>
                    <td>{dato.id_ugrj}</td>
                    <td>{dato.rancho}</td>
                    <td>
                      <a
                        onClick={() => asociarProductor(dato.uuid_dynamics)}
                        className="btn btn-info btn-xs"
                      >
                        Agregar
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </BlockUi>
  );
};
export default TecnicoAsociarProductoresListado;
