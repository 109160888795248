import React from 'react';
import Home from './../pages/Home.js';
import LoginV3 from './../pages/login-v3.js';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Home',
    component: () => <Home />
  },
  {
    path: '/login',
    exact: true,
    title: 'Acceder',
    component: () => <LoginV3 />
  }
];

export default routes;