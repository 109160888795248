import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import ListadoProductores from './componentes/ListadoProductores';
import ListadoVacas from './componentes/ListadoVacas';
import InfoVacas from './componentes/InfoVacas';

const VacasEventos = ({ tecnico=false }) => {
	const [productor, setProductor] = useState(0);
	const [vaca, setVaca] = useState('');

	const productorSeleccionado = (id_productor) => {
		setProductor(id_productor);
		setVaca(0);
	}

	const vacaSeleccionada = (id_vaca) => {
		setVaca(id_vaca);
	}

		return (
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item">Mis Productores</li>
					<li className="breadcrumb-item active"><Link to="/tecnicos/vacas/eventos">Vacas Eventos</Link></li>
			</ol>
			<h1 className="page-header">Vacas Eventos</h1>
				<Panel>
					<PanelHeader></PanelHeader>
					<PanelBody>
					<div className="row">
						<div className="col-md-3" style={{borderRight : '2px solid #dadada'}}>
							<ListadoProductores productorSeleccionado={productorSeleccionado} tecnico={tecnico} />
						</div>

						<div className="col-md-3" style={{borderRight : '2px solid #dadada'}}>
							<ListadoVacas id_productor={productor} vacaSeleccionada={vacaSeleccionada} />
						</div>
						<div className="col-md-6">
							<InfoVacas vaca={vaca} />
						</div>
					</div>


					</PanelBody>
				</Panel>
			</div>
		)
};

export default VacasEventos;