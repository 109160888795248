import React, { useEffect, useState } from "react";
import Api from "../../../api/global.service";
import ApiUrls from '../../../constants/Api';
import DataTable from "react-data-table-component";
import estiloGeneral from "../../../nuup/componentes/Tablas/estiloGeneral";
import "../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import HerramientasTablas from "../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas";
import getUrlFiltros from "../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros";

var dataArray = [];
const ListadoTecnicos = (props) => {
  const [filtradoAlert, setFiltradoAlert] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [seleccionado, setSeleccionado] = useState(0);
  const [uuidSeleccionado, setUuidSeleccionado] = useState('');

  useEffect(() => {
    if (seleccionado > 0) {
      props.tecnicoSeleccionado(seleccionado);
      props.tecnicoUuidSeleccionado(uuidSeleccionado);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionado]);

  const columns = [
    {
      name: "Técnico",
      sortable: true,
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 700 }}>{row.firstname + " " + row.lastname1 + " " + row.lastname2}</div>
          {row.email}
        </div>
      ),
    },
    {
      name: "Acceso",
      selector: "username",
      maxWidth: "150px",
      sortable: true,
      right: false,
    },
  ];

  const handleRowClicked = (row) => {
    const updatedData = datosFiltrados.map((item) => {
      if (row.id_tecnico !== item.id_tecnico) {
        return {
          ...item,
          toggleSelected: false,
        };
      } else {
        console.log(item);
        setSeleccionado(item.id_tecnico);
        setUuidSeleccionado(item.uuid_dynamics);
        return {
          ...item,
          toggleSelected: true,
        };
      }
    });

    setDatosFiltrados(updatedData);
  };

  useEffect(() => {
    setFiltradoAlert(false);
    obtenerUsuarios();
  }, []);

  const obtenerUsuarios = (filtrado = false) => {
    setBlocking(true);
    Api.getAll(ApiUrls.tecnicos.listado,{personalizado : props.personalizado})
      .then((response) => {
        dataArray = response.data.data;
        setDatosFiltrados(dataArray);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  const filtrarUsuarios = () => {
    // Validar que existan elementos seleccionados
    if (
      localStorage.getItem("filtro_tipo") > 0 &&
      localStorage.getItem("filtro_valor").length > 0
    ) {
      obtenerUsuarios(true);
    } else {
      obtenerUsuarios();
    }
  };

  const handleChange = (datosFiltrados) => {
    setDatosFiltrados(datosFiltrados);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "#dadada",
        userSelect: "none",
      },
    },
  ];

  return (
    <BlockUi tag="div" blocking={blocking}>
      <HerramientasTablas
        txtBusqueda="Buscar técnico"
        datosTabla={dataArray}
        urlRegistrar=""
        columnasFiltrar={[
          "firstname",
          "lastname1",
          "lastname2",
          "email"
        ]}
        exportar={false}
        onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
        funcionFiltrar={filtrarUsuarios}
        filtrarRegistros={false}
        filtros={[]}
        filtradoAlert={filtradoAlert}
      />

      <DataTable
        pagination
        paginationPerPage={15}
        columns={columns}
        data={datosFiltrados}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={estiloGeneral}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
      />
    </BlockUi>
  );
};
export default ListadoTecnicos;
