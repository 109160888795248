import React from "react";
import Modal from "react-bootstrap/Modal";
import { Worker, Viewer, LoadError, ProgressBar } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Api from "../../../api/global.service";
import Swal from "sweetalert2";
import Button from "./Button";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const ModalPdf = (props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const hideFormEdit = () => {
    props.onCerrarPdf();
  };

  const renderError = (error: LoadError) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        message = "Archivo inválido ó corrupto";
        break;
      case "MissingPDFException":
        message = "Archivo no encontrado";
        break;
      case "UnexpectedResponseException":
        message = "No fue posible obtener el archivo";
        break;
      default:
        message = "Error no definido";
        break;
    }

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#e53e3e",
            borderRadius: "0.25rem",
            color: "#fff",
            padding: "0.5rem",
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  const download = async () => {
    Swal.showLoading();
    const resArchivo = await Api.getBlob(props.archivo);
    const url = window.URL.createObjectURL(new Blob([resArchivo.data]));
    const link = document.createElement("a");
    const archivoNombre =
      "FIRA_" + props.archivo.substring(props.archivo.lastIndexOf("/") + 1);
    link.href = url;
    link.setAttribute("download", archivoNombre);
    document.body.appendChild(link);
    link.click();
    Swal.close();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => hideFormEdit()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {props.titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.archivo && (
            <div>
              <Button
                className="btn btn-link text-capitalize"
                onClick={() => download(props.archivo)}
              >
                <i class="fas fa-file-pdf mr-1"></i>DESCARGAR
              </Button>
            </div>
          )}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl={props.url}
              httpHeaders={{
                Authorization: "Bearer " + localStorage.token,
              }}
              renderLoader={(percentages: number) => (
                <div style={{ width: "240px" }}>
                  <ProgressBar progress={Math.round(percentages)} />
                </div>
              )}
              renderError={renderError}
              // plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalPdf;
