import React from 'react';
import '../../styles/Formularios.css';

const FormInputCheckbox = (props) => {
    const handleClick = (e) => {
        props.onChecked(e.target.checked);
    };

    return <>
    	<div className="form-group">
        <label  className="checkbox">{props.label}
            <input
                {...props.register(props.id)}
                id={props.id}
                name={props.name}
                type="checkbox"
                className={`form-control ${props.error ? "is-invalid" : ""}`}
                onClick={handleClick}
            />
              <span class="checkmark"></span>
            </label>
            {props.error && <div className="invalid-feedback">{props.error.message}</div>}
        </div>
    </>
};
export default FormInputCheckbox;