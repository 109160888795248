import React from 'react';

class NoData extends React.Component {
	render() {
		return (
      <div className="col-md-12">
				<p><em>El productor que esta consultando no tiene información para esta sección.</em></p>
      </div>
		)
	}
}

export default NoData;
