import React, { useEffect,useState } from "react";
import Api from '../../../../../../../../api/global.service';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../../../../../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../../../../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';

var dataArray = [];
const HerramientasTablasFiltroStateCodeTabla = (props) => {
	
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [toggleCleared] = useState(false);

	const columns = [
		{
			name: 'ID',
			selector: 'id_estatus',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Estatus',
			selector: 'estatus',
			sortable: true,
			right: false
		}
	];



	useEffect(() => {
		obtenerEstatus();
	}, [props.quitarSeleccionados]);

	  const obtenerEstatus = ()  => {
		setDatosFiltrados([
			{
				id_estatus : 0,
				estatus : 'Activo'
			},
			{
				id_estatus : 1,
				estatus : 'Inactivo'
			}
		]);
	  }


	const handleRowSelected = React.useCallback(state => {
		props.onChangeSeleccionados(state.selectedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<BlockUi tag="div" blocking={blocking}>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={''}
			selectableRows
			onSelectedRowsChange={handleRowSelected}
			clearSelectedRows={toggleCleared}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default HerramientasTablasFiltroStateCodeTabla;
