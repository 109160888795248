import React,{useState,useEffect} from 'react';
import { useForm } from "react-hook-form";
import Api from "../../../api/global.service";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../../../nuup/componentes/Forms/FormInput.jsx";
import FormSelectArray from '../../../nuup/componentes/Forms/FormSelectArray';
import FormSelectApi from "../../../nuup/componentes/Forms/FormSelectApi.jsx";
import { Link, useParams } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Modal from "react-bootstrap/Modal";
import UsuariosBusquedaDynamics from "./componentes/UsuariosBusquedaDynamics";
import Swal from "sweetalert2";
import { format, parseISO} from 'date-fns';

const UsuariosRegistrar = () => {
  const { uuid_proveedor = '',uuid_contacto = '',uuid_user = '' } = useParams();
  const [blocking, setBlocking] = useState(false);
  const [show, setShow] = useState(false);
	const [nuevo, setNuevo] = useState(true);
	const [rutas, setRutas] = useState([]);

  const [state, setState] = useState({
    id_user: 0,
    usuarios: [],
    currentUsuario: null,
    currentIndex: -1,
  });
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object().shape({
    uuid_proveedor: yup.string(),
    uuid_contacto: yup.string(),
    uuid_user: yup.string(),
    uuid_account: yup.string().required("UUID ACCOUNT es requerido"),
    id_margarita: yup.string().required("ID Margarita es requerido"),
    id_ugrj: yup.string(),
    rancho: yup.string().required("Rancho es requerido"),
    nombres: yup.string().required("Nombre(s) requerido"),
    apellido_paterno: yup.string().required("Apellido paterno requerido"),
    apellido_materno: yup.string(),
    id_tipo_proveedor: yup
      .number("Seleccione un tipo de proveedor")
      .min(1, "Seleccione un tipo de proveedor")
      .required("Seleccione un tipo de proveedor"),
    id_centro_colecta: yup
      .number("Seleccione un centro de colecta")
      .min(1, "Seleccione un centro de colecta")
      .required("Seleccione un centro de colecta"),
    id_ruta: yup
      .number("Seleccione una ruta")
      .min(1, "Seleccione una ruta")
      .required("Seleccione una ruta"),
    fecha_nacimiento: yup.string(),
    email_notificacion: yup.string(),
    rol: yup
      .number("Seleccione un rol")
      .min(1, "Seleccione un rol")
      .required("Seleccione un rol"),
    nivel: yup
      .number("Seleccione un nivel")
      .min(1, "Seleccione un nivel")
      .required("Seleccione un nivel"),
    email: yup
      .string()
      .email("Correo elecrónico no válido")
      .required("Correo elecrónico no válido"),
    username: yup
      .string()
      .matches(phoneRegExp, "Registro el número telefónico")
      .required(),
    password: yup.string(),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      uuid_proveedor : '',
      uuid_contacto : '',
      uuid_user : '',
      uuid_account : '',
      id_margarita : '',
      id_ugrj : '',
      rancho : '',
      nombres : '',
      apellido_paterno : '',
      apellido_materno : '',
      id_tipo_proveedor : '',
      id_centro_colecta: 0,
      id_ruta: 0,
      fecha_nacimiento : '',
      email_notificacion : '',
      rol: 0,
      nivel: 0,
      email : '',
      username : '',
      password : '',
      passwordConfirmation : ''
    },
  });

  const obtenerRutas = (id_centro_colecta,productor = {}) => {
    setBlocking(true);
    Api.getAll("catalogos/rutas", {id_centro_colecta: id_centro_colecta})
      .then((response) => {
        setBlocking(false);
        setRutas(response.data.data);
        if (typeof productor.id_ruta !== 'undefined') {
          reset(productor);
        }
      }).catch((e) => {
        setBlocking(false);
        console.log(e);
      });
  };


  const obtenerProveedor = () => {
    setBlocking(true);
    Api.get("usuarios/v2/proveedor", uuid_proveedor + '/' + uuid_contacto + '/' + uuid_user)
      .then((response) => {
        let proveedorData = response.data;
        setBlocking(false);
        reset(proveedorData.data);
        obtenerRutas(proveedorData.data.id_centro_colecta,proveedorData.data);
      })
      .catch((e) => {
        setBlocking(false);
        console.log(e);
      });
  };

  const creaProveedor = (values) => {
    setBlocking(true);
    Api.create("usuarios/v2", values)
      .then((response) => {
        setBlocking(false);
        let _result = response.data;
        if (_result.success === 1) {
          Swal.fire({
            icon: "success",
            title: "",
            text: _result.message,
          });
          window.location.href = "/administracion/usuarios";
        } else {
          setBlocking(false);
          Swal.fire({
            icon: "error",
            title: "",
            text: _result.message,
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: e,
        });
      });
  };

  const hideFormEdit = () => {
    setState({ ...state, id_user: 0 });
    setShow(false);
  };

  const actualizaProveedor = (values) => {
    setBlocking(true);
    Api.update("usuarios/v2", uuid_proveedor + '/' + uuid_contacto + '/' + uuid_user, values)
      .then((response) => {
        setBlocking(false);
        let _result = response.data;
        if (_result.success === 1) {
          Swal.fire({
            icon: "success",
            title: "",
            text: _result.message,
          });
          Swal.fire({
            icon: "success",
            title: "",
            text: _result.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "",
            text: _result.message,
          });
        }
      })
      .catch((e) => {
        setBlocking(false);
        Swal.fire({
          icon: "error",
          title: "",
          text: e,
        });
      });
  };

  const onSubmit = (values) => {
    if (uuid_proveedor.length > 0) {
      actualizaProveedor(values);
    } else {
      creaProveedor(values);
    }
  };


	const productorSeleccionado = (productor) => {
    reset({
      uuid_account: productor.accountid,
      id_margarita: productor.tch_idproductor,
      id_ugrj: productor.tch_idproveedor,
      rancho: productor.tch_ranchoID.msdyn_name,
      nombres: productor.tch_nombreprincipalcuenta,
      apellido_paterno: productor.tch_apellidopaterno,
      apellido_materno: productor.tch_apellidomaterno,
      fecha_nacimiento: format(parseISO(productor.tch_fechanacimiento), 'yyyy-MM-dd'),
    });
    setShow(false);
	}

	const buscarDynamics = () => {
    reset({
      uuid_account: '',
      id_margarita: '',
      id_ugrj: '',
      rancho: '',
      nombres: '',
      apellido_paterno: '',
      apellido_materno: '',
      fecha_nacimiento: '',
    });
    setShow(true);
	}


	useEffect(() => {
    if (uuid_proveedor.length > 0) {
      obtenerProveedor();
      setNuevo(false);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


  return (
    <>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/administracion/usuarios">
              Administración de usuarios V2
            </Link>
          </li>
          <li className="breadcrumb-item active">Registrar usuario</li>
        </ol>
        <h1 className="page-header">
          Administración de usuarios V2<small></small>
        </h1>

        <div className="row">
          <div className="col-md-12">
            <Panel>
              <PanelHeader>Información de proveedor</PanelHeader>
              <PanelBody>
                {nuevo && (
                <div className="row">
                  <div className="col-md-3 offset-md-9">
                    <button
                      onClick={() => buscarDynamics()}
                      className="btn btn-warning btn-block"
                    >
                      Buscar en Dynamics
                    </button>
                  </div>
                </div>
                )}

                <BlockUi
                  tag="div"
                  message=""
                  blocking={blocking}
                  renderChildren={true}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-12">
                    <FormInput
                        id="uuid_proveedor"
                        type="hidden"
                        name="uuid_proveedor"
                        label="UUID PROVEEDOR"
                        error={errors.uuid_proveedor}
                        register={register}
                        required
                      />

                    <FormInput
                        id="uuid_contacto"
                        type="hidden"
                        name="uuid_contacto"
                        label="UUID CONTACTO"
                        error={errors.uuid_contacto}
                        register={register}
                        required
                      />

                      <FormInput
                        id="uuid_user"
                        type="hidden"
                        name="uuid_user"
                        label="UUID USER"
                        error={errors.uuid_user}
                        register={register}
                        required
                      />
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#d4d4d4",
                        padding: 10,
                        marginBottom: 10,
                      }}
                    >
                      <h3>Datos Dynamics</h3>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <FormInput
                          id="uuid_account"
                          type="text"
                          name="uuid_account"
                          label="UUID ACCOUNT"
                          error={errors.uuid_account}
                          register={register}
                        />
                      </div>

                      <div className="col-md-3">
                        <FormInput
                          id="id_margarita"
                          type="text"
                          name="id_margarita"
                          label="ID MARGARITA"
                          error={errors.id_margarita}
                          register={register}
                        />
                      </div>

                      <div className="col-md-3">
                        <FormInput
                          id="id_ugrj"
                          type="text"
                          name="id_ugrj"
                          label="ID UGRJ"
                          error={errors.id_ugrj}
                          register={register}
                        />
                      </div>

                      <div className="col-md-3">
                        <FormInput
                          id="rancho"
                          type="text"
                          name="rancho"
                          label="Rancho"
                          error={errors.rancho}
                          register={register}
                        />
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#d4d4d4",
                        padding: 10,
                        marginBottom: 10,
                      }}
                    >
                      <h3>Datos Generales</h3>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col-md-4">
                        <FormInput
                          id="nombres"
                          type="text"
                          name="nombres"
                          label="Nombre"
                          error={errors.nombres}
                          register={register}
                        />
                      </div>

                      <div className="col-md-4">
                        <FormInput
                          id="apellido_paterno"
                          type="text"
                          name="apellido_paterno"
                          label="Apellido Paterno"
                          error={errors.apellido_paterno}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="apellido_materno"
                          type="text"
                          name="apellido_materno"
                          label="Apellido Materno"
                          error={errors.apellido_materno}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <FormSelectApi
                          id="id_tipo_proveedor"
                          name="id_tipo_proveedor"
                          label="Tipo de Proveedor"
                          url="catalogos/tipoproveedores"
                          keyArray="id_tipo_proveedor"
                          labelArray="tipo_proveedor"
                          showLoading={false}
                          error={errors.id_tipo_proveedor}
                          onFinish={() => {}}
                          register={register}
                        />
                      </div>

                      <div className="col-md-4">
                        <FormSelectApi
                          id="id_centro_colecta"
                          name="id_centro_colecta"
                          label="Centro de Colecta"
                          url="catalogos/centros"
                          keyArray="id_centro_colecta"
                          labelArray="centro_colecta"
                          showLoading={false}
                          error={errors.id_centro_colecta}
                          register={register}
                          onFinish={() => {}}
                          onChange={(event) =>
                            obtenerRutas(event.target.value)
                          }
                        />
                      </div>

                      <div className="col-md-4">
                        <FormSelectArray
                          id="id_ruta"
                          name="id_ruta"
                          label="Ruta"
                          keyArray="id_ruta"
                          labelArray="nombre_ruta"
                          error={errors.id_ruta}
                          items={rutas}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <FormInput
                          id="fecha_nacimiento"
                          type="date"
                          name="fecha_nacimiento"
                          label="Fecha Nacimiento"
                          error={errors.fecha_nacimiento}
                          register={register}
                        />
                      </div>

                      <div className="col-md-4">
                        <FormInput
                          id="email_notificacion"
                          type="text"
                          name="email_notificacion"
                          label="Correo Electrónico (Notificaciones)"
                          error={errors.email_notificacion}
                          register={register}
                        />
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#d4d4d4",
                        padding: 10,
                        marginBottom: 10,
                      }}
                    >
                      <h3>Datos de acceso</h3>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col-md-4">
                        <FormSelectApi
                          id="rol"
                          name="rol"
                          label="Rol"
                          url="usuarios/roles"
                          keyArray="id"
                          labelArray="name"
                          error={errors.rol}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormSelectApi
                          id="nivel"
                          name="nivel"
                          label="Nivel"
                          url="catalogos/usuarios/niveles"
                          keyArray="id_nivel"
                          labelArray="nombre_nivel"
                          error={errors.nivel}
                          register={register}
                        />
                      </div>

                      <div className="col-md-4">
                        <FormInput
                          id="email"
                          type="text"
                          name="email"
                          label="Correo Electrónico"
                          error={errors.email}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <FormInput
                          id="username"
                          type="number"
                          name="username"
                          label="Usuario (Teléfono)"
                          error={errors.username}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="password"
                          type="password"
                          name="password"
                          label="Contraseña"
                          error={errors.password}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="passwordConfirmation"
                          type="password"
                          name="passwordConfirmation"
                          label="Contraseña"
                          error={errors.passwordConfirmation}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 offset-md-9">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Guardar proveedor
                        </button>
                      </div>
                    </div>
                  </form>
                </BlockUi>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => hideFormEdit()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Ingrese los datos para la búsqueda
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UsuariosBusquedaDynamics productorSeleccionado={productorSeleccionado} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UsuariosRegistrar;
