import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../../components/panel/panel.jsx";
import PrintButton from "../../../components/print/button";

const TecnicosATAvanzado = () => {
  const embedURL =
    "https://app.powerbi.com/reportEmbed?reportId=8cbfc989-3a73-41ff-961c-6dd79000fb21&autoAuth=true&ctid=35ffa4f7-9b27-405b-a3b7-13d53446ee4f";
  const directURL =
    "https://app.powerbi.com/groups/03a0859f-ae05-4d62-ac36-7a98339e204f/reports/8cbfc989-3a73-41ff-961c-6dd79000fb21/ReportSectiondbf0d54957ea790830a5";
  return (
    <>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item active">
          <Link to="/">Tablero</Link>
        </li>
      </ol>
      <h1 className="page-header">Reporte avanzado</h1>
      <div className="row">
        <div className="col-md-12">
          <Panel className="mb-0">
            <PanelBody className="mb-0">
              <PrintButton directURL={directURL} />
              <div className="powerbi-embedded-report">
                <iframe
                  className="mt-0 print-element"
                  title="Técnicos AT"
                  width="100%"
                  height="100%"
                  src={embedURL}
                  frameBorder="0"
                  allowFullScreen="{true}"
                ></iframe>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
};

export default TecnicosATAvanzado;
