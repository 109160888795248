import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import  '../../../nuup/styles/Tablas.css'
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import ArchivosExportar from './ArchivosExportar';
import ArchivosDetalleExportar from './ArchivosDetalleExportar';
import ModalDetalleVisto from './ModalDetalleVisto';
import conviertArray from '../../../nuup/helpers/conviertArray'; 

var dataArray = [];
const ArchivosEnviarTabla = (props) => {
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [idAviso, setIdAviso] = useState(false);
	const [verDetalles, setVerDetalles] = useState(false);

	const [filtro] = useState({
		usuario : 0,
		categoria : 0,
		ruta : 0
	});

	const columns = [
		{
			name: 'ID',
			selector: 'id_aviso',
			maxWidth: '150px',
			sortable: true,
			right: false
		},

		{
			name: 'TÍTULO',
			selector: 'titulo',
			maxWidth: '350px',
			sortable: true,
			right: false
		},
		{
			name: 'NOTICIA',
			selector: 'aviso',
			maxWidth: '550px',
			sortable: true,
			right: false
		},

		{
			name: 'ENVIADOS / VISTOS',
			sortable: true,
			maxWidth: '150px',
			right: false,
			cell: row => (
				<button onClick={() => abrirDetalle(row.id_aviso)} className={getColorPorcenrtaje(row.porcentaje_visto)}>{row.enviados + ' / ' + row.visto + ' (' + row.porcentaje_visto + '%)'}</button>
			)
		},

		{
			name: 'DESCARGAR DETALLES',
			sortable: false,
			maxWidth: '100px',
			right: false,
			cell: row => (
				<button onClick={() => descargarDetalle(row.id_aviso)} className='btn btn-dark'><i className='fa fa-download'></i></button>
			)
		},

		{
			name: 'FECHA ENVÍO',
			selector: 'created_at',
			maxWidth: '200px',
			sortable: true,
			right: false
		}

	];

	useEffect(() => {
		obtenerArchivos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtro]);

	  const obtenerArchivos = ()  => {
		Swal.showLoading();	
		Api.getAll(ApiUrl.noticias.archivos,filtro)
		  .then(response => {
			Swal.close();
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
		  })
		  .catch(e => {
			Swal.close();
			console.log(e);
		  });
	  }

	  // eslint-disable-next-line no-unused-vars
	  const [selectedRows, setSelectedRows] = React.useState([]);
	     // eslint-disable-next-line no-unused-vars 
	  const [toggleCleared, setToggleCleared] = React.useState(false);
  

	  const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
		props.onChangeSeleccionados(state.selectedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	const abrirDetalle = (id_aviso)  => {
		setVerDetalles(true);
		setIdAviso(id_aviso);
	}

	const descargarDetalle = (id_aviso)  => {
		Api.getAll(ApiUrl.noticias.archivosdetalle + '/' + id_aviso).then(response => {
            let _items = conviertArray(response.data.data);
			ArchivosDetalleExportar(_items);
        }).catch(e => {
			console.log(e);
        });
	}

	const onCerrarModal = (id_aviso)  => {
		setVerDetalles(false);
		setIdAviso(0);
	}

	const getColorPorcenrtaje = (valor)  => {
		let color = 'btn btn-success';
		if(valor >= 0 && valor <= 33){
			color = 'btn btn-danger';
		}else if(valor > 33 && valor <= 70){
			color = 'btn btn-warning';
		}
		return color;
	}


	return (
      <>
		  {/*
			<div className="row">
			  <div className="col-md-8 offset-md-2">
				<div className="row">
					<div className="col-md-3">
						<FiltroUsuarios onChange={(event) => filtrarTabla('usuario',event.target.value)} showLoading={false}  />
					</div>
					<div className="col-md-6">
						<FiltroRutas onChange={(event) => filtrarTabla('ruta',event.target.value)} showLoading={false}  />
					</div>

					<div className="col-md-3">
						<FiltroNotificacionesCategorias onChange={(event) => filtrarTabla('categoria',event.target.value)} showLoading={false}  />
					</div>
				</div>
			  </div>
		  </div>
		  */}

		  <HerramientasTablas 
				txtBusqueda="Buscar archivo" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/noticias/archivos/registrar" 
				columnasFiltrar={["id_aviso","productor","categoria","titulo","aviso","creado"]}
				exportar={ArchivosExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>


			<DataTable
				pagination
				columns={columns}
				data={datosFiltrados}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				onSelectedRowsChange={handleRowSelected}
			/>
         	<ModalDetalleVisto aviso={idAviso} show={verDetalles} onCerrarModal={onCerrarModal}  />
		</>
    );
  };
export default ArchivosEnviarTabla;
