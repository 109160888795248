import xlsx from "json-as-xlsx";
import moment from 'moment';


const ListadoVacasHistorialExportar = (content) => {
    let columns = [
        { label: 'Id Historial  KPI', value: 'id_historial_vacas_kpi' },
        { label: 'Proveedor', value: 'nombre_proveedor' },
        { label: 'ID UGRJ', value: 'id_ugrj' },
        { label: 'ID Vaca', value: 'id_vaca' },
        { label: 'Nombre', value: 'nombre' },
        { label: 'Fecha de cálculo', value: 'fecha' },
        { label: 'Días abiertos', value: 'dias_abiertos' },
        { label: 'Días leche', value: 'dias_leche' },
        { label: 'Intervalo entre partos', value: 'intervalo_partos' }
      ];
      
      let settings = {
        sheetName: 'Historial_Kpis_Vacas',
        fileName: 'Historial_Kpis_Vacas' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default ListadoVacasHistorialExportar;
