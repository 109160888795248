import React, { useState } from "react";
import ApiUrls from "../../../constants/Api";
import DataTable from "react-data-table-component";
import estiloGeneral from "../../../nuup/componentes/Tablas/estiloGeneral";
import "../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
// import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
// import ListadoVacasHistorialExportar from './ListadoVacasHistorialExportar';
import { useFetchWithPaginate } from "../../../nuup/hooks/useFetchWithPaginate";
import { format, parseISO } from "date-fns";

const ListadoHistorialVacas = (props) => {
  const [
    blocking,
    setBlocking,
    datosFiltrados,
    obtenerDatosFiltrados,
    setPage,
    totalRows,
    perPage,
    handlePerRowsChange,
  ] = useFetchWithPaginate(ApiUrls.vacas.historial);

  const columns = [
    {
      name: "Proveedor",
      selector: "nombre_proveedor",
      maxWidth: "250px",
      sortable: true,
      right: false,
    },
    {
      name: "ID UGRJ/ID MARGARITA",
      sortable: true,
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 700 }}>
            {row.id_ugrj != "id proveedor" ? row.id_ugrj : "-"}
          </div>
          {row.id_margarita != "id productor" ? row.id_margarita : "-"}
        </div>
      ),
    },
    {
      name: "Id Vaca",
      selector: "id_vaca",
      maxWidth: "100px",
      sortable: true,
      right: false,
    },
    {
      name: "Nombre",
      selector: "nombre",
      maxWidth: "150px",
      sortable: true,
      right: false,
    },
    {
      name: "Raza",
      selector: "nombre_raza",
      maxWidth: "200px",
      sortable: true,
      right: false,
    },
    {
      name: "Tipo",
      selector: "nombre_tipo_vaca",
      maxWidth: "200px",
      sortable: true,
      right: false,
    },
    {
      name: "Estado Reproductivo",
      selector: "nombre_estado_reproductivo",
      maxWidth: "200px",
      sortable: true,
      right: false,
    },
    {
      name: "Fecha de registro",
      selector: "created_at",
      maxWidth: "200px",
      sortable: true,
      right: false,
      cell: (row) => (
        <div>{format(parseISO(row.created_at), "dd/MM/yyyy")}</div>
      ),
    },
  ];

  return (
    <BlockUi tag="div" blocking={blocking} renderChildren={true}>
      {/*
			<HerramientasTablas 
				txtBusqueda="Buscar usuario" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["nombre_proveedor","id_ugrj","id_margarita","id_vaca","nombre","nombre_raza","nombre_tipo_vaca","nombre_estado_reproductivo","created_at"]}
				exportar={ListadoVacasHistorialExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				funcionFiltrar={filtrarUsuarios}
				filtrarRegistros={false}
				filtros={['productor']}
				filtradoAlert={filtradoAlert}
			/>
			*/}

      <DataTable
        pagination
        columns={columns}
        data={datosFiltrados}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={estiloGeneral}
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={obtenerDatosFiltrados}
        sortServer
      />
    </BlockUi>
  );
};
export default ListadoHistorialVacas;
