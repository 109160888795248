import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../../api/global.service";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../../../../nuup/componentes/Forms/FormInput.jsx";
import Swal from "sweetalert2";

const UsuariosBusquedaDynamics = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object().shape({
    valor: yup.string().required("Seleccione un valor"),
    tipo: yup.string().required("Seleccione un tipo"),
  });

  const [recarga, setRecarga] = useState(0);
  const [btncancelar, setBtncancelar] = useState(false);
  const [productores, setProductores] = useState([]);

  const [state] = useState({
    valor: "",
    tipo: 1,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: state,
  });

  const onSubmit = (values) => {
    Swal.showLoading();
    Api.getAll("dynamics365/buscar/productor", {
      valor: values.valor,
      tipo: values.tipo
    }).then((response) => {
        let _result = response.data;
        if (_result.success === 1) {
          setProductores(_result.data);
          Swal.close();
        } else {
          alert(_result.message);
        }
      }).catch((e) => {
        Swal.fire("Error!", "Error al buscar información del usuario.", "error");
      });
  };

  const onEdituser = (data) => {
    setBtncancelar(true);
    reset({
      id: data.id,
      email: data.email,
      id_contacto: data.id_contacto,
      id_margarita: data.id_margarita,
      nivel: data.nivel,
      rol: data.roles[0].id,
      username: data.username,
      uuid: data.uuid,
    });
  };

  const setDatosProductor = (productor) => {
    props.productorSeleccionado(productor);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <FormInput
              id="valor"
              type="text"
              name="valor"
              label="ID"
              error={errors.valor}
              register={register}
            />
          </div>

          <div className="col-md-6">
          <label>{'Tipo de búsqueda'}</label>
            <select 
                  id='tipo'
                  className={`form-control`}
                  name='tipo'
                  {...register('tipo')}
              >
                  <option value="1">ID Margarita</option>
                  <option value="2">ID UGRJ</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 offset-md-8">
            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-primary btn-block" type="submit">
                  {"Buscar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
        <br />
      {productores.length > 0 && (
        <div className="row">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>accountid</th>
                <th>id_margarita</th>
                <th>id_ugrj</th>
                <th>Rancho</th>
                <th>Nombre</th>
                <th></th>
              </tr>
            </thead>
            <tbody>

              {productores.map((productor) => (
                <tr key={productor.accountid}>
                  <td>{productor.accountid}</td>
                  <td>{productor.tch_idproductor}</td>
                  <td>{productor.tch_idproveedor}</td>
                  <td>{productor.tch_ranchoID.msdyn_name}</td>
                  <td>{productor.tch_nombreprincipalcuenta + ' ' + productor.tch_apellidopaterno + ' ' + productor.tch_apellidomaterno}</td>
                  <td>
                    <button className="btn btn-dark btn-sm" onClick={() => setDatosProductor(productor)}>Usar estos datos</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
export default UsuariosBusquedaDynamics;
