import React, { useEffect } from "react";
import HerramientasTablasFiltroNivelesTabla from "./HerramientasTablasFiltroNivelesTabla";
import setFiltros from "../../Herramientas/setFiltros";
const HerramientasTablasFiltroNiveles = (props) => {
  const { quitarSeleccionados } = props;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quitarSeleccionados]);

  const handleSeleccionados = (_arr) => {
    let _ids = [];
    for (let a = 0; a < _arr.length; a++) {
      _ids.push(_arr[a].id_nivel);
    }
    setFiltros(6, _ids);
  };

  return (
    <>
      <HerramientasTablasFiltroNivelesTabla
        onChangeSeleccionados={handleSeleccionados}
        quitarSeleccionados={quitarSeleccionados}
      />
    </>
  );
};
export default HerramientasTablasFiltroNiveles;
