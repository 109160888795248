import React from "react";
import MisVacasListado from "./MisVacasListado";
const MisVacas = (props) => {
  return (
    <>
      <h3>Mis Vacas</h3>
      {props.id_productor > 0 && (
          <>
            <MisVacasListado id_productor={props.id_productor} />
          </>
      )}
    </>
  );
};
export default MisVacas;
