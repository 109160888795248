import xlsx from "json-as-xlsx";
import moment from 'moment';

const ExportarTablaVacas = (content) => {
    let columns = [
        {label: 'No. Vaca', value: 'no_vaca'},
        {label: 'ID SINIIGA', value: 'id_siniiga'},
        {label: 'ESTADO FISIOL.', value: 'estado_fisiologico'},

        {label: 'FECHA DE PARTO', value: 'fecha_parto'},
        {label: 'NÚMERO DE PARTOS', value: 'no_partos'},

        {label: 'DX', value: 'dx'},
        {label: 'D A', value: 'd_a'},
        {label: 'DEL', value: 'del'},
        // {label: 'fecha_secar', value: 'fecha_secar'},
        {label: 'FECHA DE SECADO', value: 'fecha_secado'},
        // {label: 'dias_secas', value: 'dias_secas'},
        {label: 'FECHA ÚLTIMA INSEMINACIÓN', value: 'fecha_ultima_inseminacion'},
      ];
      
      let settings = {
        sheetName: 'ReporteEdoReproductivo',
        fileName: 'ReporteEdoReproductivo_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default ExportarTablaVacas;
