import React from "react";
import {useHistory} from "react-router-dom";
import {PageSettings} from "./../config/page-settings.js";
import {useAuth} from "../components/content/auth.jsx";

class LoginV3 extends React.Component {
  static contextType = PageSettings;

  state = {
    email: "",
    password: "",
    error: "",
    type: "password",
  };

  constructor(props) {
    super(props);

    this.showHide = this.showHide.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem("sesion");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
    this.context.handleSetBodyWhiteBg(false);
  }


  handleSubmit(event) {
    event.preventDefault();
    this.props.auth.login(this.state).then(response => {
      if (response) {
        this.handleLogin();
      }
    }).catch(error => {
      // console.log(error);
      error.json().then(message => {
        if (message.email != null) {
          this.setState({error: "Introduzca una dirección de correo electrónico válida."})
        } else if (message.password != null) {
          this.setState({error: "La contraseña debe contener al menos 6 caracteres."})
        } else {
          this.setState({error: "Correo electrónico o contraseña incorrectos."})
        }
      });//catching errors to show notification
    });
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  handleLogin() {

    this.props.auth.profile().then((response) => {
      let grupos = JSON.parse(localStorage.grupos);
      if (grupos.length > 1) {
        this.props.history.push("/seleccionar_nivel");
      } else {
        setTimeout(function () {
          window.location.reload();
        }, 2000);
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div
            className="news-image"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1507103011901-e954d6ec0988?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80')",
            }}
          ></div>
          <div className="news-caption">
            <h4 className="caption-title">
              Administrador Tecnoleche
            </h4>
            <p></p>
          </div>
        </div>
        <div className="right-content">
          <div className="login-header">
            <div className="brand">
              {/* <span className="navbar-logo"></span> */}
              <img className="mb-4" src="../assets/images/tecnoleche-logo.png" alt="Tecnoleche logo"></img>
              <h1>Inicio de sesión</h1>
            </div>
            <div className="icon">
              <i className="fa fa-sign-in"></i>
            </div>
          </div>
          <div className="login-content">
            <form
              className="margin-bottom-0"
              id="frmLogin"
              onSubmit={this.handleSubmit}
            >
              <div className="form-group m-b-15">
                <input
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={(e) => this.setState({email: e.target.value})}
                  className="form-control form-control-lg"
                  placeholder="Correo electrónico"
                  required
                />
              </div>


              <div className="row row-space-10">
                <div className="col-md-11 m-b-15">
                  <input
                    type={this.state.type}
                    name="password"
                    value={this.state.password}
                    onChange={(e) => this.setState({password: e.target.value})}
                    className="form-control form-control-lg"
                    placeholder="Contraseña"
                    required
                  />
                </div>
                <div className="col-md-1 m-b-15" style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <button
                    type="button"
                    className="btn btn-sm"
                    onClick={this.showHide}
                  >
                    {this.state.type === 'input' ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                  </button>
                </div>
              </div>

              <div className="form-group m-b-15">
                <span style={{color: "red"}}>{this.state.error}</span>
              </div>

              {/* <div className="checkbox checkbox-css m-b-30">
                <input type="checkbox" id="remember_me_checkbox" value="" />
                <label htmlFor="remember_me_checkbox">Recuerdame</label>
              </div> */}
              <div className="login-buttons">
                <button
                  type="submit"
                  className="btn btn-teal btn-block btn-lg"
                >
                  Iniciar sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

//export default withRouter(LoginV3);
export default (props) => {
  const auth = useAuth();
  const history = useHistory();
  return <LoginV3 auth={auth} history={history} {...props} />;
};
