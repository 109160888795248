import React, { useState,useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../../api/global.service';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../../nuup/componentes/Forms/FormInput.jsx';
import FormInputCheckbox from '../../../../nuup/componentes/Forms/FormInputCheckbox.jsx';
import formatDateMoment from '../../../../nuup/helpers/formatDateMoment.jsx';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import AvisosEnviarArchivosTabla from './AvisosEnviarArchivosTabla';
import { useParams } from "react-router-dom";
import procesarCola from '../../../../nuup/helpers/procesarCola';

const AvisosEnviarForm = (params) => {
	const { id_aviso_archivo } = useParams();
	const [seleccionados,setSeleccionados] = useState([]);
	const [tipoEnvio,setTipoEnvio] = useState('');
	const [tipoEnvioEtiqueta,setTipoEnvioEtiqueta] = useState('');
	const [ocultaEmail,setOcultaEmail] = useState(true);
	
	const [state,setState] = useState({
		id_aviso_archivo : id_aviso_archivo,
		titulo : '',
		aviso : '',
		desc : '',
		ruta : '',
		reporte : '',
		envioapp : 'Solo se enviará el reporte a la aplicación móvil',
		enviomail : 'Se enviará el reporte a la aplicación móvil y al correo electrónico de notificación',
	});

	const schema = yup.object().shape({
		id_aviso_archivo : yup.number('Seleccione un tipo de documento').min(1,'Seleccione un tipo de documento').required('Seleccione un tipo de documento'),
		titulo : yup.string('Introduzca un título').required('Introduzca un título'),
		aviso : yup.string('Introduzca el aviso').required('Introduzca el aviso'),
		desc : yup.string('Introduzca la descripción').required('Introduzca la descripción')
	});

	const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: {
			id_aviso_archivo : id_aviso_archivo,
			titulo : '',
			aviso : '',
			desc : '',
			ruta : '',
			reporte : ''
		}
	});


	useEffect(() => {
		setTipoEnvioEtiqueta(state.envioapp);
		setTipoEnvio(1);
		obtenerInfoArchivo();
		    // eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const obtenerInfoArchivo = ()  => {
		Swal.showLoading();	
		Api.getAll('notificaciones/archivos/listado',{id_aviso_archivo})
		.then(response => {
			Swal.close();
			if(response.data.success === 1 && response.data.data.length){
				 setValue('titulo' ,response.data.data[0].tipodocumento.aviso_categoria);
				 setValue('fecha_inicio' ,response.data.data[0].fecha_inicio);
				 setValue('fecha_fin' ,response.data.data[0].fecha_fin);

				 handleChange();
				}else{

				}
		  })
		  .catch(e => {
			Swal.close();
			console.log(e);
		  });
	}





	const onSubmit = data => {
		setState(data);
		enviarNotificaciones(data);
	}

	const handleSeleccionados = (_arr) => {
		let _ids = [];
		for (let a = 0; a < _arr.length; a++) {
			_ids.push(_arr[a].id);			
		}
        setSeleccionados(_ids);
    }

	const enviarNotificaciones = (data) => {
		if(seleccionados.length > 0){
			Swal.showLoading();
			Api.create('notificaciones/enviar',{
				id_aviso_archivo : id_aviso_archivo,
				ids : seleccionados,
				titulo : data.titulo,
				aviso : data.aviso,
				desc : data.desc,
				fecha_inicio : data.fecha_inicio,
				fecha_fin : data.fecha_fin,
				tipo_envio : tipoEnvio,
			}).then(response => {
				// Procesar cola
				let _result = response.data;
				if(_result.success === 1){
					procesarCola();
					Swal.close();
					Swal.fire(
						'Enviado!',
						'Notificaciones enviadas.',
						'success'
					);
				}
			}).catch(e => {
				console.log(e);
				Swal.fire(
				'Error!',
				'Error al enviar notificaciones.',
				'error'
				);
			});
			
		}else{
			Swal.fire(
				'Error',
				'Seleccione al menos un registro para enviar',
				'question'
			  )
			
		}


	};

	const handleChange = () => {
		setValue('aviso' ,formatDateMoment(getValues('fecha_inicio')) + ' al ' + formatDateMoment(getValues('fecha_fin')));
		setValue('desc' ,formatDateMoment(getValues('fecha_inicio')) + ' al ' + formatDateMoment(getValues('fecha_fin')));
	}

	const enviarEmail = (valor) => {
		if(valor){
			setTipoEnvio(2);
			setTipoEnvioEtiqueta(state.enviomail);
			setOcultaEmail(false);
		}else{
			setTipoEnvio(1);
			setTipoEnvioEtiqueta(state.envioapp);
			setOcultaEmail(true);
		}
	}	

	return (
		<>
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Listado de archivos</li>
      </ol>
      <h1 className="page-header">Envios de reportes <small></small></h1>
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-6">
					<FormInput 
						id="id_aviso_archivo"
						type="hidden"
						name="id_aviso_archivo"
						label="ID"
						error={errors.id_aviso_archivo}
						register={register}
						/>
						</div>
					</div>

				<hr />
				<div className="row">
					<div className="col-md-6">
					<FormInput 
						id="fecha_inicio"
						type="text"
						name="fecha_inicio"
						label="Fecha Inicial"
						error={errors.fecha_inicio}
						register={register}
						onChange={handleChange}
						readOnly={true}
						/>
					</div>
					<div className="col-md-6">
					<FormInput 
						id="fecha_fin"
						type="text"
						name="fecha_fin"
						label="Fecha Final"
						error={errors.fecha_fin}
						register={register}
						onChange={handleChange}
						readOnly={true}
						/>
					</div>
				</div>

				<hr />
				<div className="row">
					<div className="col-md-4">
					<FormInput 
						id="titulo"
						type="text"
						name="titulo"
						label="Título"
						error={errors.titulo}
						register={register}
						/>
					</div>
					<div className="col-md-4">
					<FormInput 
						id="aviso"
						type="text"
						name="aviso"
						label="Aviso (Se mostrará en el listado de notificaciones)"
						error={errors.aviso}
						register={register}
						/>
					</div>

					<div className="col-md-4">
						<FormInput 
							id="desc"
							type="text"
							name="desc"
							label="Descripción (Se mostrará en la notificación Push)"
							error={errors.desc}
							register={register}
						/>
					</div>

				</div>


				<div className="row">
					<div className="col-md-12">
						<FormInputCheckbox
							id="email_notificacion"
							name="email_notificacion"
							label={tipoEnvioEtiqueta}
							error={errors.email_notificacion}
							register={register}
							onChecked={(value) => enviarEmail(value)}
							/>
	
						</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<AvisosEnviarArchivosTabla ocultaEmail={ocultaEmail} onChangeSeleccionados={handleSeleccionados} data={state} />			
					</div>
				</div>


				<button className="btn btn-primary btn-block" type="submit">Enviar notificaciones</button>
			</form>

			</PanelBody>
			</Panel>
			</div>
		</>
	);
};
export default AvisosEnviarForm;
