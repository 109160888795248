import React from "react";
const PrintButton = ({ directURL }) => (
  <button
    onClick={() => window.open(directURL)}
    className="btn btn-outline-secondary mb-2"
    style={{ borderRadius: 20 }}
    type="button"
  >
    <i className="fa fa-print" aria-hidden="true"></i> Imprimir
  </button>
);

export default PrintButton;
