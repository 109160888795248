import xlsx from "json-as-xlsx";
import moment from 'moment';
const ArchivosDetalleExportar = (content) => {
   let columns = [
        { label: 'ID Archivo', value: 'id_aviso' },
        { label: 'Título', value: 'titulo' },
        { label: 'Aviso', value: 'aviso' },
        { label: 'Nombre', value: 'nombre' },
        { label: 'Apellido Paterno', value: 'apellido_paterno' },
        { label: 'Apellido Materno', value: 'apellido_materno' },
        { label: 'Fecha Envio', value: 'enviado' },
        { label: 'Visto', value: 'visto' },
        { label: 'Visto Fecchas', value: 'visto_fecha' }
      ];
      
      let settings = {
        sheetName: 'Noticias_archivos_detalle',
        fileName: 'Noticias_archivos_detalle_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default ArchivosDetalleExportar;