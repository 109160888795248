import React from "react";
import TecnicoAsociarProductoresListado from "./TecnicoAsociarProductoresListado";
const TecnicoAsociarProductores = (props) => {
  return (
    <>
      {props.id_tecnico > 0 && (
          <>
            <TecnicoAsociarProductoresListado uuid_tecnico={props.uuid_tecnico} id_tecnico={props.id_tecnico} />
          </>
      )}
    </>
  );
};
export default TecnicoAsociarProductores;
