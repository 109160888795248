import React, { useEffect,useState } from "react";
import Api from '../../../../../api/global.service';
import ApiUrls from '../../../../../constants/Api';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import getUrlFiltros from '../../../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import ListadoSeguimientosExportar from './ListadoSeguimientosExportar';
var dataArray = [];
const ListadoSeguimientos = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const columns = [
		{
			name: 'Proveedor',
			selector: 'nombre_proveedor',
			sortable: true,
			right: false
		},
		{
			name: 'ID UGRJ',
			selector: 'id_ugrj',
			sortable: true,
			right: false
		},
		{
			name: 'ID Margarita',
			selector: 'id_margarita',
			sortable: true,
			right: false
		},
		{
			name: 'Proveedor',
			selector: 'nombre_proveedor',
			sortable: true,
			right: false
		},
		{
			name: 'Rancho',
			selector: 'rancho',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Vaca',
			selector: 'vaca',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'Evento',
			selector: 'evento',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'Evento detalle',
			selector: 'evento_detalle',
			maxWidth: '175px',
			sortable: true,
			right: false
		},
		{
			name: 'Evento fecha',
			selector: 'evento_fecha',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'Notificación',
			selector: 'notificacion',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'Fecha Notificación',
			selector: 'fecha_notificacion',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'Pregunta',
			selector: 'pregunta',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'Completada',
			selector: 'completada',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'Fecha Completada',
			selector: 'completada_fecha',
			maxWidth: '150px',
			sortable: true,
			right: false
		}

	];

	useEffect(() => {
		obtenerSeguimiento();
	}, []);

	  const obtenerSeguimiento = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,ApiUrls.vacas.eventos.seguimiento.detalles)).then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		}).catch(e => {
			setBlocking(false);
		});
	  }

	const filtrarUsuarios = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerSeguimiento(true);
		}else{
			obtenerSeguimiento();
		}
	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	const conditionalRowStyles = [
		{
		  when: row => row.toggleSelected,
		  style: {
			backgroundColor: "#dadada",
			userSelect: "none"
		  }
		}
	  ];

	return (
		<BlockUi tag="div" blocking={blocking}>
	
			<HerramientasTablas 
				txtBusqueda="Buscar usuario" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["nombre_proveedor","vaca","evento","evento_detalle","evento_fecha"]}
				exportar={ListadoSeguimientosExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				funcionFiltrar={filtrarUsuarios}
			/>

          <DataTable
            pagination
			paginationPerPage={15}
            columns={columns}
            data={datosFiltrados}
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
			conditionalRowStyles={conditionalRowStyles}
          />

		</BlockUi>
    );
  };
export default ListadoSeguimientos;
