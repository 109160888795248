import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrls from '../../constants/Api';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import getUrlFiltros from '../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import ListadoVacasExportar from './ListadoVacasExportar';

var dataArray = [];
const ListadoVacas = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [filtradoAlert, setFiltradoAlert] = useState(false);

	const columns = [
		{
			name: 'Proveedor',
			selector: 'nombre_proveedor',
			maxWidth: '250px',
			sortable: true,
			right: false
		},
		{
			name: 'ID UGRJ/ID MARGARITA',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.id_ugrj}</div>{row.id_margarita}</div>
		},
		{
			name: 'Rancho',
			selector: 'rancho',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Id Vaca',
			selector: 'id_vaca',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Nombre',
			selector: 'nombre',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'Raza',
			selector: 'nombre_raza',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'Tipo',
			selector: 'nombre_tipo_vaca',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'Estado Reproductivo',
			selector: 'nombre_estado_reproductivo',
			maxWidth: '200px',
			sortable: true,
			right: false
		},
		{
			name: 'Fecha de registro',
			selector: 'created_at',
			maxWidth: '200px',
			sortable: true,
			right: false
		}
	];

	useEffect(() => {
		obtenerSeguimiento();
	}, []);

	  const obtenerSeguimiento = (filtrado = false)  => {
		setBlocking(true);
		Api.getAll(getUrlFiltros(filtrado,ApiUrls.vacas.listado)).then(response => {
			dataArray = response.data.data;
			setDatosFiltrados(dataArray);
			setBlocking(false);
		}).catch(e => {
			setBlocking(false);
		});
	  }

	const filtrarUsuarios = () => {
		// Validar que existan elementos seleccionados
		if(localStorage.getItem('filtro_tipo') > 0 && localStorage.getItem('filtro_valor').length > 0){
			obtenerSeguimiento(true);
		}else{
			obtenerSeguimiento();
		}
	};


	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	const conditionalRowStyles = [
		{
		  when: row => row.toggleSelected,
		  style: {
			backgroundColor: "#dadada",
			userSelect: "none"
		  }
		}
	  ];

	return (
		<BlockUi tag="div" blocking={blocking}>

			<HerramientasTablas 
				txtBusqueda="Buscar usuario" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["nombre_proveedor","id_ugrj","id_margarita","rancho","id_vaca","nombre","nombre_raza","nombre_tipo_vaca","nombre_estado_reproductivo","created_at"]}
				exportar={ListadoVacasExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
				funcionFiltrar={filtrarUsuarios}
				filtrarRegistros={true}
				filtros={['productor']}
				filtradoAlert={filtradoAlert}
			/>

          <DataTable
            pagination
			paginationPerPage={15}
            columns={columns}
            data={datosFiltrados}
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
			conditionalRowStyles={conditionalRowStyles}
          />

		</BlockUi>
    );
  };
export default ListadoVacas;
