import React,{useState,useEffect} from 'react';
import Api from '../../api/global.service';
import ApiUrls from '../../constants/ApiTecnicos';
import { format, parseISO, differenceInMinutes} from 'date-fns';

const UltimaVezVisto = () => {
    useEffect(() => {
        let registrarAcceso = false;
        const today = new Date();
        const fechaActual = format(parseISO(today.toISOString()), 'yyyy-MM-dd hh:mm:ss');
        let ultimoAcceso =  localStorage.getItem('ultimoAcceso');
        if(ultimoAcceso === 'null'){localStorage.setItem('ultimoAcceso',fechaActual);}
        if(ultimoAcceso !== null){
            let diferenciaMinutos = differenceInMinutes(new Date(fechaActual),new Date(ultimoAcceso));
            if(diferenciaMinutos == 0 || diferenciaMinutos > 60){
                registrarAcceso = true;
                if(diferenciaMinutos > 60){
                    localStorage.setItem('ultimoAcceso',fechaActual);
                }
            }else{
                registrarAcceso = false;
            }
        }else{
            localStorage.setItem('ultimoAcceso',fechaActual);
            registrarAcceso = true;
        }
        if(registrarAcceso === true){
            Api.create(ApiUrls.accesos,{sistema : 'consola'}).then(response => {
                //console.log(response);
            }).catch(e => {
                console.log(e);
            });
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return <></>
};
export default UltimaVezVisto;