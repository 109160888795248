import React, { useEffect,useState } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../components/panel/panel.jsx";
import Api from '../../../../api/global.service';
import Swal from "sweetalert2";

const DynamicsCalidadesInfoMysql = (props) => {
  const [data, setData] = useState(false);

  useEffect(() => {
    obtenerInfo({calidad_uuid : props.calidad_uuid});
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.calidad_uuid]);
  
  const obtenerInfo = (params)  => {
    Swal.showLoading();	
    Api.getAll('dynamics365/buscar/calidad/proceso',params)
      .then(response => {
      Swal.close();
      setData(response.data.data);
      })
      .catch(e => {
      Swal.close();
      console.log(e);
      });
    }


  return (
    <Panel>
      <PanelHeader>Información proceso sincronización</PanelHeader>
      <PanelBody>
            {data && (
              <>
              <div className="row">
            <div className="col-md-12">

              <div style={{backgroundColor : '#d4d4d4',padding : 10,fontSize : 18,fontWeight : 'bold'}} > Tablas temporales </div>

              </div>
              </div>

              <div className="row">
            <div className="col-md-12">

              <h5 style={{color : data.calidades_folios !== null ? 'green' : 'red'}}>365_calidades_folios</h5>

              {data.calidades_folios !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UUID</th>
                  <th>Folio</th>
                  <th>Estado</th>
                  <th>Version</th>
                  <th>Completado</th>
                  <th>Migrado</th>
                  <th>Fecha primera_ejecucion</th>
                  <th>Fecha ultima_ejecucion</th>
                  <th>Fecha creación Dynamics</th>
                  <th>Fecha creación MySQL</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.calidades_folios.uuid}>
                <td>{data.calidades_folios.id_365_calidad_folio}</td>
                <td>{data.calidades_folios.uuid}</td>
                <td>{data.calidades_folios.folio}</td>
                <td>{data.calidades_folios.estado}</td>
                <td>{data.calidades_folios.version}</td>
                <td>{data.calidades_folios.completado}</td>
                <td>{data.calidades_folios.migrado}</td>
                <td>{data.calidades_folios.fecha_primera_ejecucion}</td>
                <td>{data.calidades_folios.fecha_ultima_ejecucion}</td>
                <td>{data.calidades_folios.createdon_dynamics}</td>
                <td>{data.calidades_folios.created_at}</td>
                </tr>
              </tbody>
            </table>
            )}
              <hr />

              </div>
              </div>

              <div className="row">
            <div className="col-md-12">

              <h5 style={{color : data.calidades_temp !== null ? 'green' : 'red'}}>365_calidades</h5>

          {data.calidades_temp !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UUID</th>
                  <th>ID Margarita / ID UGRJ / Tanque</th>
                  <th>Fecha</th>
                  <th>Grasas</th>
                  <th>Proteinas</th>
                  <th>Células somáticas</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.calidades_temp.uuid + "_1"}>
                <td>{data.calidades_temp.id_365_calidad}</td>
                <td>{data.calidades_temp.uuid}</td>
                <td>{data.calidades_temp.id_margarita + ' / ' + data.calidades_temp.id_ugrj + ' / ' + data.calidades_temp.tanque}</td>
                <td>{data.calidades_temp.fecha}</td>
                <td>{data.calidades_temp.grasas}</td>
                <td>{data.calidades_temp.proteinas}</td>
                <td>{data.calidades_temp.celulas_somaticas}</td>
                <td>{data.calidades_temp.created_at}</td>
                </tr>
              </tbody>
            </table>
              )}

              <hr />

              </div>
              </div>
              
              <div className="row">
            <div className="col-md-12">
              <div style={{backgroundColor : '#d4d4d4',padding : 10,fontSize : 18,fontWeight : 'bold'}} > Tablas Producción </div>
              </div>
              </div>

              <div className="row">
            <div className="col-md-12">
            <h5 style={{color : data.calidades !== null ? 'green' : 'red'}}>calidades</h5>
              {data.calidades !== null && (
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UUID</th>
                  <th>ID Margarita / ID UGRJ / Tanque</th>
                  <th>Fecha</th>
                  <th>Grasas</th>
                  <th>Proteinas</th>
                  <th>Células somáticas</th>
                  <th>created_at</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data.calidades.uuid + "_1"}>
                <td>{data.calidades.id_calidad}</td>
                <td>{data.calidades.uuid}</td>
                <td>{data.calidades.id_margarita + ' / ' + data.calidades.id_ugrj + ' / ' + data.calidades.tanque}</td>
                <td>{data.calidades.fecha}</td>
                <td>{data.calidades.grasas}</td>
                <td>{data.calidades.proteinas}</td>
                <td>{data.calidades.celulas_somaticas}</td>
                <td>{data.calidades.created_at}</td>
                </tr>
              </tbody>
            </table>
            )}
             </div>
              </div>

            </>
            )}

      </PanelBody>
    </Panel>
  );
};
export default DynamicsCalidadesInfoMysql;
