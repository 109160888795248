import React, { useEffect,useState,useMemo } from "react";
import Api from '../../../../api/global.service';
import DataTable,{defaultThemes } from 'react-data-table-component';
import Swal from "sweetalert2";
import  '../../../../nuup/styles/Tablas.css'
import ModalPdf from '../../../../nuup/componentes/Generales/ModalPdf';
import ModalSubirPdf from '../../../../nuup/componentes/Generales/ModalSubirPdf';

const AvisosEnviarArchivosTabla = (props) => {
	const [subirpdf, setSubirpdf] = useState(false);
	const [infousuario, setInfousuario] = useState([]);
	const [verpdf, setVerpdf] = useState(false);
	const [urlpdf, setUrlpdf] = useState(false);
	const [data, setData] = useState({});

	const columns = useMemo(() => [
		{
			name: 'ID',
			selector: 'id',
			sortable: true,
			right: false
		},
		{
			name: 'NOMBRE',
			sortable: true,
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.firstname}</div>{row.lastname1 + row.lastname2}</div>
		},
		{
			name: 'ID MARGARITA',
			selector: 'id_margarita',
			sortable: true,
			right: false
		},
		{
			name: 'ID UGRJ',
			selector: 'id_ugrj',
			sortable: true,
			right: false
		},

		{
			name: 'EMAIL (ENVÍO DE REPORTE)',
			selector: 'email_notification',
			sortable: true,
			right: false,
			omit: props.ocultaEmail,
		},

		{
			name: 'ARCHIVO A ENVIAR',
			maxWidth: '50px',
			sortable: true,
			right: false,
			cell: row => <div>{(row.archivo.length > 0) ? <><a className="btn btn-xs btn-info" onClick={() => abrirPdf(row.ruta,row.archivo)}><i className="fa fa-file"></i></a></> : <></> }</div>
		},

	], [props.ocultaEmail]);


	const rowSelectCritera = row => row.archivo.length === 0;


	const conditionalRowStyles = [
		{
		  when: row => row.archivo === '',
		  style: {
			backgroundColor: 'rgba(215, 44, 44, 0.4)',
			color: 'black',
			'&:hover': {
			  cursor: 'pointer',
			},
		  },
		},
		{
			when: row => row.archivo !== '',
			style: {
			  backgroundColor: 'rgba(63, 195, 128, 0.4)',
			  color: 'black',
			  '&:hover': {
				cursor: 'pointer',
			  },
			},
		  }
		
	  ];


	useEffect(() => {
		console.log('useEffect');
		console.log(props.data);
		if(props.data.id_aviso_archivo > 0){
			obtenerArchivosUsuarios();
		}
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data]);

	  const obtenerArchivosUsuarios = ()  => {
		Swal.showLoading();	
		Api.getAll('notificaciones/listadoarchivosusuarios',{
			id_aviso_archivo : props.data.id_aviso_archivo,
			ano : props.data.ano,
			mes : props.data.mes,
			semana : props.data.semana
		}).then(response => {
			Swal.close();
			setData(response.data.data);
		  })
		  .catch(e => {
			Swal.close();
			console.log(e);
		  });
	  }

	  

	// eslint-disable-next-line no-unused-vars
	const [selectedRows, setSelectedRows] = React.useState([]);
	// eslint-disable-next-line no-unused-vars
	const [toggleCleared, setToggleCleared] = React.useState(false);

	
	const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
		props.onChangeSeleccionados(state.selectedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	
	const abrirPdf = (ruta,archivo) => {
		let url = process.env.REACT_APP_API_URL + '/api/' + ruta + archivo;
		setUrlpdf(url);
		setVerpdf(true);
	}
	
	const subirPdf = (row) => {
		setInfousuario(row);
		setSubirpdf(true);
	}
	

	const onCerrarPdf = () => {
		setVerpdf(false);
	}


	const onCerrarSubirPdf = () => {
		setSubirpdf(false);
	}

	const customStyles = {
		cells: {
		  style: {
			'&:not(:last-of-type)': {
			  borderRightStyle: 'solid',
			  borderRightWidth: '1px',
			  borderRightColor: defaultThemes.default.divider.default,
			},
		  },
		},
	  };


	return (
      <div>
        <div className="float-md-right" role="group" aria-label="Button group with nested dropdown">
        </div>
		{data.length > 0 && (
		<>
		  <DataTable
            pagination
            columns={columns}
            data={data}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={customStyles}
			conditionalRowStyles={conditionalRowStyles}
			selectableRows
			onSelectedRowsChange={handleRowSelected}
			clearSelectedRows={toggleCleared}
			selectableRowDisabled={rowSelectCritera}
          />
			<ModalPdf show={verpdf} url={urlpdf} titulo="Archivo enviado" onCerrarPdf={onCerrarPdf} />
			<ModalSubirPdf show={subirpdf} data={infousuario}  onCerrarSubirPdf={onCerrarSubirPdf} />
	
		</>
		)}
		</div>
    );
  };
export default AvisosEnviarArchivosTabla;
