import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Day from "./Day";

import Buttons from "./Buttons";
import { getCurrentDateDispatch, getEventsFromLS } from "../actions/actionCreatorsDispatch"

import moment from 'moment';

const Calendar = () => {
  const body = document.getElementsByTagName("body");
  const dispatch = useDispatch();
  const calendarContext = useSelector(state => state.calendarState);

  const {
    currentMonth,
    currentYear,
    days,
  } = calendarContext;



  useEffect(() => {
    dispatch(getCurrentDateDispatch(moment().year(), moment().month() + 1, moment().date()));
    dispatch(getEventsFromLS());
  }, [dispatch]);


  return (
    <div className="calendar">
      <div className="title">
        {moment.months(currentMonth - 1)} {currentYear}{" "}
        <Buttons />
      </div>
      <div className="calendar-table">
        <div className="thead">
          <div>Lunes</div>
          <div>Martes</div>
          <div>Miercoles</div>
          <div>Jueves</div>
          <div>Viernes</div>
          <div>Sabado</div>
          <div>Domingo</div>
        </div>
        <div className="thead-sm">
          <div>L</div>
          <div>M</div>
          <div>M</div>
          <div>J</div>
          <div>V</div>
          <div>S</div>
          <div>D</div>
        </div>
        <div className="tbody">
          {days.map((day, index) => (
            <Day key={index} day={day} />
          ))}
        </div>
      </div>

    </div>
  );
};

export default Calendar;
