import React, { useEffect, useState } from "react";
import Api from "../../../../api/global.service";
import DataTable from "react-data-table-component";
import "../../../../nuup/styles/Tablas.css";
import HerramientasTablas from "../../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas";
import getUrlFiltros from "../../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros";
import estiloGeneral from "../../../../nuup/componentes/Tablas/estiloGeneral";
import BlockUi from "react-block-ui";

var dataArray = [];
const WhatsappTabla = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [seleccionado, setSeleccionado] = useState({});

  const columns = [
    {
      name: "MENSAJE",
      selector: "mensaje",
      maxWidth: "300px",
      sortable: true,
      right: false,
    },
    {
      name: "FECHA",
      selector: "created_at",
      maxWidth: "200px",
      sortable: true,
      right: false,
    },
  ];

  useEffect(() => {
    obtenerReportes();
  }, []);

  useEffect(() => {
    if (seleccionado > 0) {
      props.avisoSeleccionado(seleccionado);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionado]);

  const handleRowClicked = (row) => {
    const updatedData = datosFiltrados.map((item) => {
      if (row.id_aviso_whatsapp !== item.id_aviso_whatsapp) {
        return {
          ...item,
          toggleSelected: false,
        };
      } else {
        setSeleccionado(item.id_aviso_whatsapp);
        return {
          ...item,
          toggleSelected: true,
        };
      }
    });

    setDatosFiltrados(updatedData);
  };

  const obtenerReportes = (filtrado = false) => {
    setBlocking(true);
    Api.getAll(getUrlFiltros(filtrado, "avisos/whatsapp"))
      .then((response) => {
        dataArray = response.data.data;
        setDatosFiltrados(dataArray);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
        console.log(e);
      });
  };

  const handleChange = (datosFiltrados) => {
    setDatosFiltrados(datosFiltrados);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "#dadada",
        userSelect: "none",
      },
    },
  ];

  return (
    <BlockUi tag="div" blocking={blocking}>
      <HerramientasTablas
        txtBusqueda="Buscar mensaje"
        datosTabla={dataArray}
        urlRegistrar=""
        columnasFiltrar={["id_aviso_whatsapp", "mensaje", "created_at"]}
        exportar={false}
        onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
        funcionFiltrar={false}
      />
      <DataTable
        pagination
        columns={columns}
        data={datosFiltrados}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={estiloGeneral}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
      />
    </BlockUi>
  );
};
export default WhatsappTabla;
