import React from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../components/panel/panel.jsx";

const DynamicsLiquidacionesInfo365 = (props) => {
  return (
    <Panel>
      <PanelHeader>Información Dynamics</PanelHeader>
      <PanelBody>
        <div className="row">
          <div className="col-md-12">
            <div style={{backgroundColor : '#d4d4d4',margin : 10,padding : 10,fontSize : 18,fontWeight : 'bold'}} > liquidaciones </div>
         
            <table className="table table-bordered">
            <thead>
              <tr>
				        <th>Folio</th>
                <th>ID UGRJ</th>
                <th>Productor</th>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Estatus</th>
                <th>Total</th>
                <th>Total con impuestos</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr key={props.liquidacion.No}>
                <td>{props.liquidacion.No}</td>
                <td>{props.liquidacion.Buy_from_Vendor_No}</td>
                <td>{props.liquidacion.Buy_from_Vendor_Name}</td>
                <td>{props.liquidacion.Posting_Date}</td>
                <td>{props.liquidacion.Document_Type}</td>
                <td>{props.liquidacion.Status}</td>
                <td className={(props.liquidacion.Amount === 0) ? 'bg-danger' : ''}>{props.liquidacion.Amount}</td>
                <td className={(props.liquidacion.Amount_Including_VAT === 0) ? 'bg-danger' : ''}>{props.liquidacion.Amount_Including_VAT}</td>

                <td>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>

      </PanelBody>
    </Panel>
  );
};
export default DynamicsLiquidacionesInfo365;
