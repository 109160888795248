import React, { useEffect, useState } from "react";
import Api from "../../../../../../api/global.service";
import ApiUrls from "../../../../../../constants/Api";
import "../../../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
var dataArray = [];
const TablaDatosMiLeche = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {

    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.infogeneral.minegocio.micalidad, {
        id_margarita: props.id_margarita,
        id_ugrj: props.id_ugrj,
        tipo: props.tipo,
      })
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    if (props.id_productor > 0) {
      obtenerInfo();
    }

       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_productor]);



  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
        <div className="col-md-12">
          <h5>{props.titulo}</h5>
          {datos[0] &&
          <table className="table table-striped table-bordered">
            <tr>
              <th>Fecha</th>
              <th>Total</th>
            </tr>
              {datos.map((dato) => (
                <tr>
                  <td>{dato.desc}</td>
                  <td>
                  <strong>{(dato.data.grasas > 0) ? 'Grasas: ' : ''}</strong> {dato.data.grasas} <br />
                  <strong>{(dato.data.proteinas > 0) ? 'Proteinas: ' : ''}</strong> {dato.data.proteinas} <br />
                  <strong>{(dato.data.celulas_somaticas > 0) ? 'Células Somáticas: ' : ''}</strong> {dato.data.celulas_somaticas}
                  </td>
                </tr>
              ))}
          </table>
          }
        </div>
      </div>
    </BlockUi>
  );
};
export default TablaDatosMiLeche;
