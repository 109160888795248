import xlsx from "json-as-xlsx";
import moment from 'moment';

const WhatsappExportar = (content) => {
    let columns = [
        { label: 'ID', value: 'id_aviso_whatsapp' },
        { label: 'Productor', value: row => (row.nombre_proveedor) },
        { label: 'Mensaje', value: 'mensaje' },
        { label: 'Fecha Envío', value: 'creado' }
      ];
      
      let settings = {
        sheetName: 'Avisos_Whatsapp',
        fileName: 'Avisos_Whatsapp_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default WhatsappExportar;
