import React from "react";

const DynamicsEncuestasListado = (props) => {


  const setEncuesta = (encuesta) => {
    props.encuestaSeleccionada(encuesta);
  };



  const validaEncuesta = (encuesta,tipo) => {
    let className = "table-success";
    let text = "";

    // Falta información en la encuesta
    if(encuesta.tch_costoporlitroalimentacion === null || encuesta.tch_costoporlitroproducido === null || encuesta.tch_egresototalmes === null){
      text += "Encuesta: ";
      if(encuesta.tch_costoporlitroalimentacion === null){
        text += "tch_costoporlitroalimentacion, ";
      }

      if(encuesta.tch_costoporlitroproducido === null){
        text += "tch_costoporlitroproducido, ";
      }

      if(encuesta.tch_egresototalmes === null){
        text += "tch_egresototalmes";
      }
      className = "table-danger";
    }

    // Falta información en egreso
    if(encuesta.tch_idEgreso){
      if(encuesta.tch_idEgreso.tch_egresodiferentealimentacionmes === null){
        text += ",Egreso: tch_egresodiferentealimentacionmes";
      className = "table-danger";
    }
  }

    // Falta información en dieta
    if(encuesta.tch_idDietaTotal){
      if(encuesta.tch_idDietaTotal.tch_costoalimentaciondia === null){
        text += "tch_egresototalmes";
      }
    }

    // Falta información en producción

    if(encuesta.tch_idProduccion){
    if(encuesta.tch_idProduccion.tch_totalhato === null &&
    encuesta.tch_idProduccion.tch_vacaslinea === null &&
    encuesta.tch_idProduccion.tch_vacaseca === null &&
    encuesta.tch_idProduccion.tch_novillona === null &&
    encuesta.tch_idProduccion.tch_vaquilla === null &&
    encuesta.tch_idProduccion.tch_becerra === null &&
    encuesta.tch_idProduccion.tch_vacaalta === null &&
    encuesta.tch_idProduccion.tch_vacamedia === null &&
    encuesta.tch_idProduccion.tch_vacabaja === null &&
    encuesta.tch_idProduccion.tch_ternero === null &&
    encuesta.tch_idProduccion.tch_toro === null){
      text += ",Producción: No hay datos";
      className = "table-danger";
    }
  }

    // Falta información en asistencia técnica

    if(Array.isArray(encuesta.tch_idVisitaTecnica)){
    if(encuesta.tch_idVisitaTecnica.tch_areadeactividades === null &&
    encuesta.tch_idVisitaTecnica.tch_administracion === null &&
    encuesta.tch_idVisitaTecnica.tch_administracionotro === null &&
    encuesta.tch_idVisitaTecnica.tch_calidad === null &&
    encuesta.tch_idVisitaTecnica.tch_calidadotro === null &&
    encuesta.tch_idVisitaTecnica.tch_nutricion === null &&
    encuesta.tch_idVisitaTecnica.tch_nutricionotro === null &&
    encuesta.tch_idVisitaTecnica.tch_saludanimal === null &&
    encuesta.tch_idVisitaTecnica.tch_saludanimalotro === null &&
    encuesta.tch_idVisitaTecnica.tch_reproduccion === null &&
    encuesta.tch_idVisitaTecnica.tch_reproduccionotro === null){
      text += ",Asistencia técnica: No hay datos";
      className = "table-danger";
    }
  }

    if(tipo === 'className'){
      return className;
    }else{
      return text;
    }

  };

  return (
    <div className="row">
		{props.encuestas.length > 0 && (
    <div className="col-md-12">
    <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
				        <th>Folio</th>
                <th>ID Margarita</th>
                <th>ID UGRJ</th>
                <th>Rancho</th>
                <th>Fecha</th>
                <th>Errores</th>
                <th>Status sincronización</th>
                <th>Ver en dynamics</th>
              </tr>
            </thead>
            <tbody>
              {props.encuestas.map((encuesta,index) => (
              <tr className={validaEncuesta(encuesta,'className')} key={encuesta.tch_visitamensualid}>
                <td>{index + 1}</td>
                <td>{encuesta.tch_foliodevisitamensual}</td>
                <td>{encuesta.tch_idProductor.tch_idproductor}</td>
                <td>{encuesta.tch_idProductor.tch_idproveedor}</td>
                <td>{(!encuesta.tch_idRancho) ? '' : encuesta.tch_idRancho.msdyn_name }</td>
                <td>{encuesta.tch_fechavisita}</td>
                <td>{validaEncuesta(encuesta,'text')}</td>
			          <td>
                    <button className="btn btn-dark btn-sm" onClick={() => setEncuesta(encuesta)}><i className="fa fa-arrow-right"></i></button>
                </td>
			          <td>
                <a className="btn btn-info" target="_blank" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_visitamensual&id=' + encuesta.tch_visitamensualid}><i className="fa fa-link"></i></a>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

  );
  };
export default DynamicsEncuestasListado;
