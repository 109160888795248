import xlsx from "json-as-xlsx";
import moment from 'moment';

const ListadoInventariosHistorialExportar = (content) => {
    let columns = [
        { label: 'ID Historial', value: 'id_historial_inventario' },
        { label: 'ID UGRJ', value: 'id_ugrj' },
        { label: 'ID Rancho', value: 'id_rancho' },
        { label: 'Proveedor', value: 'nombre_proveedor' },
        { label: 'Fecha Inicio', value: 'fecha_inicio' },
        { label: 'Fecha Fin', value: 'fecha_fin' },
        { label: 'Total', value: 'total' },
        { label: 'Linea', value: 'linea' },
        { label: 'Secas', value: 'secas' },
        { label: 'Becerras', value: 'becerras' },
        { label: 'Novillas', value: 'novillas' },
        { label: 'Vaquillas', value: 'vaquillas' },
        { label: 'Toros', value: 'toros' },
        { label: 'Fecha de registro', value: 'created_at' },
      ];
      
      let settings = {
        sheetName: 'Historial_Inventarios',
        fileName: 'Historial_Inventarios_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default ListadoInventariosHistorialExportar;
