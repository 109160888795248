import React, { useEffect, useState } from "react";
import Api from "../../../../../../api/global.service";
import ApiUrls from '../../../../../../constants/Api';
import BlockUi from "react-block-ui";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

var dataArray = [];
const MiTecnicoInfo = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {

    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.infogeneral.misbeneficios.mitecnico, { id_productor: props.id_productor })
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };
  

    if (props.id_productor > 0) {
      obtenerInfo();
    }
  }, [props.id_productor]);


  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
    {datos[0] && 
      <>
      <div className="col-md-3">
        <Card bg='light' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{datos[0].nombre} {datos[0].apellido_paterno} {datos[0].apellido_materno}</Card.Title>
          </Card.Body>
          <ListGroup className="list-group-flush">
          <ListGroupItem>ID: <strong>{datos[0].id_tecnico}</strong></ListGroupItem>
            <ListGroupItem>Celular: <strong>{datos[0].celular}</strong></ListGroupItem>
            <ListGroupItem>Fecha Ingreso: <strong>{datos[0].fecha_ingreso}</strong></ListGroupItem>
          </ListGroup>
        </Card>
        <br />
        </div>
        </>
    }
    </div>
    </BlockUi>
  );
};
export default MiTecnicoInfo;
