import xlsx from "json-as-xlsx";
import moment from 'moment';

const ListadoSeguimientosExportar = (content) => {
    let columns = [
        { label: 'ID Vaca', value: 'id_vaca' },
        { label: 'Proveedor', value: 'nombre_proveedor' },
        { label: 'ID UGRJ', value: 'id_ugrj' },
        { label: 'ID Margarita', value: 'id_margarita' },
        { label: 'Rancho', value: 'rancho' },
        { label: 'Vaca', value: 'vaca' },
        { label: 'Evento', value: 'evento' },
        { label: 'Evento Detalle', value: 'evento_detalle' },
        { label: 'Evento Fecha', value: 'evento_fecha' },
        { label: 'Total de notificaciones', value: 'total_notificaciones' },
        { label: 'Total de notificaciones atendidas', value: 'total_notificaciones_atendidas' }
      ];
      
      let settings = {
        sheetName: 'Seguimiento_Vacas',
        fileName: 'Seguimiento_Vacas_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default ListadoSeguimientosExportar;
