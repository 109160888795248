import React, { useEffect, useState } from "react";
import Api from "../../../api/global.service";
import ApiUrl from "../../../constants/Api";
import DataTable, { defaultThemes } from "react-data-table-component";
import "../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";

const AvisosDetalleVistosTabla = (props) => {
  const [blocking, setBlocking] = useState(false);

  const [state, setState] = useState({
    id_aviso: 0,
    avisos: [],
    currentUsuario: null,
    currentIndex: -1,
  });

  const columns = [
    {
      name: "NOMBRE",
      sortable: true,
      maxWidth: "250px",
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 700 }}>
            {row.nombre + " " + row.apellido_paterno}
          </div>
        </div>
      ),
    },

    {
      name: "ENVIADO",
      selector: "enviado",
      sortable: true,
      maxWidth: "250px",
      right: false,
    },

    {
      name: "FECHA VISTO",
      selector: "visto_fecha",
      sortable: true,
      maxWidth: "250px",
      right: false,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.visto === "0",
      style: {
        backgroundColor: "rgba(215, 44, 44, 0.4)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.visto === "1",
      style: {
        backgroundColor: "rgba(63, 195, 128, 0.4)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const customStyles = {
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  useEffect(() => {
    const obtenerDetalles = () => {
      setBlocking(true);
      Api.getAll(ApiUrl.noticias.detalles + "/" + props.id_aviso)
        .then((response) => {
          setState({
            avisos: response.data.data,
          });
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
          console.log(e);
        });
    };

    obtenerDetalles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <DataTable
        pagination
        columns={columns}
        data={state.avisos}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </BlockUi>
  );
};
export default AvisosDetalleVistosTabla;
