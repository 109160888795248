import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Api from "../../../../api/global.service";
import BlockUi from "react-block-ui";
import Alert from "react-bootstrap/Alert";
var dataArray = [];
export default function TablaVacaEventosNotificaciones(props) {
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);

  useEffect(() => {
    const obtenerNotificaciones = () => {
      setBlocking(true);
      Api.getAll("vacas/detalle/eventos/notificaciones", {
        id_vaca: props.vaca,
      })
        .then((response) => {
          dataArray = response.data.data;
          setDatosFiltrados(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    obtenerNotificaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vaca]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <h4 style={{ backgroundColor: "#dadada", padding: 10 }}>
        Próximas notificaciones
      </h4>
      {datosFiltrados.length > 0 && (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>ID Evento</th>
              <th>Fecha notificación</th>
              <th>Notificación</th>
              <th>Pregunta</th>
              <th>Pregunta Si</th>
              <th>Fecha creación</th>
            </tr>
          </thead>
          <tbody>
            {datosFiltrados.map((dato) => (
              <tr>
                <td>{dato.id_vaca_evento_notificacion}</td>
                <td>{dato.id_vaca_evento}</td>
                <td>{dato.fecha_notificacion}</td>
                <td>{dato.notificacion}</td>
                <td>{dato.pregunta}</td>
                <td>{dato.pregunta_si}</td>
                <td>{dato.created_at}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {datosFiltrados.length === 0 && (
        <Alert variant={"warning"}>No existen datos por mostrar</Alert>
      )}
    </BlockUi>
  );
}
