import React from "react";
import Api from "../../api/global.service";
import ApiUrls from '../../constants/Api';
import { Link } from "react-router-dom";
import DocumentosTabla from "./DocumentosTabla";
import {
  Panel,
  PanelBody,
} from "../../components/panel/panel.jsx";

const Documentos = () => {
  return (
    <>
      <h1 className="page-header">
        Entrega de documentación de reportes mensuales  
      </h1>
      <div className="row">
        <div className="col-md-12">
          <Panel>
            <PanelBody>
              <DocumentosTabla />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
};

export default Documentos;