import React, { useEffect, useState } from "react";
import Api from "../../api/global.service";
import ApiTecnicos from "../../constants/ApiTecnicos";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Button from "../../nuup/componentes/Generales/Button";
import "../../nuup/styles/Tablas.css";
import estiloGeneral from "../../nuup/componentes/Tablas/estiloGeneral";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import ModalPdf from "../../nuup/componentes/Generales/ModalPdf";
import { Link } from "react-router-dom";
import { Export, downloadCSV } from "../../nuup/componentes/Tablas/exportarCsv";
import { Dropdown, ProgressBar } from "react-bootstrap";

const DocumentosTabla = (props) => {
  const [verpdf, setVerpdf] = useState(false);
  const [urlpdf, setUrlpdf] = useState(false);
  const [archivopdf, setArchivopdf] = useState(false);
  const [data, setData] = useState([]);
  const today = new Date();
  const startMonth = "1"; // Enero
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  useEffect(() => {
    obtenerTecnicosData();
  }, []);

  const obtenerTecnicosData = async () => {
    Swal.showLoading();
    const resTecnicos = await Api.getAll(
      ApiTecnicos.reportes.tecnicos + "/equipo/1"
    );
    const tecnicos = resTecnicos.data.data;
    const tecnicosData = tecnicosTabla(tecnicos);
    setData(tecnicosData);
    Swal.close();
  };

  const tecnicosTabla = (tecnicosData) => {
    tecnicosData.forEach((t) => {
      t.ultimoAno = t.reportes.length > 0 ? t.reportes[t.reportes.length-1].ano : null;
      t.ultimoMes = t.reportes.length > 0 ? t.reportes[t.reportes.length-1].mes : null;
      t.estatusEntrega = calcEstatus(t.reportes);
    });
    return tecnicosData;
  };

  const calcEstatus = (reportes) => {
    const totalMonths = currentMonth - startMonth;
    let acc = 0;
    reportes.forEach((r) => {
      r.ano == currentYear && acc++;
    });
    return acc != 0 ? ((acc / totalMonths) * 100).toFixed(2) : acc;
  };

  const abrirPdf = (archivo) => {
    const url = process.env.REACT_APP_API_URL + "/api/" + archivo;
    setUrlpdf(url);
    setVerpdf(true);
    setArchivopdf(archivo);
  };

  const onCerrarPdf = () => {
    setVerpdf(false);
  };

  const viewLastReport = (row) => {
    return (
      <>
        {row.ultimoAno && row.ultimoMes && (
          <Button
            className="btn btn-link text-capitalize"
            onClick={() =>
              row.reportes.length > 0 ? abrirPdf(row.reportes[row.reportes.length-1].archivo) : {}
            }
          >
            <i class="fas fa-file-alt mr-1"></i>
            {format(new Date(row.ultimoAno, row.ultimoMes - 1, 1), "MMMM", {
              locale: es,
            }) +
              " " +
              row.ultimoAno}
          </Button>
        )}
      </>
    );
  };

  const viewReportsButton = (reportes) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" className="bg-white text-muted">
          Elige un mes de reporte
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {reportes.map((r) => (
            <Dropdown.Item
              href=""
              key={r.id_tecnico_reporte}
              onClick={() => abrirPdf(r.archivo)}
              className="text-capitalize"
            >
              <i class="fas fa-square fa-2xs text-success mr-1"></i>
              {format(new Date(r.ano, r.mes - 1, 1), "MMMM", {
                locale: es,
              }) +
                " " +
                r.ano}
              <span className="ml-3">
                Ver documento <i class="fas fa-external-link-alt"></i>
              </span>
            </Dropdown.Item>
          ))}
          {reportes.length == 0 && (
            <Dropdown.Item href="#">Sin reporte</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th className="text-uppercase text-muted">Nombre del técnico</th>
            <th className="text-uppercase text-muted">Estatus de entrega</th>
            <th className="text-uppercase text-muted">Última entrega</th>
            <th className="text-uppercase text-muted">Todos los documentos</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id_tecnico}>
              <td>
                {row.nombre} {row.apellido_paterno} {row.apellido_materno}
              </td>
              <td>
                <ProgressBar
                  now={parseFloat(row.estatusEntrega)}
                  label={`${row.estatusEntrega}%`}
                  variant="success"
                  style={{ height: "30px" }}
                />
              </td>
              <td>{viewLastReport(row)}</td>
              <td>{viewReportsButton(row.reportes)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* PDF modal */}
      <ModalPdf
        show={verpdf}
        url={urlpdf}
        archivo={archivopdf}
        titulo="Archivo guardado"
        onCerrarPdf={onCerrarPdf}
      />
    </div>
  );
};

export default DocumentosTabla;
