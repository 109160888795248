import React, { useEffect, useState } from "react";
import Api from "../../../../../../api/global.service";
import ApiUrls from "../../../../../../constants/Api";
import BlockUi from "react-block-ui";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";

var dataArray = [];
const MisAsistenciasListado = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    if (props.id_productor > 0) {
      obtenerInfo();
    }
           // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_productor]);

  const obtenerInfo = (filtrado = false) => {
    setDatos([]);
    setBlocking(true);
    Api.getAll(ApiUrls.infogeneral.misbeneficios.misasistencias, {
      id_productor: props.id_productor,
    })
      .then((response) => {
        dataArray = response.data.data;
        console.log(dataArray[0]);
        setDatos(dataArray);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };


  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
        {datos[0] &&
          datos.map((dato) => (
            <>
              <div className="col-md-4">
                <Card bg="light" style={{ width: "24rem" }}>
                  <Card.Body>
                    <Card.Title>Fecha de visita: {dato.fecha}</Card.Title>
                    <Card.Text>
                      Recomendaciones a implementarantes de la siguiente semana:
                    </Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                    {dato.recomendaciones.map((recomendacion) => (
                      <ListGroupItem>
                        <div style={{ marginTop: 10 }}>
                          <p><strong>{recomendacion.tema}:</strong></p>
                          <span>{recomendacion.comentarios}</span>
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Card>
                <br />
              </div>
            </>
          ))}
      </div>
    </BlockUi>
  );
};
export default MisAsistenciasListado;
