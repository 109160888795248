import React from "react";
import HerramientasTablasFiltroTipoDocumentosTabla from "./HerramientasTablasFiltroTipoDocumentosTabla";
import setFiltros from "../../Herramientas/setFiltros";
const HerramientasTablasFiltroTipoDocumentos = (props) => {
  const { quitarSeleccionados } = props;

  const handleSeleccionados = (_arr) => {
    let _ids = [];
    for (let a = 0; a < _arr.length; a++) {
      _ids.push(_arr[a].id_avisos_tipos_categoria);
    }
    setFiltros(4, _ids);
  };

  return (
    <>
      <HerramientasTablasFiltroTipoDocumentosTabla
        onChangeSeleccionados={handleSeleccionados}
        quitarSeleccionados={quitarSeleccionados}
      />
    </>
  );
};
export default HerramientasTablasFiltroTipoDocumentos;
