import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import Api from "../../../api/global.service";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../../../nuup/componentes/Forms/FormInput.jsx";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Swal from "sweetalert2";
import DynamicsLiquidacionesListado from "./componentes/DynamicsLiquidacionesListado";
import DynamicsLiquidacionesInfo365 from "./componentes/DynamicsLiquidacionesInfo365";
import DynamicsLiquidacionesInfoMysql from "./componentes/DynamicsLiquidacionesInfoMysql";

import Modal from "react-bootstrap/Modal";

const DynamicsLiquidaciones = () => {
	const [show, setShow] = useState(false);

	const schema = yup.object().shape({
		valor: yup.string("Seleccione un valor"),
		tipo: yup.string("Seleccione un tipo"),
	  });
	
	  const [recarga, setRecarga] = useState(0);
	  const [btncancelar, setBtncancelar] = useState(false);
	  const [liquidaciones, setLiquidaciones] = useState([]);
	  const [liquidacion, setLiquidacion] = useState({});

	  const [state] = useState({
		valor: "",
		tipo: 1,
	  });
	
	  const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	  } = useForm({
		resolver: yupResolver(schema),
		mode: "onBlur",
		defaultValues: state,
	  });

	  const onSubmit = (values) => {
		Swal.showLoading();
		Api.getAll("dynamics365/buscar/liquidacion", {
			fechaInicio: values.fechaInicio,
			fechaFin: values.fechaFin,
			valor: values.valor,
			tipo: values.tipo
		}).then((response) => {
			let _result = response.data;
			if (_result.success === 1) {
			  setLiquidaciones(_result.data);
			  setShow(true);
			  Swal.close();
			} else {
			  alert(_result.message);
			}
		  }).catch((e) => {
			Swal.fire("Error!", "Error al buscar información del usuario.", "error");
		  });
	  };

	  const hideFormEdit = () => {
		setShow(false);
	  };

	  const setLiquidacionSeleccionada = (liquidacion) => {
		setShow(false);
		setLiquidacion(liquidacion);
	  };

	  


  return (
    <>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Dynamics</Link>
        </li>
        <li className="breadcrumb-item active">Liquidaciones</li>
      </ol>
      <h1 className="page-header">
        Liquidaciones <small>Dynamics</small>
      </h1>
      <Panel>
        <PanelHeader>Buscar registros de liquidacion</PanelHeader>
        <PanelBody>
			<>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">

			<div className="col-md-3">
            <FormInput
                  id="fechaInicio"
                  type="date"
                  name="fechaInicio"
                  label="Fecha Inicio"
                  error={errors.fechaInicio}
                  register={register}
                />
			</div>

			<div className="col-md-3">
			<FormInput
                  id="fechaFin"
                  type="date"
                  name="fechaFin"
                  label="Fecha Fin"
                  error={errors.fechaFin}
                  register={register}
                />
			</div>

              <div className="col-md-3">
                <FormInput
                  id="valor"
                  type="text"
                  name="valor"
                  label="ID"
                  error={errors.valor}
                  register={register}
                />
              </div>

              <div className="col-md-3">
                <label>{"Tipo de búsqueda"}</label>
                <select
                  id="tipo"
                  className={`form-control`}
                  name="tipo"
                  {...register("tipo")}
                >
                  <option value="1">ID UGRJ</option>
                </select>
              </div>

            </div>

            <div className="row">
              <div className="col-md-4 offset-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <button className="btn btn-primary btn-block" type="submit">
                      {"Buscar"}
                    </button>
                  </div>
                </div>
              </div>
			  {liquidaciones && liquidaciones.length > 0 && (
			  <div className="col-md-4 offset-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <a onClick={() => setShow(true)} className="btn btn-warning btn-block">
                      {"Seleccionar otra"}
                    </a>
                  </div>
                </div>
              </div>
			  )}
            </div>
          </form>

		<hr />

			<Modal
			show={show}
			onHide={() => hideFormEdit()}
			size="xl"
			>
			<Modal.Header closeButton>
			<Modal.Title id="example-custom-modal-styling-title">
				Resultados de la búsqueda
			</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<DynamicsLiquidacionesListado liquidacionSeleccionada={(liquidacion) => setLiquidacionSeleccionada(liquidacion)} liquidaciones={liquidaciones} />
			</Modal.Body>
		</Modal>




		  </>
        </PanelBody>
      </Panel>

	{liquidacion.No && (
		<>
		<DynamicsLiquidacionesInfo365 liquidacion={liquidacion} />
	  </>

	)}

    </>
  );
};

export default DynamicsLiquidaciones;
