import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../api/global.service";
import ApiTecnicos from "../../../constants/ApiTecnicos";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInputFile from "../../../nuup/componentes/Forms/FormInputFile.jsx";
import FormSelectApi from "../../../nuup/componentes/Forms/FormSelectApi.jsx";
import FormSelectAno from "../../../nuup/componentes/Forms/FormSelectAno";
import FormSelectMeses from "../../../nuup/componentes/Forms/FormSelectMeses";
import Swal from "sweetalert2";

const TecnicosArchivosSubirForm = () => {
  const SELECT_TECNICO = "Seleccione un técnico";
  const SELECT_MES = "Seleccione el mes";
  const SELECT_ANO = "Seleccione el año";
  const schema = yup.object().shape({
    id_tecnico: yup
      .number(SELECT_TECNICO)
      .min(1, SELECT_TECNICO)
      .required(SELECT_TECNICO),
    file: yup
      .mixed()
      .required("Requerido")
      .test("fileFormat", "Solo se permiten archivos .pdf", (value) => {
        return (
          value[0] &&
          ["application/pdf"].includes(
            value[0].type
          )
        );
      }),
    mes: yup
        .number(SELECT_MES)
        .min(1, SELECT_MES)
        .required(SELECT_MES),
    ano: yup
        .number(SELECT_ANO)
        .min(1, SELECT_ANO)
        .required(SELECT_ANO),
  });

  const [file, setFile] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      id_tecnico: 0,
      mes: 0,
      ano: 0,
    },
  });

  const creaArchivo = (values) => {
    Swal.showLoading();
    const formData = new FormData();
    var _values = Object.keys(values).map((key) => [key, values[key]]);
    _values.forEach(function (item) {
        if(item[0] !== 'file'){
            formData.append(item[0], item[1]);
        }
    });
    formData.append("file", file);
    Api.createMultiPart(ApiTecnicos.reportes.tecnicos, formData)
      .then((response) => {
        let _result = response.data;
        if (_result.success === 1) {
          Swal.fire({
            icon: "success",
            title: "",
            text: "Reporte guardado correctamente",
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "",
            text: _result.message,
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: e,
        });
      });
  };

  const onSubmit = (values) => {
    creaArchivo(values);
  };

  const onChange = (e) => {
    console.log("onChange");
    setFile(e.target.files[0]);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <FormSelectApi
              id="id_tecnico"
              name="id_tecnico"
              label="Técnico"
              url="tecnicos/equipo/1"
              keyArray="id_tecnico"
              labelArray={['nombre', 'apellido_paterno', 'apellido_materno']}
              error={errors.id_tecnico}
              register={register}
            />
          </div>
          <div className="col-md-3">
            <FormSelectMeses
              id="mes"
              name="mes"
              label="Mes"
              error={errors.mes}
              register={register}
            />
          </div>
          <div className="col-md-3">
            <FormSelectAno
              id="ano"
              name="ano"
              label="Año"
              error={errors.ano}
              register={register}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <FormInputFile
              id="file"
              name="file"
              label="Archivo PDF"
              keyArray="file"
              labelArray="tipo_documento"
              error={errors.file}
              register={register}
              onChange={onChange}
            />
            <div class="alert alert-warning" role="alert">Proporcionar solo documentos en formato PDF</div>
          </div>
        </div>

        <button className="btn btn-primary btn-block" type="submit">
          Guardar
        </button>
      </form>
    </>
  );
};
export default TecnicosArchivosSubirForm;
