import React from "react";
import { Link } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import { useAuth } from "./../../components/content/auth.jsx";
import { useHistory } from "react-router-dom";

class SidebarProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileActive: 0,
      nivel: localStorage.nivel_name,
    };
    this.handleProfileExpand = this.handleProfileExpand.bind(this);
  }

  handleProfileExpand(e) {
    e.preventDefault();
    this.setState((state) => ({
      profileActive: !this.state.profileActive,
    }));
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({ pageSidebarMinify }) => (
          <ul className="nav">
            <li
              className={
                "nav-profile " + (this.state.profileActive ? "expand " : "")
              }
            >
              <Link to="/" onClick={this.handleProfileExpand}>
                <div className="cover with-shadow"></div>
                {localStorage.getItem("esFIRA") === "true" ? (
                  <div
                    className="image mb-1"
                    style={{
                      width: "100%",
                      height: '30px',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <img
                      src="../../assets/images/fira-logo.png"
                      alt="FIRA logo"
                    />
                  </div>
                ) : (
                  <div className="image image-icon text-grey-darker">
                    {localStorage.getItem("esAdmin") === "true" ? (
                      <i className="fa fa-user-cog"></i>
                    ) : localStorage.getItem("esTecnico") === "true" ? (
                      <i className="fa fa-user-tie"></i>
                    ) : (
                      <i className="fa fa-user"></i>
                    )}
                  </div>
                )}
                <div className="info">
                  <b className="caret pull-right"></b>
                  {this.props.auth.user.firstname}{" "}
                  {this.props.auth.user.lastname1}{" "}
                  {this.props.auth.user.lastname2}
                  <small>{this.state.nivel}</small>
                </div>
              </Link>
            </li>
            {/* <li>
							<ul className={"nav nav-profile " + (this.state.profileActive && !pageSidebarMinify ? "d-block " : "")}>
								<li><Link to="/"><i className="fa fa-cog"></i> Configuracion</Link></li>
							</ul>
						</li> */}
          </ul>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default (props) => {
  const auth = useAuth();
  const history = useHistory();
  return <SidebarProfile auth={auth} history={history} {...props} />;
};
