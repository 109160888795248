import xlsx from "json-as-xlsx";
import moment from 'moment';


const ListadoKpisHatosExportar = (content) => {
    let columns = [
        { label: 'Id Historial  KPI', value: 'id_historial_hatos_kpi' },
        { label: 'Proveedor', value: 'nombre_proveedor' },
        { label: 'ID UGRJ', value: 'id_ugrj' },
        { label: 'ID Vaca', value: 'id_vaca' },
        { label: 'Nombre', value: 'nombre' },
        { label: 'Fecha de cálculo', value: 'fecha' },
        { label: 'Días abiertos', value: 'dias_abiertos' },
        { label: 'Días leche', value: 'dias_leche' }
      ];
      
      let settings = {
        sheetName: 'Historial_Kpis_Hatos',
        fileName: 'Historial_Kpis_Hatos' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default ListadoKpisHatosExportar;
