import React from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../components/panel/panel.jsx";

const DynamicsEncuestasInfo365 = (props) => {
  return (
    <Panel>
      <PanelHeader>Información Dynamics</PanelHeader>
      <PanelBody>
        <div className="row">
          <div className="col-md-12">
            <div style={{backgroundColor : '#d4d4d4',margin : 10,padding : 10,fontSize : 18,fontWeight : 'bold'}} > Encuesta mensual </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>UUID</th>
                  <th>Folio</th>
                  <th>ID Margarita</th>
                  <th>ID UGRJ</th>
                  <th>Rancho</th>
                  <th>Fecha</th>
                  <th>Costo litro alimentación</th>
                  <th>Costo litro producido</th>
                  <th>Egreso total mes</th>
                  <th>Encuesta Completado</th>
                  <th>Egreso Completado (Global / Sección)</th>
                  <th>Dieta Completado (Global / Sección)</th>
                  <th>Producción Completado (Global / Sección)</th>
                  <th>Ver en Dynamics</th>
                </tr>
              </thead>
              <tbody>
                <tr key={props.encuesta.tch_visitamensualid}>
                  <th>{props.encuesta.tch_visitamensualid}</th>
                  <td>{props.encuesta.tch_foliodevisitamensual}</td>
                  <td>{props.encuesta.tch_idProductor.tch_idproductor}</td>
                  <td>{props.encuesta.tch_idProductor.tch_idproveedor}</td>
                  <td>{props.encuesta.tch_idRancho.msdyn_name}</td>
                  <td>{props.encuesta.tch_fechavisita}</td>
                  <td className={(props.encuesta.tch_costoporlitroalimentacion === null) ? 'bg-danger' : ''}>{props.encuesta.tch_costoporlitroalimentacion}</td>
                  <td className={(props.encuesta.tch_costoporlitroproducido === null) ? 'bg-danger' : ''}>{props.encuesta.tch_costoporlitroproducido}</td>
                  <td className={(props.encuesta.tch_egresototalmes === null) ? 'bg-danger' : ''}>{props.encuesta.tch_egresototalmes}</td>
                  <td>
                    {props.encuesta.tch_completadaencuestarancho ? "SI" : "NO"}
                  </td>
                  <td>
                    {props.encuesta.tch_completadaegreso ? "SI" : "NO"} /{" "}
                    {props.encuesta.tch_idEgreso.tch_completadaegreso
                      ? "SI"
                      : "NO"}
                  </td>
                  <td>
                    {props.encuesta.tch_completadadieta ? "SI" : "NO"} /{" "}
                    {props.encuesta.tch_idDietaTotal.tch_completadadieta
                      ? "SI"
                      : "NO"}
                  </td>
                  <td>
                    {props.encuesta.tch_completadaproduccion ? "SI" : "NO"} /{" "}
                    {props.encuesta.tch_idProduccion.tch_completadaproduccion
                      ? "SI"
                      : "NO"}
                  </td>

                  <th>
                    <a className="btn btn-info" target="_blank" rel="noopener noreferrer" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_visitamensual&id=' + props.encuesta.tch_visitamensualid}><i className="fa fa-link"></i></a>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
          <div style={{backgroundColor : '#d4d4d4',margin : 10,padding : 10,fontSize : 18,fontWeight : 'bold'}} > Egreso </div>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Folio</th>
                  <th>Egreso diferente Alimentación</th>
                  <th>Completado</th>
                  <th>Version</th>
                  <th>Ver en Dynamics</th>
                </tr>
              </thead>
              <tbody>
                <tr key={props.encuesta.tch_idEgreso.tch_folioegreso}>
                  <td>{props.encuesta.tch_idEgreso.tch_folioegreso}</td>

                  <td className={(props.encuesta.tch_idEgreso.tch_egresodiferentealimentacionmes === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idEgreso.tch_egresodiferentealimentacionmes}</td>
                  <td>{(props.encuesta.tch_idEgreso.tch_completadaegreso) ? 'SI' : 'NO'}</td>
                  <td>{props.encuesta.tch_idEgreso.versionnumber}</td>
                  <a className="btn btn-info" target="_blank" rel="noopener noreferrer" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_egresovm&id=' + props.encuesta.tch_idEgreso.tch_egresovmid}><i className="fa fa-link"></i></a>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
          <div style={{backgroundColor : '#d4d4d4',margin : 10,padding : 10,fontSize : 18,fontWeight : 'bold'}} > Dieta </div>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Folio</th>
                  <th>Costo alimentación día</th>
                  <th>Completado</th>
                  <th>Version</th>
                  <th>Ver en Dynamics</th>
                </tr>
              </thead>
              <tbody>
                <tr key={props.encuesta.tch_idDietaTotal.tch_foliodietatotal}>
                  <td>{props.encuesta.tch_idDietaTotal.tch_foliodietatotal}</td>
                  <td className={(props.encuesta.tch_idDietaTotal.tch_costoalimentaciondia === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idDietaTotal.tch_costoalimentaciondia}</td>
                  <td>{(props.encuesta.tch_idDietaTotal.tch_completadadieta) ? 'SI' : 'NO'}</td>
                  <td>{props.encuesta.tch_idDietaTotal.versionnumber}</td>                
                  <a className="btn btn-info" target="_blank" rel="noopener noreferrer" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_dietatotal&id=' + props.encuesta.tch_idDietaTotal.tch_dietatotalid}><i className="fa fa-link"></i></a>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

  
        <div style={{backgroundColor : '#d4d4d4',margin : 10,padding : 10,fontSize : 18,fontWeight : 'bold'}} > Producción </div>
        <div className="row">
          <div className="col-md-12">

          <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Folio</th>
                  <th>Litros (Hato)</th>
                  <th>Litros (Linea)</th>
                  <th>Completado</th>
                  <th>Version</th>
                  <th>Ver en Dynamics</th>
                </tr>
              </thead>
              <tbody>
                <tr key={props.encuesta.tch_idProduccion.tch_folioproduccion + "_1"}>
                <td>{props.encuesta.tch_idProduccion.tch_folioproduccion}</td>             
                  <td className={(props.encuesta.tch_idProduccion.tch_litrosporvacahato === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_litrosporvacahato}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_litrosporvacalinea === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_litrosporvacalinea}</td>
                  <td>{(props.encuesta.tch_idProduccion.tch_completadaproduccion) ? 'SI' : 'NO'}</td>
                  <td>{props.encuesta.tch_idProduccion.versionnumber}</td>
                  <a className="btn btn-info" target="_blank" rel="noopener noreferrer" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_produccionvm&id=' + props.encuesta.tch_idProduccion.tch_produccionvmid}><i className="fa fa-link"></i></a>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-md-12">

          <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Total hato</th>
                  <th>Linea</th>
                  <th>Secas</th>
                  <th>Novillonas</th>
                  <th>Vaquillas</th>
                  <th>Becerras</th>
                  <th>Altas</th>
                  <th>Medias</th>
                  <th>Bajas</th>
                  <th>Terneros</th>
                  <th>Toros</th>
                </tr>
              </thead>

              <tbody>
                <tr key={props.encuesta.tch_idProduccion.tch_folioproduccion + "_2"}>
                  <td className={(props.encuesta.tch_idProduccion.tch_totalhato === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_totalhato}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_vacaslinea === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_vacaslinea}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_vacaseca === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_vacaseca}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_novillona === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_novillona}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_vaquilla === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_vaquilla}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_becerra === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_becerra}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_vacaalta === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_vacaalta}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_vacamedia === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_vacamedia}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_vacabaja === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_vacabaja}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_ternero === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_ternero}</td>
                  <td className={(props.encuesta.tch_idProduccion.tch_toro === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idProduccion.tch_toro}</td>
                </tr>
              </tbody>
            </table>


          </div>

          </div>

          <div className="row">
          <div className="col-md-12">
          <div style={{backgroundColor : '#d4d4d4',margin : 10,padding : 10,fontSize : 18,fontWeight : 'bold'}} > Asistencia Técnica </div>

          <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Folio</th>
                  <th>Área de actividades</th>
                  <th>Administración</th>
                  <th>Administración (Otro)</th>
                  <th>Calidad</th>
                  <th>Calidad (Otro)</th>
                  <th>Nutrición</th>
                  <th>Nutrición (Otro)</th>
                  <th>Salud Animal</th>
                  <th>Salud Animal (Otro)</th>
                  <th>Reproducción</th>
                  <th>Reproducción (Otro)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr key={props.encuesta.tch_idVisitaTecnica.tch_visitatecnicaid}>
                <td>{props.encuesta.tch_idVisitaTecnica.tch_foliovisitatecnica}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_areadeactividades === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_areadeactividades}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_administracion === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_administracion}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_administracionotro === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_administracionotro}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_calidad === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_calidad}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_calidadotro === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_calidadotro}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_nutricion === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_nutricion}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_nutricionotro === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_nutricionotro}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_saludanimal === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_saludanimal}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_saludanimalotro === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_saludanimalotro}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_reproduccion === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_reproduccion}</td>
                <td className={(props.encuesta.tch_idVisitaTecnica.tch_reproduccionotro === null) ? 'bg-danger' : ''}>{props.encuesta.tch_idVisitaTecnica.tch_reproduccionotro}</td>
                <a className="btn btn-info" target="_blank" rel="noopener noreferrer" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_visitatecnica&id=' + props.encuesta.tch_idVisitaTecnica.tch_visitatecnicaid}><i className="fa fa-link"></i></a>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </PanelBody>
    </Panel>
  );
};
export default DynamicsEncuestasInfo365;
