import React from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../components/panel/panel.jsx";

const DynamicsCalidadesInfo365 = (props) => {
  return (
    <Panel>
      <PanelHeader>Información Dynamics</PanelHeader>
      <PanelBody>
        <div className="row">
          <div className="col-md-12">
            <div style={{backgroundColor : '#d4d4d4',margin : 10,padding : 10,fontSize : 18,fontWeight : 'bold'}} > Calidades </div>
         
            <table className="table table-bordered">
            <thead>
              <tr>
                <th>Uuid</th>
				        <th>Folio</th>
                <th>ID Margarita</th>
                <th>ID UGRJ</th>
                <th>Fecha</th>
                <th>Grasas</th>
                <th>Proteinas</th>
                <th>Células Somáticas</th>
                <th>Ver en Dynamics</th>
              </tr>
            </thead>
            <tbody>

              <tr key={props.calidad.tch_calidadproductorid}>
                <td>{props.calidad.tch_calidadproductorid}</td>
                <td>{props.calidad.tch_foliocalidad}</td>
                <td>{props.calidad.tch_proveedorproductorID.tch_idproductor}</td>
                <td>{props.calidad.tch_proveedorproductorID.tch_idproveedor}</td>
                <td>{props.calidad.tch_fecharuta}</td>
                <td className={(props.calidad.tch_resultadograsas === null) ? 'bg-danger' : ''}>{props.calidad.tch_resultadograsas}</td>
                <td className={(props.calidad.tch_resultadoproteinas === null) ? 'bg-danger' : ''}>{props.calidad.tch_resultadoproteinas}</td>
                <td className={(props.calidad.tch_resultadoscelulassomaticas === null) ? 'bg-danger' : ''}>{props.calidad.tch_resultadoscelulassomaticas}</td>
			          <td>
                <a className="btn btn-info" target="_blank" rel="noopener noreferrer" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_calidadproductor&id=' + props.calidad.tch_calidadproductorid}><i className="fa fa-link"></i></a>
                </td>
              </tr>

          </tbody>
          </table>
          </div>
        </div>

      </PanelBody>
    </Panel>
  );
};
export default DynamicsCalidadesInfo365;
