import React from "react";
import MiLeche from './mileche/MiLeche';
import MisIngresos from './misingresos/MisIngresos';
import MisGastos from './misgastos/MisGastos';
import MiCalidad from './micalidad/MiCalidad';

const MiNegocio = (props) => {
  return (
    <>
      <h3>Mi Negocio</h3>
      {props.id_productor > 0 && (
          <>
            <MiLeche id_productor={props.id_productor} id_margarita={props.id_margarita} id_ugrj={props.id_ugrj} rancho={props.rancho} />
            <MiCalidad id_productor={props.id_productor} id_margarita={props.id_margarita} id_ugrj={props.id_ugrj} rancho={props.rancho} />
            <MisIngresos id_productor={props.id_productor} id_margarita={props.id_margarita} id_ugrj={props.id_ugrj} rancho={props.rancho} />
            <MisGastos id_productor={props.id_productor} id_margarita={props.id_margarita} id_ugrj={props.id_ugrj} rancho={props.rancho} />
          </>
      )}
    </>
  );
};
export default MiNegocio;