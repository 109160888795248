import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import ArchivosEnviarTabla from './ArchivosEnviarTabla.jsx';

const ArchivosEnviarListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Listado de archivos</li>
      </ol>
      <h1 className="page-header">Envios de archivos <small></small></h1>
      <Panel>
        <PanelHeader>Archivos enviados</PanelHeader>
        <PanelBody>
            <ArchivosEnviarTabla/>
            
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default ArchivosEnviarListar;
