import React, { useEffect, useState } from "react";
import Api from "../../../../api/global.service";
import DataTable from "react-data-table-component";
import estiloGeneral from "../../../../nuup/componentes/Tablas/estiloGeneral";
import "../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import HerramientasTablas from "../../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas";
import ListadoVacasExportar from "../../ListadoVacasExportar";
import {pagOptionsESsmall} from "../../../../nuup/helpers/pagOptionsES";

var dataArray = [];
const ListadoVacas = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [seleccionado, setSeleccionado] = useState(0);

  const columns = [
    {
      name: "NOMBRE/ESTADO",
      sortable: true,
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 700 }}>{row.nombre}</div>
          {row.nombre_estado_reproductivo}
        </div>
      ),
    },
    {
      name: "TIPO/RAZA",
      sortable: true,
      right: false,
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 700 }}>{row.nombre_tipo_vaca}</div>
          {row.nombre_raza}
        </div>
      ),
    },
  ];

  const handleRowClicked = (row) => {
    const updatedData = datosFiltrados.map((item) => {
      if (row.id_vaca !== item.id_vaca) {
        return {
          ...item,
          toggleSelected: false,
        };
      } else {
        setSeleccionado(item.id_vaca);
        return {
          ...item,
          toggleSelected: true,
        };
      }
    });

    setDatosFiltrados(updatedData);
  };

  useEffect(() => {
    const obtenerVacas = (filtrado = false) => {
      setBlocking(true);
      Api.getAll("vacas/productor", { id_productor: props.id_productor })
        .then((response) => {
          dataArray = response.data.data;
          setDatosFiltrados(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setDatosFiltrados([]);
          setBlocking(false);
        });
    };

    if (props.id_productor > 0) {
      obtenerVacas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_productor]);

  useEffect(() => {
    props.vacaSeleccionada(seleccionado);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionado]);

  const handleChange = (datosFiltrados) => {
    setDatosFiltrados(datosFiltrados);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "#dadada",
        userSelect: "none",
      },
    },
  ];

  return (
    <BlockUi tag="div" blocking={blocking}>
      <HerramientasTablas
        txtBusqueda="Buscar vaca"
        datosTabla={dataArray}
        urlRegistrar=""
        columnasFiltrar={[
          "nombre",
          "nombre_estado_reproductivo",
          "nombre_tipo_vaca",
          "nombre_raza",
        ]}
        exportar={ListadoVacasExportar}
        onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
      />

      <DataTable
        pagination
        paginationPerPage={15}
        paginationComponentOptions={pagOptionsESsmall}
        columns={columns}
        data={datosFiltrados}
        Clicked
        noDataComponent={"Seleccione un productor"}
        customStyles={estiloGeneral}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
      />
    </BlockUi>
  );
};
export default ListadoVacas;
