import React, { useEffect, useState } from "react";
import Api from "../../../../../api/global.service";
import ApiUrls from '../../../../../constants/Api';
import "../../../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import NoData from "../../../../../components/content/no-data";


var dataArray = [];
const MisVacasListado = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {

    const obtenerInfo = (filtrado = false) => {
      setDatos([]);
      setBlocking(true);
      Api.getAll(ApiUrls.infogeneral.misvacas, { id_productor: props.id_productor })
        .then((response) => {
          dataArray = response.data.data;
          setDatos(dataArray);
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    };

    if (props.id_productor > 0) {
      obtenerInfo();
    }
  }, [props.id_productor]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
    {datos[0] && 
      datos.map((dato) =>
      <>
      <div className="col-md-3">
        <Card bg='light' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{dato.nombre}</Card.Title>
          </Card.Body>
          <ListGroup className="list-group-flush">
          <ListGroupItem><strong>ID:</strong> {dato.id_vaca}</ListGroupItem>
          <ListGroupItem><strong>D SINIIGA: </strong>{dato.id_siniiga}</ListGroupItem>
            <ListGroupItem><strong>Raza: </strong>{dato.nombre_raza}</ListGroupItem>
            <ListGroupItem><strong>Tipo / Categoría: </strong>{dato.nombre_tipo_vaca}</ListGroupItem>
            <ListGroupItem><strong>Estado reproductivo: </strong>{dato.nombre_estado_reproductivo}</ListGroupItem>
            <ListGroupItem><strong>Última inseminacion: </strong>{dato.fecha_ultima_inseminacion}</ListGroupItem>
            <ListGroupItem><strong>Último celo: </strong>{dato.fecha_ultimo_celo}</ListGroupItem>
            <ListGroupItem><strong>Último diagnóstico preñez: </strong>{dato.fecha_ultimo_diagnostico_prenez}</ListGroupItem>
            <ListGroupItem><strong>Último parto: </strong>{dato.fecha_ultimo_parto}</ListGroupItem>
          </ListGroup>
        </Card>
        <br />
        </div>
        </>
      )
    }

    {datos.length == 0 && <NoData />}

    </div>
    </BlockUi>
  );
};
export default MisVacasListado;
