import React, {useState} from "react";

import {Link} from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import Calendar from "./calendario/components/Calendar";
import "./calendario/css/App.css";
import DayDetail from "./calendario/components/DayDetail";
import NewEvent from "./calendario/components/NewEvent";
import Modal from "react-bootstrap/Modal";

const TecnicosPleneacion = () => {
  const [registro, setRegistro] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [eventoEditar, setEventoEditar] = useState({id_tecnico_planeacion: 0});

  const hideFormEdit = () => {
    setRegistro(false);
  };

  const setEditar = (evento) => {
    setEventoEditar(evento);
    setRegistro(true);
  };


  return (
    <>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item active"> <Link to="/tecnicos/planeacion">Mi Planeación</Link></li>
      </ol>
      <h1 className="page-header">
        Planeación <small>mensual</small>
      </h1>

      <div className="row">
        <div className="col-md-5">
          <Panel>
            <PanelHeader>
              Tareas
           </PanelHeader>
            <PanelBody>
              <div className="calendar">
                <DayDetail eventoEditar={(evento) => setEditar(evento)} registrarEvento={() => [setRegistro(true)]} refreshData={refresh} />
                <Modal show={registro} onHide={() => hideFormEdit()} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title id="registrar-evento-modal">
                      Registrar evento
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pt-0">
                    <NewEvent eventoEditar={eventoEditar} accionEvento={(data) => setRefresh(data)} cerrarModal={() => setRegistro(false)} />
                  </Modal.Body>
                </Modal>
              </div>
            </PanelBody>
          </Panel>
        </div>
        <div className="col-md-7">
          <Panel>
            <PanelHeader>Calendario</PanelHeader>
            <PanelBody>
              <div className="calendar">
                <Calendar />
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
};

export default TecnicosPleneacion;
