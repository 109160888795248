import xlsx from "json-as-xlsx";
import moment from 'moment';
const NoticiasExportar = (content) => {
 
   let columns = [
        { label: 'ID Noticia', value: 'id_aviso' },
        { label: 'Categoría', value: 'categoria' },
        { label: 'Título', value: 'titulo' },
        { label: 'Noticia', value: 'aviso' },
        { label: 'Fecha registro', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'Noticias',
        fileName: 'Noticias_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default NoticiasExportar;
