import React, { useEffect,useState } from "react";
import Api from '../../../../../../../../api/global.service';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../../../../../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../../../../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';

var dataArray = [];
const HerramientasTablasFiltroProductorTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [toggleCleared] = useState(false);

	const columns = [
		{
			name: 'ID',
			selector: 'id',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'TIPO PROVEEDOR',
			selector: 'tipo_proveedor',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'PROVEEDOR / CONTACTO',
			sortable: true,
			maxWidth: '300px',
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.nombre_proveedor}</div>{row.firstname + ' ' + row.lastname1}</div>
		},
		{
			name: 'ID UGRJ',
			selector: 'id_ugrj',
			maxWidth: '100px',
			sortable: true,
			right: false
		}

	];



	useEffect(() => {
		obtenerUsuarios();
	}, [props.quitarSeleccionados]);

	  const obtenerUsuarios = ()  => {
		setBlocking(true);
		Api.getAll('usuarios')
		  .then(response => {
			dataArray = response.data.data; 
			setDatosFiltrados(dataArray);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);

		  });
	  }


	const handleRowSelected = React.useCallback(state => {
		//setSelectedRows(state.selectedRows);
		props.onChangeSeleccionados(state.selectedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<BlockUi tag="div" blocking={blocking}>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={''}
			selectableRows
			onSelectedRowsChange={handleRowSelected}
			clearSelectedRows={toggleCleared}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default HerramientasTablasFiltroProductorTabla;
