import React, { useState,useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';


const ContactosRegistrar = () => {
	const { id_contacto_interes } = useParams();
	const [blocking, setBlocking] = useState(false);
	const schema = yup.object().shape({
		id_contacto_interes: yup.number().min(0),
		uuid: yup.string(),
		nombre: yup.string().required('Nombre requerido'),
		numero_telefonico: yup.string().required('Teléfono requerido')
	});


	const { register, handleSubmit, reset, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: {
			id_contacto_interes : id_contacto_interes,
			uuid: "",
			nombre: "",
			numero_telefonico: ""
		}
	});



	useEffect(() => {
		if (id_contacto_interes > 0) {obtenerContacto();}
		   // eslint-disable-next-line react-hooks/exhaustive-deps
	  },[]);

	const obtenerContacto = () => {
		setTimeout(function(){ 
			setBlocking(true);
			Api.getAll(ApiUrl.contactos + '/show/' + id_contacto_interes).then(response => {
				let contactoData = response.data.data;
				reset(contactoData[0]);
				console.log(contactoData[0]);
				setBlocking(false);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
		 }, 3000);
	}

	const creaContacto = values => {
		setBlocking(true);
		Api.create(ApiUrl.contactos,values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				  })
				  window.location.href = '/administracion/contactos';
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const actualizaContacto = values => {
		Swal.showLoading();
		console.log(values);
		Api.update(ApiUrl.contactos,values.uuid, values).then(response => {
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				  })
				  window.location.href = '/administracion/contactos';
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const onSubmit = values => {
		if (id_contacto_interes > 0) {
			actualizaContacto(values);
		} else {
			creaContacto(values);
		}
	}

	

	return (
		<>
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/administracion/contactos">Registrar contacto</Link></li>
				<li className="breadcrumb-item active"></li>
			</ol>
			<h1 className="page-header">Administración de contactos <small></small></h1>

			<div className="row">
			<div className="col-md-12">

				<Panel>
					<PanelHeader>Datos del contacto</PanelHeader>
					<PanelBody>
					<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>

					<form onSubmit={handleSubmit(onSubmit)}>

				<div className="form-group row">
					<div className="col-sm-6">
					<FormInput 
							id="nombre"
							type="text"
							name="nombre"
							label="Nombre"
							error={errors.nombre}
							register={register}
						/>
					</div>
		
					<div className="col-sm-6">
						<FormInput 
							id="numero_telefonico"
							type="text"
							name="numero_telefonico"
							label="Teléfono"
							error={errors.numero_telefonico}
							register={register}
						/>
					</div>
				</div>

						<div className="form-group row">
							<div className="col-md-12">
								<FormInput 
									id="uuid"
									type="hidden"
									name="uuid"
									label="UUID"
									error={errors.uuid}
									register={register}
								/>
							</div>
						</div>



						<div className="row">
						<div className="col-md-3 offset-md-9">
							<button className="btn btn-primary btn-block" type="submit">Guardar contacto</button>
						</div>
					</div>
					</form>
					</BlockUi>
					</PanelBody>
				</Panel>
			</div>
			</div>
	

			</div>
		</>
	);
};
export default ContactosRegistrar;
