import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import AvisosArchivosSubirTabla from './AvisosArchivosSubirTabla.jsx';

const AvisosArchivosSubirListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Listado de archivos</li>
      </ol>
      <h1 className="page-header">Administración de archivos <small></small></h1>
      <Panel>
        <PanelHeader>Archivos registrados</PanelHeader>
        <PanelBody>
            <AvisosArchivosSubirTabla/>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default AvisosArchivosSubirListar;
