import xlsx from "json-as-xlsx";
import moment from 'moment';

const UsuariosLoginExportar = (content) => {
    let columns = [
        { label: 'ID Usuario', value: 'id_user' },
        { label: 'Usuario', value: 'username' },
        { label: 'Email', value: 'email' },
        { label: 'Nombre', value: row => (row.firstname + ' ' + row.lastname1 + ' ' + ((row.lastname2 === null) ? '' : row.lastname2)) }, // Run functions
        { label: 'ID UGRJ', value: 'id_ugrj' },
        { label: 'ID Margarita', value: 'id_margarita' },
        { label: 'Token', value: 'token' },
        { label: 'Último login', value: 'ultimo_login' }
      ];
      
      let settings = {
        sheetName: 'Usuarios',
        fileName: 'Usuarios_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default UsuariosLoginExportar;
