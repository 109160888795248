import React from 'react';
// import Home from './../pages/Home.js';
import Home from '../pages/fira/Home.jsx';
import Documentos from '../pages/fira/Documentos';
import Productores from '../pages/fira/Productores';
import LoginV3 from './../pages/login-v3.js';

import {Provider} from 'react-redux';
import store from '../pages/tecnicos/planeacion/calendario/store';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Inicio',
    component: () => <Home />
  },
  {
    path: '/home',
    exact: true,
    title: 'Inicio',
    component: () => <Home />
  },
  {
    path: '/docs',
    exact: true,
    title: 'Documentos',
    component: () => <Documentos />
  },
  {
    path: '/productores',
    exact: true,
    title: 'Productores',
    component: () => <Productores />
  },
  {
    path: '/login',
    exact: true,
    title: 'Acceder',
    component: () => <LoginV3 />
  }
];

export default routes;