import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import ContactosTabla from './ContactosTabla.jsx';

const ContactosListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Administración de contactos</Link></li>
        <li className="breadcrumb-item active">Listado de contactos</li>
      </ol>
      <h1 className="page-header">Administración de contactos <small></small></h1>
      <Panel>
        <PanelHeader>Contactos registrados</PanelHeader>
        <PanelBody>
            <ContactosTabla/>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default ContactosListar;
