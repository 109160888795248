import React from "react";
import TablaDatosMisGastos from "../tablas/TablaDatosMisGastos";

const MisGastos = (props) => {
  return (
    <>
      <h4 style={{ backgroundColor: "#d4d4d4", padding: 10 }}>Mis Gastos</h4>
      {props.id_productor > 0 && (
        <>
          <div className="row">
            <div className="col-md-12">
              <TablaDatosMisGastos
                titulo="Año"
                tipo={3 }
                id_productor={props.id_productor}
                id_margarita={props.id_margarita}
                rancho={props.rancho}
              />
            </div>  
          </div>
        </>
      )}
    </>
  );
};
export default MisGastos;
