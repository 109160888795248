import React from 'react';
import Home from './../pages/Home.js';
import LoginV3 from './../pages/login-v3.js';
import UsuariosListar from './../pages/usuarios/UsuariosListar.jsx';
import UsuariosLoginListar from './../pages/usuarios/UsuariosLoginListar.jsx';
import UsuariosRegistrarV2 from './../pages/usuarios/V2/UsuariosRegistrarV2.jsx';
import AvisosArchivosSubirListar from './../pages/avisos/Reportes/AvisosArchivosSubir/AvisosArchivosSubirListar';
import AvisosEnviarForm from './../pages/avisos/Reportes/AvisosEnviar/AvisosEnviarForm';
import NoticiasEnviarProductorListar from './../pages/noticias/NoticiasEnviarProductorListar';
import NoticiasEnviarListar from './../pages/noticias/NoticiasEnviarListar';
import NoticiasEnviarForm from './../pages/noticias/NoticiasEnviarForm';
import ArchivosEnviarProductorListar from './../pages/noticias/archivos/ArchivosEnviarProductorListar';
import ArchivosEnviarListar from './../pages/noticias/archivos/ArchivosEnviarListar';
import ArchivosEnviarForm from './../pages/noticias/archivos/ArchivosEnviarForm';
import AvisosListar from './../pages/avisos/Reportes/AvisosListado/AvisosListar';
import VideosListar from './../pages/videos/VideosListar.jsx';
import VideosRegistrar from './../pages/videos/VideosRegistrar.jsx';
import Tecnicos from './../pages/tecnicos/Tecnicos.jsx';
import TecnicosAccesos from './../pages/tecnicos/TecnicosAccesos.jsx';
import TecnicosPersonalizados from './../pages/tecnicos/TecnicosPersonalizados.jsx';
//import TecnicosRegistrar from './../pages/tecnicos/TecnicosRegistrar.jsx';
import SoloPushEnviarForm from './../pages/avisos/SoloPush/SoloPushEnviarForm';
import ContactosListar from './../pages/contactos/ContactosListar.jsx';
import ContactosRegistrar from './../pages/contactos/ContactosRegistrar.jsx';
import VacasEventos from './../pages/vacas/eventos/VacasEventos';
import VacasSeguimientoTotales from './../pages/vacas/seguimiento/totales/VacasSeguimientoTotales';
import VacasSeguimientoDetalles from './../pages/vacas/seguimiento/detalles/VacasSeguimientoDetalles';
import WhatsappEnviarForm from './../pages/avisos/Whatsapp/WhatsappEnviarForm';
import WhatsappListar from './../pages/avisos/Whatsapp/WhatsappListado/WhatsappListar';
import UsuariosInformacionGeneral from './../pages/usuarios/InformacionGeneral/UsuariosInformacionGeneral';
import Vacas from './../pages/vacas/Vacas';
import HistorialVacas from './../pages/vacas/historial/HistorialVacas';
import KpisVacas from './../pages/vacas/kpis/vacas/KpisVacas';
import KpisHatos from './../pages/vacas/kpis/hatos/KpisHatos';
import HistorialInventarios from './../pages/ranchos/historial/HistorialInventarios';
import DynamicsEncuestas from './../pages/dynamics/encuestas/DynamicsEncuestas';
import DynamicsCalidades from './../pages/dynamics/calidad/DynamicsCalidades';
import DynamicsLiquidaciones from './../pages/dynamics/liquidaciones/DynamicsLiquidaciones';
import DynamicsEncuestasInventarios from './../pages/dynamics/encuestas/DynamicsEncuestasInventarios';
import DynamicsEncuestasCostos from './../pages/dynamics/encuestas/DynamicsEncuestasCostos';
import DynamicsEncuestasAsistencias from './../pages/dynamics/encuestas/DynamicsEncuestasAsistencias';
import TecnicosArchivosListar from './../pages/fira/Reportes/TecnicosArchivosListar';

const routesAdmin = [
  {
    path: '/',
    exact: true,
    title: 'Home',
    component: () => <Home />
  },
  {
    path: '/login',
    exact: true,
    title: 'Acceder',
    component: () => <LoginV3 />
  },
  {
    path: '/administracion/usuarios',
    exact: true,
    title: 'Usuarios Registrados',
    component: () => <UsuariosListar />
  },
  {
    path: '/administracion/usuarios/registrar',
    exact: true,
    title: 'Registrar usuario',
    component: () => <UsuariosRegistrarV2 />
  },

  {
    path: '/administracion/usuarios/actualizar/:uuid_proveedor/:uuid_contacto/:uuid_user',
    exact: true,
    title: 'Actualizar usuario',
    component: () => <UsuariosRegistrarV2 />
  },

  {
    path: '/administracion/usuarios/logins',
    exact: true,
    title: 'Login de usuarios',
    component: () => <UsuariosLoginListar />
  },
  {
    path: '/administracion/usuarios/info',
    exact: true,
    title: 'Información General',
    component: () => <UsuariosInformacionGeneral />
  },
  {
    path: '/administracion/tecnicos/informacion',
    exact: true,
    title: 'Técnicos Registrados',
    component: () => <Tecnicos />
  },

  {
    path: '/administracion/tecnicos/personalizados',
    exact: true,
    title: 'Técnicos Personalizados',
    component: () => <TecnicosPersonalizados />
  },
  

  {
    path: '/administracion/tecnicos/accesos',
    exact: true,
    title: 'Accesos de técnicos',
    component: () => <TecnicosAccesos />
  },

  {
    path: '/administracion/notificaciones/reporte',
    exact: true,
    title: 'Subir archivo',
    component: () => <AvisosListar />
  },
  {
    path: '/administracion/notificaciones/reporte/archivos',
    exact: true,
    title: 'Subir archivo',
    component: () => <AvisosArchivosSubirListar />
  },

  {
    path: '/administracion/notificaciones/reporte/enviar/:id_aviso_archivo',
    exact: true,
    title: 'Enviar reporte',
    component: () => <AvisosEnviarForm />
  },

  {
    path: '/administracion/notificaciones/solopush',
    exact: true,
    title: 'Enviar recordatorio',
    component: () => <SoloPushEnviarForm />
  },
  {
    path: '/administracion/notificaciones/whatsapp',
    exact: true,
    title: 'Enviar whatsapp',
    component: () => <WhatsappEnviarForm />
  },

  {
    path: '/administracion/notificaciones/whatsapp/listado',
    exact: true,
    title: 'Enviar whatsapp',
    component: () => <WhatsappListar />
  },

  {
    path: '/administracion/noticias/archivos',
    exact: true,
    title: 'Enviar archivo',
    component: () => <ArchivosEnviarListar />
  },

  {
    path: '/administracion/noticias/archivos/productor',
    exact: true,
    title: 'Listado de archivos por productor',
    component: () => <ArchivosEnviarProductorListar />
  },

  {
    path: '/administracion/noticias/archivos/registrar',
    exact: true,
    title: 'Enviar archivo',
    component: () => <ArchivosEnviarForm />
  },


  
  {
    path: '/administracion/noticias',
    exact: true,
    title: 'Enviar texto',
    component: () => <NoticiasEnviarListar />
  },

  {
    path: '/administracion/noticias/productor',
    exact: true,
    title: 'Enviar texto',
    component: () => <NoticiasEnviarProductorListar />
  },

  {
    path: '/administracion/noticias/registrar/:id_aviso',
    exact: true,
    title: 'Enviar noticia',
    component: () => <NoticiasEnviarForm />
  },

  {
    path: '/administracion/videos',
    exact: true,
    title: 'Videos Registrados',
    component: () => <VideosListar />
  },
  {
    path: '/administracion/videos/registrar/:id_video',
    exact: true,
    title: 'Registrar video',
    component: () => <VideosRegistrar />
  },

  {
    path: '/administracion/contactos',
    exact: true,
    title: 'Contactos Registrados',
    component: () => <ContactosListar />
  },
  {
    path: '/administracion/contactos/registrar/:id_contacto_interes',
    exact: true,
    title: 'Registrar contacto',
    component: () => <ContactosRegistrar />
  },

  {
    path: '/administracion/vacas/listado',
    exact: true,
    title: 'Listado de Vacas',
    component: () => <Vacas />
  },

  {
    path: '/administracion/historiales/vacas',
    exact: true,
    title: 'Cambios en vacas',
    component: () => <HistorialVacas />
  },
  {
    path: '/administracion/historiales/inventarios',
    exact: true,
    title: 'Historial de inventarios',
    component: () => <HistorialInventarios />
  },
  {
    path: '/administracion/historiales/kpis/vacas',
    exact: true,
    title: 'KPIs Vacas',
    component: () => <KpisVacas />
  },

  {
    path: '/administracion/historiales/kpis/hatos',
    exact: true,
    title: 'KPIs Hatos',
    component: () => <KpisHatos />
  },

  {
    path: '/administracion/vacas/eventos',
    exact: true,
    title: 'Vacas / Eventos',
    component: () => <VacasEventos />
  },
  {
    path: '/administracion/vacas/seguimiento',
    exact: true,
    title: 'Vacas / Seguimiento totales',
    component: () => <VacasSeguimientoTotales />
  },

  {
    path: '/administracion/vacas/seguimiento/detalle',
    exact: true,
    title: 'Vacas / Seguimiento detalle',
    component: () => <VacasSeguimientoDetalles />
  },
  {
    path: '/administracion/dynamics/encuestas',
    exact: true,
    title: 'Encuestas Mensuales',
    component: () => <DynamicsEncuestas />
  },
  {
    path: '/administracion/dynamics/calidades',
    exact: true,
    title: 'Calidades',
    component: () => <DynamicsCalidades />
  },
  {
    path: '/administracion/dynamics/liquidaciones',
    exact: true,
    title: 'Calidades',
    component: () => <DynamicsLiquidaciones />
  },
  {
    path: '/administracion/dynamics/encuestas/inventarios',
    exact: true,
    title: 'Inventarios',
    component: () => <DynamicsEncuestasInventarios />
  },
  {
    path: '/administracion/dynamics/encuestas/costos',
    exact: true,
    title: 'Costos',
    component: () => <DynamicsEncuestasCostos />
  },
  {
    path: '/administracion/dynamics/encuestas/asistencias',
    exact: true,
    title: 'Costos',
    component: () => <DynamicsEncuestasAsistencias />
  },
  {
    path: '/administracion/reportes/tecnicos/archivos',
    exact: true,
    title: 'Reportes técnicos',
    component: () => <TecnicosArchivosListar />
  },

];

export default routesAdmin;