import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody, PanelHeader } from "../../../components/panel/panel.jsx";
import TablaTotales from "../../../nuup/componentes/Tablas/TablaTotales.jsx";
import UltimaVezVisto from "../../../nuup/helpers/UltimaVezVisto.jsx";
import { toDate } from "date-fns";

const AdopcionApp = () => {
  const date = toDate(new Date());
  const [state, setState] = useState({
    anio: date.getYear() + 1900,
    mes: date.getMonth() + 1,
  });

  return (
    <>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item active">
          <Link to="/">Tablero</Link>
        </li>
      </ol>
      <h1 className="page-header">
        Reporte de adopción de la App Tecnoleche Productores
      </h1>
      {/* PowerBi Tecnicos AT Simple */}
      <div className="row">
        <div className="col-md-12">
          <Panel className="mb-0">
            <PanelBody className="mb-0 text-center">
              <div className="powerbi-embedded-report">
                <iframe
                  className="mt-0"
                  title="Técnicos AT"
                  width="100%"
                  height="100%"
                  src="https://app.powerbi.com/reportEmbed?reportId=eb7566e0-c873-4c79-a9f4-1d4ea4421d9f&autoAuth=true&ctid=35ffa4f7-9b27-405b-a3b7-13d53446ee4f"
                  frameBorder="0"
                  allowFullScreen="{true}"
                ></iframe>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
      {/* API reports */}
      <div className="row">
        <UltimaVezVisto />
        <div className="col-md-6">
          <Panel>
            <PanelHeader>Últimos accesos</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/accesos_apps"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "ultimo_acceso",
                  labelsText: "Productor",
                  dataText: "Último acceso",
                  perPage: 10,
                }}
                params={{
                  tipo: "registros",
                  anio: "",
                  mes: "",
                }}
              />
            </PanelBody>
          </Panel>
        </div>

        <div className="col-md-6">
          <Panel>
            <PanelHeader>Accesos mes actual por productor</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/accesos_apps"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "total",
                  labelsText: "Productor",
                  dataText: "Total accesos",
                  perPage: 10,
                }}
                params={{
                  tipo: "totales",
                  anio: state.anio,
                  mes: state.mes,
                }}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Panel>
            <PanelHeader>Total de accesos por productor</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/accesos_apps"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "total",
                  labelsText: "Productor",
                  dataText: "Total accesos",
                  perPage: 10,
                }}
                params={{
                  tipo: "totales",
                  anio: "",
                  mes: "",
                }}
              />
            </PanelBody>
          </Panel>
        </div>

        <div className="col-md-6">
          <Panel>
            <PanelHeader>Últimos registros de vacas</PanelHeader>
            <PanelBody>
              <TablaTotales
                titulo=""
                url="dashboard/vacas_registradas"
                config={{
                  labelsKey: "proveedor",
                  dataKey: "ultimo_registro",
                  labelsText: "Productor",
                  dataText: "Último registro",
                  perPage: 10,
                }}
                params={{
                  tipo: "registros",
                  anio: "",
                  mes: "",
                }}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
};

export default AdopcionApp;
