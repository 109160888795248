import React from "react";
import HerramientasTablasFiltroRutasTabla from "./HerramientasTablasFiltroRutasTabla";
import setFiltros from "../../Herramientas/setFiltros";
const HerramientasTablasFiltroRutas = (props) => {
  const { quitarSeleccionados } = props;

  const handleSeleccionados = (_arr) => {
    let _ids = [];
    for (let a = 0; a < _arr.length; a++) {
      _ids.push(_arr[a].id_ruta);
    }
    setFiltros(2, _ids);
  };

  return (
    <>
      <HerramientasTablasFiltroRutasTabla
        onChangeSeleccionados={handleSeleccionados}
        quitarSeleccionados={quitarSeleccionados}
      />
    </>
  );
};
export default HerramientasTablasFiltroRutas;
