import React, { useState,useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import ListadoVacas from './ListadoVacas';
import getUrlFiltros from '../../../nuup/componentes/Tablas/HerramientasTablas/Modulos/Filtrado/Herramientas/getUrlFiltros';
import Api from '../../../api/global.service';
var dataArray = [];

const AsistenteVacasEventos = () => {
	let { id_user } = useParams();
	const [vaca, setVaca] = useState('');
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const vacaSeleccionada = (id_vaca) => {
		setVaca(id_vaca);
	}

	useEffect(() => {
		localStorage.setItem('filtro_tipo', 3);
		localStorage.setItem('filtro_valor', id_user);
		obtenerUsuario(true);
	}, []);
	
	const obtenerUsuario = (filtrado = false)  => {
	Api.getAll(getUrlFiltros(filtrado,'usuarios')).then(response => {
		dataArray = response.data.data; 
		setDatosFiltrados(dataArray);
	}).catch(e => {

	});
	}
	

	return (
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/">Vacas Eventos</Link></li>
				<li className="breadcrumb-item active">Active Page</li>
			</ol>
			<h1 className="page-header">Vacas Eventos</h1>
			<Panel>
				<PanelHeader>
				{datosFiltrados.map((item, index) =>
					<h3 key={index}>{item['nombre_proveedor']}</h3>
          		)}
				</PanelHeader>
				<PanelBody>
					<>
						<Link className="btn btn-block btn-info" to={'/'}>Regresar al listado de usuarios</Link>
						<Link className="btn btn-block btn-warning" to={'/asistente/usuarios/info/' + id_user}>Ver Información del usuario</Link>
					</>
					<hr />
					<div className="row">
						<div className="col-md-12" style={{ borderRight: '2px solid #dadada' }}>
							<ListadoVacas id_productor={id_user} vacaSeleccionada={vacaSeleccionada} />
						</div>
					</div>
				</PanelBody>
			</Panel>
			
		</div>
	)
};

export default AsistenteVacasEventos;