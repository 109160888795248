import React, { useState } from "react";

const DynamicsEncuestasListado = (props) => {
  var totalIncompleto = 0;
  var totalMysql = 0;
  var totalRegistros = 0;

  const validaInventario = (encuesta,tipo) => {
    let className = "";
    let text = "";
    if(encuesta.tch_idProduccion){
    if(encuesta.tch_idProduccion.tch_totalhato === null &&
    encuesta.tch_idProduccion.tch_vacaslinea === null &&
    encuesta.tch_idProduccion.tch_vacaseca === null &&
    encuesta.tch_idProduccion.tch_novillona === null &&
    encuesta.tch_idProduccion.tch_vaquilla === null &&
    encuesta.tch_idProduccion.tch_becerra === null &&
    encuesta.tch_idProduccion.tch_vacaalta === null &&
    encuesta.tch_idProduccion.tch_vacamedia === null &&
    encuesta.tch_idProduccion.tch_vacabaja === null &&
    encuesta.tch_idProduccion.tch_ternero === null &&
    encuesta.tch_idProduccion.tch_toro === null){
      text += ",Producción: No hay datos";
      className = "btn-danger";
      totalIncompleto++;
    }
  }

  if(encuesta.tch_idProduccion.mysql){
    className = "btn-success";
    totalMysql++;
  }


  if(encuesta.tch_idProduccion.array === false){
    className = "btn-danger";
    }

    totalRegistros++;

    if(tipo === 'className'){
      return className;
    }else{
      return text;
    }

  };

  return (
    <div className="row">
		{props.encuestas.length > 0 && (
    <div className="col-md-12">
      <hr />
      {/*
      <table className="table table-bordered">
          <thead>
            <tr>
              <td>Total de registros</td>
              <td>Registros sincronizados</td>
              <td>Registros incompletos</td>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>{totalRegistros}</td>
              <td>{totalMysql}</td>
              <td>{totalIncompleto}</td>
            </tr>
          </tbody>
        </table>
      <hr />
      */}

    <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
				        <th>Folio Encuesta</th>
                <th>Folio Producción</th>
                <th>ID Margarita</th>
                <th>ID UGRJ</th>
                <th>Rancho</th>
                <th>Fecha</th>
                <th>Total hato</th>
                  <th>Linea</th>
                  <th>Secas</th>
                  <th>Novillonas</th>
                  <th>Vaquillas</th>
                  <th>Becerras</th>
                  <th>Altas</th>
                  <th>Medias</th>
                  <th>Bajas</th>
                  <th>Terneros</th>
                  <th>Toros</th>
                  <th>Litros (Hato)</th>
                  <th>Litros (Linea)</th>
              </tr>
            </thead>
            <tbody>
              {props.encuestas.map((encuesta,index) => (
              <tr className={validaInventario(encuesta,'className')} key={encuesta.tch_visitamensualid}>
                <td>{index + 1}</td>
                <td>
                  <a className="btn" target="_blank" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_visitamensual&id=' + encuesta.tch_visitamensualid}>{encuesta.tch_foliodevisitamensual}</a>
                </td>
                <td>
                {encuesta.tch_idProduccion.array === true && (
                  <a className="btn" target="_blank" href={'https://tecnoleche.crm.dynamics.com/main.aspx?cmdbar=true&forceUCI=1&navbar=off&newWindow=true&pagetype=entityrecord&etn=tch_produccionvm&id=' + encuesta.tch_idProduccion.tch_produccionvmid}>{encuesta.tch_idProduccion.tch_folioproduccion}</a>
                )}
                </td>
                <td>{encuesta.tch_idProductor.tch_idproductor}</td>
                <td>{encuesta.tch_idProductor.tch_idproveedor}</td>
                <td>{(!encuesta.tch_idRancho) ? '' : encuesta.tch_idRancho.msdyn_name }</td>
                <td>{encuesta.tch_fechavisita}</td>
                  <td className={(encuesta.tch_idProduccion.tch_totalhato === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_totalhato}</td>
                  <td className={(encuesta.tch_idProduccion.tch_vacaslinea === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_vacaslinea}</td>
                  <td className={(encuesta.tch_idProduccion.tch_vacaseca === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_vacaseca}</td>
                  <td className={(encuesta.tch_idProduccion.tch_novillona === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_novillona}</td>
                  <td className={(encuesta.tch_idProduccion.tch_vaquilla === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_vaquilla}</td>
                  <td className={(encuesta.tch_idProduccion.tch_becerra === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_becerra}</td>
                  <td className={(encuesta.tch_idProduccion.tch_vacaalta === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_vacaalta}</td>
                  <td className={(encuesta.tch_idProduccion.tch_vacamedia === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_vacamedia}</td>
                  <td className={(encuesta.tch_idProduccion.tch_vacabaja === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_vacabaja}</td>
                  <td className={(encuesta.tch_idProduccion.tch_ternero === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_ternero}</td>
                  <td className={(encuesta.tch_idProduccion.tch_toro === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_toro}</td>
                  <td className={(encuesta.tch_idProduccion.tch_litrosporvacahato === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_litrosporvacahato}</td>
                  <td className={(encuesta.tch_idProduccion.tch_litrosporvacalinea === null) ? 'bg-danger' : ''}>{encuesta.tch_idProduccion.tch_litrosporvacalinea}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
  };
export default DynamicsEncuestasListado;
